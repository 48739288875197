import { useEffect, useState } from "react";
import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import * as contextStore from "./student-course-context.store";
import { StudentStore } from "../student.store";

export function StudentCourse(): JSX.Element {
    const { studentId, personCourseId } = useParams();
    const location = useLocation();            
    const [store, setStore] = useState<contextStore.StudentCourseContextStore>(undefined);   
    const studentStore = useOutletContext<StudentStore>();    

    useEffect(() => {        
        if (studentStore) {
            const locationState = location.state as any;  
            let _store: contextStore.StudentCourseContextStore;
            if (locationState?.student && locationState?.personCourse) {
                _store = contextStore.initFromStudentStore(studentStore, locationState.personCourse.id);
            }
            else if (studentId && personCourseId) {
                _store = contextStore.initFromStudentStore(studentStore, personCourseId);
            }
            else throw new Error('invalid initialization data');
            
            setStore(_store);                    
        }
    }, [studentStore]);

    return (        
        <Outlet context={store}/>
    );
}