import { Card, CardHeader, Typography, CardContent, Box, IconButton, Stack, CardActionArea, CardActions } from "@mui/material";
import { ScheduleDayList } from "./ScheduleDayList";
import { ScheduleStore } from "../schedule.store";
import { useEffect, useState } from "react";
import { useSubscription } from "../../../../hooks/useSubscription";
import { CenteredContent } from "../../../shared/CenteredContent";
import { AppButton } from "../../../shared/buttons/primary";
import { Add, Print } from "@mui/icons-material";
import globalStore from "../../../../app/global.store";
import { SchdeuleAddForm } from "../edit-drawer/ScheduleAddForm";
import { ScheduleViewItem } from "../../../../models/data/schedule/schedule-or-recurrence.model";
import { addDays, isToday, isTomorrow, isYesterday } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dayjs_format, localDateString } from "../../../../util/dates";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../app/routes";

export function ScheduleCard(props: { studentID?: string  }) {
    const [store] = useState(new ScheduleStore());
    const [date, setDate] = useState<Date>(new Date());
    const [dateText, setDateText] = useState<string | JSX.Element>('');
    const [items, setItems] = useState<ScheduleViewItem[]>();     
    
    useEffect(() => {
        setDateText(dateToText(date));
        store.init(date);
    }, [date]);

    useSubscription(store?.scheduleViewItemsForDay$(date), i => setItems(i), [store, date]);       
    const navigate = useNavigate();
    
    return(
        <Card>
            <CardHeader title={
                <Typography variant="h6">
                    <span className="text-link" onClick={() => navigate(Routes.SCHEDULE)}>Schedule</span>
                </Typography>                    
                }
                action={ items &&
                    <DaySelector title={dateText} onNext={() => setDate(addDays(date, 1))} onPrev={() => setDate(addDays(date, -1))} />
                }  
            />                        
            <CardContent>                                                
                { items && (
                items.length 
                ? <ScheduleDayList date={date} scheduleStore={store} personID={props.studentID}/>                
                : <CenteredContent>
                    <Typography variant="h6">
                        Nothing scheduled
                    </Typography>
                    <Box sx={{mt:1}}>
                        <AppButton 
                            icon={<Add />}
                            text='Add'
                            onClick={() => globalStore.pushDrawerFormContent(
                                <SchdeuleAddForm
                                    date={dayjs(date).format(dayjs_format)}                            
                                    onSaved={() => store.refresh()} 
                                    showPersonAndCourse={true}/>)} 
                        />                              
                    </Box>      
                </CenteredContent>
            )}
            <CardActions sx={{justifyContent: 'center', padding: 0, mt: 4}}>
                {!!items?.length && <>
                    <AppButton 
                        icon={<Add />}
                        text='Add'
                        onClick={() => globalStore.pushDrawerFormContent(
                            <SchdeuleAddForm    
                                date={dayjs(date).format(dayjs_format)}                            
                                onSaved={() => store.refresh()} 
                                showPersonAndCourse={true}
                                personID={props.studentID}/>)} 
                    />                     
                </>}
            </CardActions>
             
            </CardContent>        
        </Card>
    );
}

const DaySelector = (props: {title: string | JSX.Element, onNext: () => void, onPrev: () => void}) => <Stack>    
    <Stack direction={'row'}>    
        <IconButton size="small" sx={{borderRadius: 0}}
            onClick={_ => props.onPrev()}>
            <FontAwesomeIcon icon={["fas", "chevron-left"]}/>
        </IconButton>
        <Typography variant="h6" sx={{width: '70px', textAlign: 'center'}}>                            
            <Box fontSize={'smaller'}>{props.title}</Box>        
        </Typography>                    
        <IconButton size="small" sx={{borderRadius: 0}}
            onClick={_ => props.onNext()}>
            <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
        </IconButton>
    </Stack>        
</Stack>
const dateToText = (d: Date) => 
    isToday(d) 
    ? <Box>         
        <Box fontSize={'small'}>{d.toLocaleDateString(undefined, {month: 'numeric', day: 'numeric'})}</Box>        
        <Box fontSize={'small'}>Today</Box>
    </Box>        
    : <Box> 
        <Box fontSize={'small'}>{d.toLocaleDateString(undefined, {month: 'numeric', day: 'numeric'})}</Box>
        <Box fontSize={'small'}>{d.toLocaleDateString(undefined, {weekday: 'long'})}</Box>
    </Box>;

