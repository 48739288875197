// https://github.com/isuru88/random-material-color
import * as randomColor from 'random-material-color';
// https://www.npmjs.com/package/contrast-color
import * as ContrastColor from 'contrast-color';
import { AvatarDefinition } from './shared/avatar-definition';

export interface Person {
    id: string;
    organizationID?: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    dateOfBirth?: string;
    email?: string;
    primaryPhone?: string;
    account: string;
    roleName?: string;
    roleType?: number;
    academicLevelID?: string;
    academicLevelName?: string;
    avatar?: AvatarDefinition;
}

export function getAvatarColor(p: Person): {backgroundColor: string, color: string,} {            
    const bgColor = randomColor.getColor(
        {
            text: `${p.id}`
        });

    const cc = new ContrastColor({});
      
    return {
        backgroundColor: bgColor,
        color: cc.contrastColor({bgColor: bgColor}),
    };
}

export function getAvatarColorForText(text: string): {backgroundColor: string, color: string,} {
    const bgColor = randomColor.getColor(
        {
            text: `${text}`
        });

    const cc = new ContrastColor({});
      
    return {
        backgroundColor: bgColor,
        color: cc.contrastColor({bgColor: bgColor}),
    };
}

export function getContrastColor(bgColor: string): string {    
    const cc = new ContrastColor({bgColor: bgColor});
    return cc.contrastColor();
}
