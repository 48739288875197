import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import {  useOutletContext } from "react-router-dom";
import globalStore from "../../../../app/global.store";
import { Activity } from "../../../../models/data/activity/activity.model";
import { AppButton } from "../../../shared/buttons/primary";
import { StudentCourseContextStore } from "../../students/student/student-course/student-course-context.store";
import './activity-timeline.scss';
import { ActivityEditForm } from "./ActivityEditForm";
import { reloadCourses } from "../../students/student/student.store";
import { minutesToHours, shortDate } from "../../../../util/dates";
import { AppAvatar } from "../../../shared/AppAvatar";
import { AppAvatar2 } from "../../../shared/AppAvatar2";

export function ActivityTableCard(props: {
        title: string,
        activity: Activity[],
        pageSize?: number,
        showPerson?: boolean,   
        personId?: string,     
        personCourseId?: string,        
    }): JSX.Element {        
        const store = useOutletContext<StudentCourseContextStore>();        
        
        const edit = (activity?: Activity) => {
            globalStore.pushDrawerFormContent(
                <ActivityEditForm 
                    activity={activity || {personID: props.personId, personCourseID: props.personCourseId, atPrimaryLocation: true}} 
                    onSaved={(a) => { 
                        store.activityLoader(); 
                        store.reloadCourse();
                        store.studentStore && reloadCourses(store.studentStore);
                     }
                    } 
                    showPersonAndCourse={!props.personCourseId}/>)
        }

    return (
    <Card sx={{position: 'relative'}}>
        <CardHeader title={                            
            <Typography component="h6" variant="h6">
                {props.title}
            </Typography>                            
        } action={
            <AppButton type='primary' 
                onClick={() => edit()}
                icon={<FontAwesomeIcon icon={["fas", "plus"]}/>} text='Add' />}
        />
        <CardContent className='no-scrollbars'>                            
            <TableContainer sx={{maxHeight: '300px'}}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width={'100px'} sx={{fontWeight: 'bold'}}>Date</TableCell>
                        {!props.personId && 
                            <TableCell width={'200px'} sx={{fontWeight: 'bold'}}>Student</TableCell>
                        }                        
                        {!props.personCourseId && 
                            <TableCell width={'225px'} sx={{fontWeight: 'bold'}}>Course</TableCell>
                        }
                        <TableCell width={'225px'} sx={{fontWeight: 'bold'}}>Assignment</TableCell>
                        <TableCell width={'225px'} sx={{fontWeight: 'bold'}}>Notes</TableCell>
                        <TableCell width={'80px'} sx={{fontWeight: 'bold'}}>Hours</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.activity?.map(i => 
                        <TableRow key={i.id} className='hover-grow' 
                            onClick={() => edit(i)}>
                            <TableCell>{shortDate(i.startDate)}</TableCell>
                            {!props.personId &&                                 
                                <TableCell>{i.personFirstName} {i.personLastName}</TableCell>
                            }
                            {!props.personCourseId && <TableCell>
                                {i.courseAvatar &&
                                    <span style={{display:'inline-block', marginRight: '8px'}}>
                                    <AppAvatar2                                            
                                        avatarDefinition={i.courseAvatar}
                                        size='26px' fontSize="13px"/></span> } 
                                <span>{i.courseTitle}</span>
                            </TableCell>}
                            <TableCell>{i.assignmentTitle}</TableCell>
                            <TableCell>{i.description}</TableCell>
                            <TableCell>{minutesToHours(i.durationMinutes, 1)}</TableCell>
                        </TableRow>
                    )}
                </TableBody>                    
            </Table>
            </TableContainer>
        </CardContent>        
    </Card>

    );
}
