import { Person } from "../../../../models/data/person.model";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, CardHeader, Typography, Button, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Avatar, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { Add as AddIcon, MenuBookTwoTone as CourseIcon } from "@mui/icons-material";
import { displayGrade, PersonCourse } from "../../../../models/data/person-course/person-course.model";
import '../students.scss';
import { useNavigate, useOutletContext } from "react-router-dom";
import { AppButton } from "../../../shared/buttons/primary";
import { AppAvatar } from "../../../shared/AppAvatar";
import { reloadCourses, StudentStore } from "./student.store";
import { filter } from "rxjs";
import { AddCourseForm } from "./AddCourseForm";
import globalStore from "../../../../app/global.store";
import { useSubscription } from "../../../../hooks/useSubscription";
import { CardActionButtons } from "../../../shared/CardActionButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CourseListReport } from "../../../reports/CourseList";
import { PersonAcademicTerm } from "../../../../models/data/person-academic-term-model";
import { AppAvatar2 } from "../../../shared/AppAvatar2";
import { CircularProgressWithLabel } from "../../../shared/CircularProgressWithLabel";

export function StudentCourses(): JSX.Element {
    const store = useOutletContext<StudentStore>();
    const navigate = useNavigate();           
    const [studentCourses, setStudentCourses] = useState<PersonCourse[]>(undefined);      
    const [student, setStudent] = useState<Person>(undefined);    
    const [academicTerm, setAcademicTerm] = useState<PersonAcademicTerm>(undefined);

    useSubscription(store.student$, s => setStudent(s), [store.student$]);
    useSubscription(store.courses$.pipe(filter(pcs => !!pcs)), pcs => setStudentCourses(pcs), [store.courses$]);    
    useSubscription(store.academicTerm$, at => setAcademicTerm(at), [store.academicTerm$])

    const summaryReport = () =>
        globalStore.showReport(
            <CourseListReport student={student} academicTerm={academicTerm} courses={studentCourses} />
        );
      
    const addCourse = () => {        
        globalStore.drawerFormContentStackPush$.next(<AddCourseForm student={student} onSaved={() => reloadCourses(store)} store={store}/>);
    }
    return (<>{studentCourses &&                  
        <Card>
            <CardHeader 
                title={<Typography component="h6" variant="h6">Courses</Typography>}
                action={!!studentCourses?.length ? 
                    <CardActionButtons buttons={[
                        {text: 'Add Course', icon: <CourseIcon />, action: () => addCourse() },
                        student && academicTerm && studentCourses && {text: 'Print', icon: <FontAwesomeIcon icon={['fas', 'file-pdf']} />, action: () => summaryReport() },
                    ]} />
                : undefined}
            />                        
            <CardContent>                 
                {!!studentCourses?.length &&
                    <Table size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '30px' }}></TableCell>
                                <TableCell>Course</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Hours</TableCell>                                            
                                <TableCell>Progress</TableCell>                                            
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {studentCourses.map(c => {                            
                                return (
                                    <TableRow key={c.id} className='tr-hover-grow' onClick={() => navigate(`courses/${c.id}`, {state: {student: student, personCourse: c}})}>
                                        <TableCell>
                                            <AppAvatar2 avatarDefinition={c.avatar} />                                                          
                                        </TableCell>
                                        <TableCell>{c.course_Name}</TableCell>
                                        <TableCell>{displayGrade(c)}</TableCell>                                                            
                                        <TableCell>{c.statistics?.sumActivityHours}</TableCell>                
                                        <TableCell>{ 
                                            !!c.course_HoursRequired 
                                                && <CircularProgressWithLabel value={((c.statistics?.sumActivityHours || 0)/ c.course_HoursRequired) * 100} /> 
                                        }</TableCell>                                            
                                    </TableRow>
                                )})
                            }                                            
                        </TableBody>
                    </Table>
                }                          
                {!studentCourses?.length &&
                    <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-80px' }}>
                        <div style={{ textAlign: 'center' }}>
                            <Typography component="h5" variant="h5">
                                This student doesn't have any courses yet
                            </Typography>
                            <Box sx={{ mt: 4 }}>
                                <Box sx={{ mt: 2 }}>                                                    
                                    <AppButton size="large" 
                                        onClick={() => addCourse()} 
                                        icon={<CourseIcon/>} 
                                        text='Add Course' />                                                                                                                                        
                                </Box>
                            </Box>
                        </div>
                    </div>
                }
            </CardContent>                                        
        </Card>}             
    </>);    
}