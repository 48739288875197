import { Observable, map } from "rxjs";
import { Person } from "../models/data/person.model";
import { prettyJson } from "../util/pretty-json";
import { apiGet, apiPost } from "./api-service";
import { PersonAcademicTerm } from "../models/data/person-academic-term-model";

export function getPerson(personId: string): Observable<Person> {    
    return apiGet<Person>(`person/${personId}`);
}

export function getPersonsForOrganization(organizationId: string): Observable<Person[]> {    
    return apiGet<Person[]>(`person/getPersonsForOrganization/${organizationId}`); 
}

export function savePerson(person: Person): Observable<Person> {
    return apiPost<Person>('person/save', person);
}

export function getStudents(): Observable<Person[]> {
    return apiGet<Person[]>('person/getStudents');
}

export function getPersonAcademicTerms(personId: string): Observable<PersonAcademicTerm[]> {
    return apiGet<PersonAcademicTerm[]>(`person/${personId}/academic-terms`);
}

export function savePersonAcademicTerm(pat: PersonAcademicTerm): Observable<PersonAcademicTerm> {
    return apiPost<PersonAcademicTerm>(`person/${pat.personID}/academic-terms/save`, pat);
}

export function changeSelfPassword(pw: string): Observable<any> {
    return apiPost<any>('person/change-password', pw);
}