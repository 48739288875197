import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export function PopupButton(props: {icon: JSX.Element, items: PopupMenuItem[]}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        return true;
    };

    return <> 
        <IconButton            
            onClick={handleClick}>
            {props.icon}
        </IconButton>
        <Menu
            anchorEl={anchorEl}            
            open={open}
            onClose={handleClose}>
            {props.items?.map((i, index) => 
                <MenuItem key={index} onClick={() => handleClose() && i.action()}>
                    {i.icon && <span style={{marginRight: '18px'}}>{i.icon}</span>}
                    {i.text}
                </MenuItem>                    
            )}            
        </Menu>

    </>
}

export type PopupMenuItem = {
    icon?: JSX.Element,
    text?: string,
    action: () => void,
}