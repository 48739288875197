import { map, Observable } from "rxjs";
import { apiPost } from "./api-service";
export type TokenModel = {token: string, refresh: string};

export function login(username: string, password: string): Observable<TokenModel | undefined> {
    return apiPost<TokenModel>('auth/login', {username: username, password: password});    
}

export function refreshAuthToken(tokenModel: TokenModel) {
    return apiPost<TokenModel>('auth/refresh', tokenModel);
}