import { addMonths } from "date-fns";
import { formatWithOptions } from "date-fns/fp";
import dayjs from "dayjs";

// export function shortDate(d: string | Date): string {
//     if (!d) return '';    
//     const date = d instanceof(Date) ? d : new Date(d);
//     return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
//         .toLocaleDateString(undefined, {month: '2-digit', day: '2-digit', year: 'numeric'});
// }

export function shortDate(d: string | Date, twoDigit?: boolean) {
    return twoDigit ? dayjs(d).format('MM/DD/YYYY') : dayjs(d).format('M/D/YYYY');
}

export function dateFromShortDateString(d: string) {
    const date = new Date(d);
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

export function minutesToHours(mins: number, decimals?: number): string {
    return (mins/60).toFixed(decimals || 0);
}

export const lastDOM = (firstDOM: Date) => new Date(firstDOM.getFullYear(), firstDOM.getMonth() + 1, 0);
export const weekStart = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay());
export const weekEnd = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate() + (6 - d.getDay()));
export const lastDOMWeekEnd = (firstDOM: Date) => weekEnd(lastDOM(firstDOM));

const monthAdd = (adj: number) => (d: Date) => new Date(d.getFullYear(), d.getMonth() + adj, 1);
export const nextMonth = monthAdd(1);
export const previousMonth = monthAdd(-1);

export const MIN_DATE = new Date(0);
export const MAX_DATE = new Date('9999-12-31');

export const dayOfWeekName = (dow: number) => new Date(2023, 3, 23 + dow).toLocaleString(undefined, { weekday: 'long' });
export const dayOfWeekShortName = (dow: number) => new Date(2023, 3, 23 + dow).toLocaleString(undefined, { weekday: 'short' });

export const localDateString = (date: Date) => {
    const d = formatWithOptions({}, 'yyyy-MM-dd')(date);          
    return d;
    // return `${date.getUTCFullYear()}-${(date.getUTCMonth()+1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
} 

export const dateOnly = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const dayjs_format = 'YYYY-MM-DD';