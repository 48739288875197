import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { SaveCancelActions } from "../SaveCancelActions";
import { FormDataContext } from "./Form";

export function FormActions(props: { 
    saveAction?: () => void,
    saveIcon?: JSX.Element,
    cancelAction?: () => void, 
    deleteAction?: () => void, 
    deleteConfirmation?: {title: string, text: string}
    }): JSX.Element {
        const formData = useContext(FormDataContext);
        const [formValid, setFormValid] = useState(false);
        const [busy, setBusy] = useState(false);

        useEffect(() => {
            const td$ = new Subject<void>();
            if (formData) {
                formData.isValid$().pipe(takeUntil(td$)).subscribe(v => setFormValid(v));
                formData.isBusy$.pipe(takeUntil(td$)).subscribe(b => setBusy(b));
            }
            return () => td$.next();
        }, [formData]);
        
        const saveAction = () => {          
            setBusy(true);            
            formValid && props.saveAction();
        };

        const deleteAction = () => {          
            setBusy(true);            
            props.deleteAction();
        };
    return (        
        formData ?
        <SaveCancelActions saveAction={props.saveAction ? () => saveAction() : undefined} 
            saveIcon={props.saveIcon}
            cancelAction={props.cancelAction} 
            saveDisabled={!formValid} disableAll={busy} 
            deleteAction={props.deleteAction && deleteAction}
            deleteConfirmation={props.deleteConfirmation}            
            />
        : <></>
    );
}