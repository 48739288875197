import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import globalStore from "../../../../app/global.store";
import { ScheduledActivity } from "../../../../models/data/schedule/scheduled-activity.model";
import { shortDate } from "../../../../util/dates";
import { Form } from "../../../shared/form/Form";
import { FormData } from "../../../shared/form/form-data";
import { FormActions } from "../../../shared/form/FormActions";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { ScheduleStore } from "../schedule.store";
import { ScheduleDayList } from "../shared/ScheduleDayList";

export function DayDetailDrawer (props: {
    date: Date,    
    scheduleStore: ScheduleStore,    
    personID?: string,}) {         
        
    return (        
        <DrawerFormContainer>   
            <Form formData={new FormData({})}>
                <DrawerFormHeader>
                    <Typography variant="h6">{shortDate(props.date)}</Typography>
                </DrawerFormHeader>            
                <DrawerFormBody>              
                    <Box sx={{minWidth: '275px'}}>
                        <ScheduleDayList date={props.date} 
                            scheduleStore={props.scheduleStore}  
                            personID={props.personID} />
                    </Box>
                </DrawerFormBody>    
                <DrawerFormFooter>
                    <FormActions                             
                        cancelAction={() => globalStore.drawerFormContentStackPop$.next()}/>                    
                </DrawerFormFooter>           
            </Form>          
        </DrawerFormContainer>        
    );
}
