import { Box, Typography } from "@mui/material";
import globalStore from "../../../../app/global.store";
import { Form } from "../../../shared/form/Form";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { FormActions } from "../../../shared/form/FormActions";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { ScheduleStore } from "../schedule.store";
import { take } from "rxjs";
import { FormBool } from "../../../shared/form/FormBool";
import { ScheduleWeekReport } from "../../../reports/schedule/ScheduleWeekReport";
import { Print } from "@mui/icons-material";

export function ScheduleWeekPrintOptions (props: {
    store: ScheduleStore,    
    dates: Date[],
    }
) {             
    const formData = new FormData({
        studentPageBreak: new FormFieldData(true),
        dayPageBreak: new FormFieldData(true),
    });

    const print = () => {
        const formValue = formData.getValueAs<{studentPageBreak: boolean, dayPageBreak: boolean}>();
        props.store.scheduleViewItemsForEachDate$(props.dates).pipe(take(1)).subscribe(items => {
            globalStore.popDrawerFormContent();
            globalStore.showReport(ScheduleWeekReport(items, formValue.studentPageBreak, formValue.dayPageBreak))
        });
    };

    return (        
        <DrawerFormContainer>   
            <Form formData={formData}>
                <DrawerFormHeader>
                    <Typography variant="h6">Print Schedule</Typography>
                </DrawerFormHeader>            
                <DrawerFormBody>              
                    <Box sx={{minWidth: '275px'}}>
                        <Box>
                            <FormBool label="Page break for each student" fieldName="studentPageBreak" type="switch"/>
                        </Box>
                       <Box>
                            <FormBool label="Page break for each day" fieldName="dayPageBreak" type="switch"/>
                       </Box>
                    </Box>
                </DrawerFormBody>    
                <DrawerFormFooter>
                    <FormActions                             
                        saveIcon={
                            <Print />
                            //<FontAwesomeIcon icon={['fas', 'file-pdf']} />
                        }
                        saveAction={() => print()}
                        cancelAction={() => globalStore.drawerFormContentStackPop$.next()}/>                    
                </DrawerFormFooter>           
            </Form>          
        </DrawerFormContainer>        
    );
}