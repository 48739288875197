import { Card, CardHeader, CardContent, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Skeleton, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import { useEffect, useState } from "react";
import { AcademicTerm } from "../../../../models/data/settings/academic-term";
import { getAcademicTerms, saveAcademicTerm } from "../../../../services/settings.service";
import Grid from "@mui/material/Unstable_Grid2";
import { FormField } from "../../../../models/app/form-field";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { hasLengthValidator, notNullValidator, setFormValue } from "../../../../util/form-util";
import { Page } from "../../../shared/Page";
import { SaveCancelActions } from "../../../shared/SaveCancelActions";
import { mergeMap, tap } from "rxjs";
import { AppButton } from "../../../shared/buttons/primary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import globalStore from "../../../../app/global.store";
import { AcademicTermEditForm } from "./AcademicTermEditForm";

export function AcademicTermList(props: {}) {
    const [academicTerms, setAcademicTerms] = useState<AcademicTerm[]>([]);
    const [loaded, setLoaded] = useState(false);     
    const [reload, setReload] = useState({});

    useEffect(()=>{
        setLoaded(false);
        getAcademicTerms().subscribe(terms => {
            setAcademicTerms(terms);
            setLoaded(true);
        });
    }, [reload]);

    const edit = (academicTerm?: AcademicTerm) => globalStore.pushDrawerFormContent(
        <AcademicTermEditForm term={academicTerm || {}} onSaved={() => setReload({})}/>
    ); 

    return(     
        <Page breadcrumbs={[{text: 'Settings'}, {text: 'Terms'}]} title='Terms'>              
          <Grid container spacing={2}>                                            
            <Grid xs={12} md={6} xl={3}>                                                                    
                <Card>
                    <CardHeader
                    action={
                        <AppButton size="medium" icon={<FontAwesomeIcon icon={['fas', 'calendar-plus']} />} text='Add Term' 
                            onClick={() => edit()}/>                         
                    }/>  
                    <CardContent>          
                        <Table size="small">    
                            <TableHead>
                                <TableRow>                        
                                    <TableCell>Name</TableCell>
                                    <TableCell>Start</TableCell>
                                    <TableCell>End</TableCell>                                    
                                </TableRow>
                            </TableHead>            
                            <TableBody>
                            {loaded &&
                                academicTerms.map(t => {
                                    return (
                                    <TableRow key={t.id} className='tr-hover-grow' onClick={() => edit(t)}>                                                                
                                        <TableCell>                                                                               
                                            {t.name}
                                        </TableCell>    
                                        <TableCell>
                                            {new Date(t.startDate).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                        </TableCell>                
                                        <TableCell>
                                            {new Date(t.endDate).toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric' })}
                                        </TableCell>                                                                 
                                    </TableRow>
                                    );
                                })
                            } 
                            { !loaded &&
                            [0,1].map((v) => 
                            <TableRow key={v}>
                                <TableCell colSpan={4}>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>                    
                            </TableRow>)
                            }                           
                            </TableBody>
                        </Table>    
                    </CardContent>                     
                </Card>
                </Grid>                   
            </Grid>  
        </Page>
    );
}

const adaptDateValue = (val: any) =>
    (val && dayjs(val).format('YYYY-MM-DD')) || val || undefined;