import { Typography, Stack } from "@mui/material";
import globalStore from "../../../../app/global.store";
import { Form } from "../../../shared/form/Form";
import { FormActions } from "../../../shared/form/FormActions";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { requiredDate, requiredString } from "../../../shared/form/validators";
import { FormTextField } from "../../../shared/form/FormTextField";
import { saveAcademicTerm } from "../../../../services/settings.service";
import { AcademicTerm } from "../../../../models/data/settings/academic-term";
import { FormDatePicker } from "../../../shared/form/FormDatePicker";

export function AcademicTermEditForm(props: {term: AcademicTerm, onSaved: () => void}) {
    const formData = new FormData({
        name: new FormFieldData(props.term.name || '', [requiredString]),
        startDate: new FormFieldData(props.term.startDate || '', [requiredDate]),
        endDate: new FormFieldData(props.term.endDate || '', [requiredDate]),
    });

    const save = () => {
        const fv = formData.getValueAs<AcademicTerm>();
        saveAcademicTerm({...props.term, ...fv}).subscribe(_ => {
            props.onSaved()
            globalStore.popDrawerFormContent();
        });        
    };

    return <DrawerFormContainer>
        <Form formData={formData}>            
            <DrawerFormHeader>
                <Typography variant="h6">{props.term.id ? 'Edit' : 'Add'} Term</Typography>
            </DrawerFormHeader>            
            <DrawerFormBody>              
                <Stack spacing={2} sx={{minWidth: '275px'}}>
                    <FormTextField label="Term Name" required={true} fieldName="name" />
                    <FormDatePicker label="Start" required={true} fieldName="startDate" />
                    <FormDatePicker label="End" required={true} fieldName="endDate" />
                </Stack>                             
            </DrawerFormBody>    
            <DrawerFormFooter>
                <FormActions     
                    saveAction={() => save()}                         
                    cancelAction={() => globalStore.popDrawerFormContent()}/>                    
            </DrawerFormFooter>
        </Form>
    </DrawerFormContainer>;
}
