export enum AssignmentType
{
    Lesson = 0b1 << 0,
    Project = 0b1 << 1,
    Quiz = 0b1 << 2,
    Exam = 0b1 << 3
}

export function displayAssignmentType(assignmentType: AssignmentType): string {
    switch(assignmentType) {
        case AssignmentType.Lesson:
            return 'Lesson';
        case AssignmentType.Project:
            return 'Project';
        case AssignmentType.Quiz:
            return 'Quiz';
        case AssignmentType.Exam:
            return 'Exam';

    }
}