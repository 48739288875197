import { useState } from "react";
import { Card, CardContent, CardHeader, Table, TableCell, TableRow, Typography } from "@mui/material";
import { CourseCurriculum } from "../../../../../models/data/course/course-curriculum.model";
import { CardActionButtons } from "../../../../shared/CardActionButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { getCourseCurriculum } from "../../../../../services/course.service";
import globalStore from "../../../../../app/global.store";
import { CurriculumEditForm } from "./CurriculumEditForm";

export function CurriculumList(props: {courseID: string}) {
    const [curriculum, setCurriculum] = useState<CourseCurriculum[]>(undefined);
    const [reload, setReload] = useState({});
    useSubscription(getCourseCurriculum(props.courseID), cc => setCurriculum(cc), [reload]);

    const edit = (c: CourseCurriculum) => 
        globalStore.pushDrawerFormContent(<CurriculumEditForm curriculum={c} onSaved={() => setReload({})} />);

    return <Card>
        <CardHeader 
            title={<Typography variant="h6">Curriculum</Typography>}
            action={<CardActionButtons                 
                buttons={[
                    {icon: <FontAwesomeIcon icon={["fas", "plus"]}/>, text: 'Add', action: () => edit({courseID: props.courseID})},                                        
                ]}/>}
        />
        <CardContent>
            <Table size="small"> {curriculum?.map(c =>
                <TableRow className="hover-grow" onClick={() => edit(c)}>
                    <TableCell>{c.name}</TableCell>
                </TableRow>)}
            </Table>
        </CardContent>
    </Card>
}

const Item = () => <></>