import { Page, View, Document, PDFViewer } from "@react-pdf/renderer";
import React, { useState } from "react";

import { Person } from "../../models/data/person.model";
import { AcademicTerm } from "../../models/data/settings/academic-term";
import { Header } from "./shared/Header";
import { SubHeader1 } from "./shared/SubHeader1";
import { PersonCourse, displayGrade } from "../../models/data/person-course/person-course.model";
import { useSubscription } from "../../hooks/useSubscription";
import { getCoursesForPerson } from "../../services/person-course.service";
import { combineLatest, map, of, withLatestFrom } from "rxjs";
import { TableRow } from "./shared/TableRow";
import { TableCell } from "./shared/TableCell";
import { PersonAcademicTerm } from "../../models/data/person-academic-term-model";

const style = {
    margin: '18px',
  };

export function CourseListReport(props: {student: Person, courses: PersonCourse[], academicTerm: PersonAcademicTerm}): JSX.Element {        
  return (
      <Document>          
          <Page size="A4" style={{...style}}>      
            <Header>Courses</Header>          
            <SubHeader1>{props.student.firstName} {props.student.lastName}</SubHeader1>
            <SubHeader1>{props.academicTerm.academicTermName}</SubHeader1>            

            <View style={{marginTop: '20pt', marginBottom: '6pt'}}>
              <TableRow>                  
                <TableCell text='Course' width='200pt' style={{fontSize: '14pt'}}/>
                <TableCell text='Grade' width='80pt' style={{fontSize: '14pt'}}/>                
                <TableCell text='Hours' width='80pt' style={{fontSize: '14pt'}}/>                 
              </TableRow>       
            </View>
            <View>                  
                {props.courses.map(c =>
                  <TableRow key={c.id}>                      
                      <TableCell text={c.course_Name} width='200pt' />
                      <TableCell text={displayGrade(c)} width='80pt' />
                      <TableCell text={c.statistics?.sumActivityHours.toString()} width='80pt' />       
                  </TableRow>            
                  )}           
            </View>
        </Page>        
    </Document>);
}