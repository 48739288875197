export const miscIcons = [
    'fas-flag-usa',
    'fas-flask',
    'fas-flask-vial',
    'fas-fire',
    'fas-snowflake',
    'fas-feather',
    'fas-magnifying-glass',
    'fas-music',
    'fas-heart',
    'fas-hippo',
    'fas-car',
    'fas-film',
    'fas-book',
    'fas-camera',
    'fas-video',    
    'fas-plane',
    'fas-money-bill',
    'fas-code',
    'fas-globe',
    'fas-truck',
    'fas-city',
    'fas-car-side',
    'fas-church',
    'fas-cross',
    'fas-calculator',
    'fas-cow',
    'fas-cloud-sun-rain',
    'fas-chess-rook',
    'fas-chart-line',
    'fas-cat',
    'fas-tents',
    'fas-laptop-code',
    'fas-landmark',
    'fas-mountain',
    'fas-viruses',
    'fas-user-doctor',
    'fas-user-astronaut',
    'fas-tornado',
    'fas-hammer',
    'fas-hanukiah',
    'fas-square-root-variable',
    'fas-superscript',
    'fas-book-bible',    
    'fas-network-wired',
    'fas-database',
    'fas-atom',
    'fas-seedling',
    'fas-frog',
    'fas-dna',
    'fas-compass-drafting',
    'fas-ruler',
    'fas-brush',
    'fas-briefcase',
    'fas-map',
];