import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, SxProps } from "@mui/material";

export function AppAvatar(props: {text?: string, bgColor?: string, textColor?: string, 
    icon?: string, 
    size?: string, 
    fontSize?: string, 
    className?: string, 
    onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void,
    border?: string,
}): JSX.Element {           
    return (
        <Avatar sx={{ 
            backgroundColor: props.bgColor, 
            color: props.textColor, 
            height: props.size || '30px', 
            width: props.size || '30px', 
            fontSize: props.fontSize || '16px',
            border: props.border,
        }}
            className={props.className}
            onClick={props.onClick}
            >
            {props.icon ?      
                <>{ props.icon.startsWith('fas-') || props.icon.startsWith('far-') 
                ? <FontAwesomeIcon icon={getFaIcon(props.icon)} />
                : <span className="material-symbols-outlined" style={{fontSize: props.fontSize || '20px'}}> 
                    {props.icon}
                </span>
                    
                }</>                           
                : <>{!!props.text && props.text.substring(0, 1)}</>
            }                                                    
        </Avatar>
    );
} 

export function getFaIcon(icon: string): [IconPrefix, IconName] | undefined {    
    const i = icon?.indexOf('-');
    if (i >= 0) {
        return [
            icon.substring(0, i) as IconPrefix,
            icon.substring(i + 1) as IconName,
        ];
    }
    else return undefined;
}