import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getAvatarColor, Person } from "../../../models/data/person.model";
import { getStudents } from "../../../services/person.service";
import { Page } from "../../shared/Page";
import Grid from '@mui/material/Unstable_Grid2';
import './students.scss';
import { useNavigate } from "react-router-dom";
import { forkJoin } from "rxjs";
import { AvatarCard } from "../../shared/AvatarCard";
import { LoaderPage } from "../../shared/LoaderPage";
import Add from "date-fns/add";
import dayjs from "dayjs";
import globalStore from "../../../app/global.store";
import { dayjs_format } from "../../../util/dates";
import { CenteredContent } from "../../shared/CenteredContent";
import { AppButton } from "../../shared/buttons/primary";
import { SchdeuleAddForm } from "../schedule/edit-drawer/ScheduleAddForm";
import { People } from "@mui/icons-material";


export function StudentList(props: {}): JSX.Element {
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState(false);
    const [students, setStudents] = useState<Person[]>([]);    

    useEffect(() => {        
        forkJoin([getStudents()]).subscribe(([s]) => {
            setStudents(s);                        
            setLoaded(true);
        });       
    }, []);

    return (<>{ loaded &&
        <Page title="Students">                            
            {students?.length ? 
                <Grid container spacing={2}>
                    {students?.map(s =>{  
                        const avatarColor = getAvatarColor(s);
                        return (
                            <Grid xs={12} md={6} xl={3} key={s.id}>
                                <AvatarCard 
                                    className="student-list-card"
                                    onClick={() => navigate(s.id, { state: { student: s}})}
                                    cardTitle={`${s.firstName} ${s.middleName} ${s.lastName}`}
                                    avatar={s.avatar}>
                                        <Typography component="div" variant="body1">
                                            {s.academicLevelName ? `${s.academicLevelName} grade` : <>&nbsp;</>}
                                        </Typography>
                                </AvatarCard>
                            </Grid>)}
                    )}
                </Grid>                                     
                : <CenteredContent>
                    <Typography variant="h6">
                        You don't have any students
                    </Typography>
                    <Box sx={{mt:1}}>
                        <AppButton 
                            icon={<People />}
                            text='Edit Household'
                            onClick={() => navigate('/settings/household')} 
                        />                              
                    </Box>      
                </CenteredContent>
            }
        </Page>
    }
    {!loaded && <LoaderPage />}       
    </>);
}

