import { BehaviorSubject, combineLatest, filter, Observable, of, shareReplay, switchMap, tap } from "rxjs";
import { PersonCourse } from "../../../../models/data/person-course/person-course.model";
import { Person } from "../../../../models/data/person.model";
import { getCoursesForPerson } from "../../../../services/person-course.service";
import { getPerson, getPersonAcademicTerms } from "../../../../services/person.service";
import { allLoaded } from "../../../../util/store.helper";
import { PersonAcademicTerm } from "../../../../models/data/person-academic-term-model";

export interface StudentStore {
    student$: BehaviorSubject<Person>;
    courses$: BehaviorSubject<PersonCourse[]>;
    loaded$: Observable<boolean>;
    academicTerm$: BehaviorSubject<PersonAcademicTerm>;
    academicTerms$: Observable<PersonAcademicTerm[]>;
}

function baseInit(): StudentStore {
    const store = {
        student$: new BehaviorSubject(undefined),
        courses$: new BehaviorSubject(undefined),
        loaded$: of(false),        
        academicTerm$: new BehaviorSubject<PersonAcademicTerm>({}),        
        academicTerms$: undefined,
    };
    store.academicTerms$ = store.student$.pipe(        
        filter(s => !!s), 
        switchMap(s => getPersonAcademicTerms(s.id)),
        shareReplay());

    combineLatest([store.student$, store.academicTerm$]).pipe(                
        switchMap(([s, t]) => s && t ? getCoursesForPerson(s.id, t.academicTermID) : of(undefined)),        
    ).subscribe(store.courses$)

    store.loaded$ = allLoaded([store.student$, store.courses$]);
    return store;
}

export function initStudentStoreFromStudent(student: Person): StudentStore {
    const store = baseInit();
    store.student$.next(student);

    return store;
}
export function initStudentStoreFromID(studentID: string): StudentStore {    
    const store = baseInit();    

    getPerson(studentID).subscribe(p => store.student$.next(p));
    return store;
}
export function reloadCourses(store: StudentStore) {
    store.academicTerm$.next({...store.academicTerm$.value});    
}