import { BehaviorSubject, Observable, switchMap, map, of, forkJoin, Subject, combineLatest, take, catchError, tap, merge } from "rxjs";
import { FormValidator } from "./validators";


export class FormData {    
    constructor(fields: FormFields) {
        this.fields = fields;
        
        Object.keys(fields).forEach(k => {
            fields[k].formData = this;
            fields[k].key = k;
        });        
     }

    fields: FormFields;
    isValid$ = () => {
        const fieldValidators$ = Object.keys(this.fields).map(f => this.fields[f].isValid$(false));        
        return combineLatest(fieldValidators$).pipe(                         
            // map(results => !results.find(r => !r.valid)),
            map(results => !results.includes(false)),
            catchError((e, o) => {                                
                console.error(e)
                return o;
            }),            
        );
    }    
    
    getValue(): any {
        return this.getValueAs<any>();
    }
    getValueAs<T>(): T {
        const value = {};
        Object.keys(this.fields).forEach(f => value[f] = this.fields[f].value$?.value);
        return value as T;
    };

    getFieldValue(field: string) {
        return this.fields[field].value$.value;
    }

    isBusy$ = new BehaviorSubject(false);
    setIsBusy = (busy: boolean) => this.isBusy$.next(busy);
}

export class FormFieldData {
    constructor(value: any, validators?: FormValidator[], dependencies?: string[]){
        this.value$ = new BehaviorSubject(value);
        this.validators = validators || [];

        this._dependencies = dependencies || [];        
    }
    value$: BehaviorSubject<any>;
    touched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    dependencyChanged$: Observable<boolean> = of(false);
    validators: FormValidator[];
    key: string;

    set formData(fd: FormData) {
        this._formData = fd;
        if(this._dependencies?.length) {
            const deps = this._dependencies.map(d => this._formData.fields[d] as FormFieldData).map(d => d.value$);            
            this.dependencyChanged$ = combineLatest(deps).pipe(map(v => true));
        }
    }
    private _formData: FormData;
    private _dependencies: string[] = [];

    isValid$ = (checkTouched = true) => 
        combineLatest([this.touched$, this.dependencyChanged$]).pipe(              
            switchMap(([t, _]) => t || !checkTouched ? applyValidators(this.value$, this._formData, this.validators): of(true)),
            // map(valid => {
            //     return {
            //         valid: valid,
            //         key: this.key,
            //     }
            // })
        );
        // this.touched$.pipe(                        
        //     switchMap(t => t || !checkTouched ? applyValidators(this.value$, this.formData, this.validators): of(true)),
        // );
}

function applyValidators(value$: BehaviorSubject<any>, form: FormData, validators: FormValidator[]): Observable<boolean> {
    return value$.pipe(map(v => !validators.some(isValid => !isValid(v, form))));
        
}

export type FormFields = {[name: string]: FormFieldData};