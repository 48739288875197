import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Course } from "../../../models/data/course/course.model";
import { getCourseByID } from "../../../services/course.service";
import { getAcademicLevels } from "../../../services/settings.service";
import { AcademicLevel } from "../../../models/data/settings/academic-level";
import { DetailTab } from "./tabs/detail/DetailTab";
import { AssignmentsTab } from "./tabs/assignment/AssignmentsTab";
import globalStateStore from "../../../app/global.store";
import { of } from "rxjs";

export function CourseDetail(): JSX.Element {
    const location = useLocation();
    const { id } = useParams();    
    const [course, setCourse] = useState<Course>(undefined);
    const [academicLevels, setAcademicLevels] = useState<AcademicLevel[]>();    
    const [activeTab, setActiveTab] = useState('detail');


    useEffect(() => {      
        const locationStateCourse = (location.state as any)?.course;
        getAcademicLevels().subscribe(al => {
            setAcademicLevels(al);
            if (id === 'add') {
                init({});            
            }
            else {
                (
                    locationStateCourse 
                    ? of(locationStateCourse)
                    : getCourseByID(id)
                ).subscribe((c) => {
                    init(c);                    
                });
            }
        });
            
        return () => globalStateStore.contextMenu$.next(undefined);
    }, []);    

    useEffect(() => {    
        globalStateStore.contextMenu$.next(
            { title: 'Course', items: [
                { text: 'Details', onClick: () => setActiveTab('detail'), active: true },            
                course?.id ? { text: 'Assignments', onClick: () => setActiveTab('assignments') } : undefined,                
            ].filter(i => !!i)}
        );
    }, [course?.id]);

    return (<>
            {activeTab === 'detail' &&
                <DetailTab course={course} academicLevels={academicLevels} onSave={(c) => init(c)}/>
            }        
            {activeTab === 'assignments' &&
                <AssignmentsTab course={course}/>
            }</>               
    );

    function init(course: Course) {
        setCourse(course);        
    };


}