import { Autocomplete, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { FormFieldData } from "./form-data";
import { FormDataContext } from "./Form";
import { SelectOption } from "./FormSelect";

export function FormAutocomplete(props: {
    fieldName: string,
    label: string,    
    options: SelectOption[],
    required?: boolean,
    disabled?: boolean,
}) {
    const formData = useContext(FormDataContext);
    const [field, setField] = useState<FormFieldData>(undefined);
    const [value, setValue] = useState<SelectOption>(null);
    const [hasError, setHasError] = useState<boolean>(false);

    const onChange = (e, value: SelectOption) => {        
        field.value$.next(value?.id);        
    };
    const onTouch = () => field.touched$.next(true);

    useEffect(() => {
        const cleanup$ = new Subject<void>();
        
        if (formData) {
            if (!(props.fieldName in formData.fields)) throw Error('Field not found in formData: ' + props.fieldName);
            const field = formData.fields[props.fieldName];
            field.value$.pipe(takeUntil(cleanup$)).subscribe(val => setValue(props.options?.find(o => o.id === val) || null));
            field.isValid$().pipe(takeUntil(cleanup$)).subscribe(valid => setHasError(!valid));            
            setField(field);
        }

        return () => cleanup$.next();
    }, [props.fieldName, formData, props.options]);

    return (field
        ?                              
        <Autocomplete                        
            size="small"                                        
            value={value}
            options={props.options || []}     
            getOptionLabel={(option: SelectOption) => option.text}         
            loading={!props.options}                              
            renderInput={(params) => <TextField size="small" {...params} label={props.label} required={!!props.required} error={hasError}/>}
            onChange={onChange} 
            onBlur={_ => onTouch()}       
            sx={{maxWidth: '100%'}}       
            disabled={!!props.disabled}                                                                                                                                                                     
        />
        : <></>
    );
}
