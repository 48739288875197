import { Observable } from "rxjs";
import { CourseAssignment } from "../models/data/course/course-assignment.model";
import { CourseSection } from "../models/data/course/course-section.model";
import { Course, CourseDetails } from "../models/data/course/course.model";
import { apiDelete, apiGet, apiPost } from "./api-service";
import { SharedCourse } from "../models/data/course/shared-course.model";
import { CourseCurriculum } from "../models/data/course/course-curriculum.model";

export function getCoursesForOrganization(): Observable<Course[]> {
    return apiGet<Course[]>(`course/GetForOrganization`);
}

export function getCourseByID(id: string): Observable<Course> {
    return apiGet<Course>(`course/${id}`);
}

export function getCourseDetails(id: string): Observable<CourseDetails> {
    return apiGet<CourseDetails>(`course/${id}/details`);
}

export function getCourseSections(id: string): Observable<CourseSection[]> {
    return apiGet<CourseSection[]>(`course/${id}/sections`);
}

export function saveCourse(course: Course): Observable<Course> {
    return apiPost<Course>(`course/save`, course);
}

export function saveCourseSection(courseSection: CourseSection): Observable<CourseSection> {
    return apiPost<CourseSection>(`course/${courseSection.courseID}/section/save`, courseSection);
}

export function saveCourseAssignment(courseAssignment: CourseAssignment): Observable<CourseAssignment> {
    return apiPost<CourseAssignment>(`course/${courseAssignment.courseID}/assignment/save`, courseAssignment);
}

export function reorderCourseItems(courseID: string, items: {id: string, children?: string[]}[]): Observable<CourseAssignment> {
    return apiPost<any>(`course/${courseID}/items/reorder`, items);
}

export function shareCourse(courseID: string, targetAccount: string) {
    return apiPost<string>(`course/${courseID}/share`, targetAccount);
}

export function getSharedCourses() {
    return apiGet<SharedCourse[]>(`course/shared`);
}

export function acceptSharedCourse(sharedCourse: SharedCourse) {
    return apiPost<Course>(`course/shared/${sharedCourse.id}/accept`, {});
}

export function declineSharedCourse(sharedCourse: SharedCourse) {
    return apiPost<SharedCourse>(`course/shared/${sharedCourse.id}/decline`, {});
}

export function getCourseCurriculum(courseID: string) {
    return apiGet<CourseCurriculum[]>(`course/${courseID}/curriculum`);
}

export function saveCourseCurriculum(curriculum: CourseCurriculum) {
    return apiPost<CourseCurriculum>(`course/${curriculum.courseID}/curriculum`, curriculum);
}

export function deleteSection(courseSection: CourseSection) {
    return apiDelete(`course/${courseSection.courseID}/section/${courseSection.id}`);
}
export function deleteAssignment(assignment: CourseAssignment) {
    return apiDelete(`course/${assignment.courseID}/assignment/${assignment.id}`);
}