import { Card, CardHeader, CardContent, Typography, Chip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Subscription, filter, take, Subject, BehaviorSubject } from "rxjs";
import { PersonCourseAssignment } from "../../../../../../models/data/person-course/person-course-assignment.model";
import { PersonCourse } from "../../../../../../models/data/person-course/person-course.model";
import { Person } from "../../../../../../models/data/person.model";
import { LoaderPage } from "../../../../../shared/LoaderPage";
import { Page } from "../../../../../shared/Page";
import { PersonCourseAssignmentOrSection, StudentCourseContextStore } from "../student-course-context.store";
import { AssignmentStepper } from "./AssignmentStepper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppButton } from "../../../../../shared/buttons/primary";
import globalStore from "../../../../../../app/global.store";
import { StudentAssignmentEditForm } from "./StudentAssignmentEditForm";
import { useSubscription } from "../../../../../../hooks/useSubscription";

export function StudentCourseAssignments(): JSX.Element {      
    const store = useOutletContext<StudentCourseContextStore>();

    const [personCourse, setPersonCourse] = useState<PersonCourse>();
    const [person, setPerson] = useState<Person>();
    const [assignmentsAndSections, setAssignmentsAndSections] = useState<PersonCourseAssignmentOrSection[]>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [selectedAssignment, setSelectedAssignment] = useState<PersonCourseAssignment>();

    useSubscription(store?.personCourse$, pc => setPersonCourse(pc), [store]);    
    useSubscription(store?.person$, p => setPerson(p), [store]);
    useSubscription(store?.personCourseAssignmentsAndSections$, as => setAssignmentsAndSections(as), [store]);            
    useSubscription(store?.selectedAssignment$, a => setSelectedAssignment(a), [store]);            
    useSubscription(store?.loaded$.pipe(filter(l => l), take(1)), () => setLoaded(true), [store]);          

    return (<>{loaded &&
        <Page breadcrumbs={loaded ? [{text: 'Students'}, {text: `${person?.firstName} ${person?.lastName}`, url: '../../'}, {text: personCourse?.course_Name, url: '../'}, {text: 'Assignments'}] : []}
            title='Assignments'>                                               
            <Grid container spacing={2}>            
                <Grid xs={12} md={3}>
                    <Card>                                          
                        <CardContent>
                            <AssignmentStepper items={assignmentsAndSections} />                            
                        </CardContent>
                    </Card>                             
                </Grid>              
                <Grid xs={12} md={9}>                        
                    {selectedAssignment && 
                        <Card>     
                            <CardHeader title={<>
                                <Typography variant="h6">{selectedAssignment.title}</Typography>
                                <CompletionDetails assignment={selectedAssignment} />
                            </>                            
                            }
                            action={
                                <AppButton type='primary' onClick={() => editAssignment(selectedAssignment)} icon={<FontAwesomeIcon icon={["fas", "pencil"]} />} text='Edit' />
                            }/>                                                            
                            <CardContent>
                            
                                <div>
                                    <Typography variant="body1">{selectedAssignment.description}</Typography>
                                </div>                                                            
                            </CardContent>
                        </Card>     
                    }
                </Grid>               
            </Grid>
        </Page>
    }
    {!loaded && <LoaderPage />}</>);

    function editAssignment(assignment: PersonCourseAssignment) {                
        globalStore.drawerFormContentStackPush$.next(
            <StudentAssignmentEditForm 
                assignment={assignment} 
                store={store} />            
        );
    }
}

function CompletionDetails(props: {assignment: PersonCourseAssignment}) {
    return <>
        {!!props.assignment.completedDate &&                         
            <Typography variant="subtitle2" sx={{display: 'inline-block'}}>                        
                <Chip label={<><FontAwesomeIcon icon={["fas", "check"]} /> Completed {new Date(props.assignment.completedDate).toLocaleDateString()}</>} 
                    color="success" size="small"/>                
                
                {!!props.assignment.pointsPossible && !!props.assignment.pointsEarned &&                                                 
                    <Chip 
                        label={<>                            
                            {(((props.assignment.pointsEarned || 0)/props.assignment.pointsPossible) * 100).toFixed(0)}
                            &nbsp;
                            <FontAwesomeIcon icon={["fas", "percent"]} />
                        </>}
                        size='small'
                        sx={{marginLeft: '8px'}}
                    />                                                                                                
                }
            </Typography>
        }
    </>;
}