import { Page } from "../../shared/Page";
import Grid from "@mui/material/Unstable_Grid2";
import { ActivityBasicTable } from "./ActivityBasicTable/ActivityBasicTable";

export function ActivityPage(props: {}): JSX.Element {    
    return (
        <Page title="Completed Instruction">
            <Grid container spacing={2}>                                        
                <Grid xs={12}>
                    <ActivityBasicTable />
                </Grid>      
            </Grid>
            
        </Page>
    );
}
