import { createContext, useEffect, useMemo, useState } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './components/pages/Login';
import { Home as HomePage } from './components/pages/Home';
import { Layout as LayoutPage } from './components/pages/layout/Layout';
import { StyledEngineProvider, Theme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { atTheme, atThemeDark } from './app/theme';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { HouseholdDetail } from './components/pages/settings/household-detail/HouseholdDetail';
import { PersonProfile } from './components/pages/settings/household-detail/PersonProfile/PersonProfile';
import { AcademicLevelList } from './components/pages/settings/academic-level/AcademicLevelList';
import { AcademicTermList } from './components/pages/settings/academic-term/AcademicTermList';
import { SubjectList } from './components/pages/settings/subject/SubjectList';
import { CourseList } from './components/pages/courses/CourseList';
import { CourseDetail } from './components/pages/courses/CourseDetail';
import { StudentList } from './components/pages/students/StudentList';
import { StudentDetail } from './components/pages/students/student/StudentDetail';
import { StudentCourse } from './components/pages/students/student/student-course/StudentCourse';
import { MobileLayout } from './components/pages/layout/mobile-layout';
import { DESKTOP_BREAKPOINT } from './app/constants';
import { ActivityPage } from './components/pages/activity/Activity';
import globalStore from './app/global.store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons'
import { User } from './models/app/user';
import { StudentCourseDetail } from './components/pages/students/student/student-course/StudentCourseDetail';
import { StudentCourseAssignments } from './components/pages/students/student/student-course/assignments/StudentCourseAssignments';
import { FormDrawer } from './components/pages/layout/form-drawer/FormDrawer';
import { Toast } from './components/pages/layout/Toast';
import { Student } from './components/pages/students/student/Student';
import { LoaderPage } from './components/shared/LoaderPage';
import { SchedulePage } from './components/pages/schedule/Schedule';
import { ConfirmDialog } from './components/shared/confirm-dialog/ConfirmDialog';
import { jwtValid } from './util/jwt';
import { refreshAuthToken } from './services/auth.service';
import { switchMap } from 'rxjs';
import { Cache } from './app/cache.context';
import { ReportModal } from './components/pages/layout/ReportModal';
import { ErrorBoundary } from './components/shared/ErrorBoundary';

export const CacheContext = createContext<Cache>(undefined)

function App() {
  library.add(fas, far);

  const storedTheme = localStorage.getItem('theme');

  const [tokenChecked, setTokenChecked] = useState(false);
  const [themeName, setThemeName] = useState<'light' | 'dark' | ''>();
  const appTheme = useMemo<Theme>(() => themeName === 'dark' ? atThemeDark : atTheme, [themeName]);
  const useMobileLayout = !useMediaQuery(DESKTOP_BREAKPOINT);
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');        
    if (storedToken) {      
      const refresh = localStorage.getItem('refresh');    
      if (jwtValid(storedToken)) {        
        globalStore.loginSuccess$({token: storedToken, refresh: refresh}).subscribe();      
      }      
      else {         
        if (refresh) {                  
          refreshAuthToken({token: storedToken, refresh: refresh}).pipe(switchMap(t => globalStore.loginSuccess$(t))).subscribe({ error: e => globalStore.signOut() });
        }
      }
    }

    globalStore.theme$.next(storedTheme === 'light' || storedTheme === 'dark' ? storedTheme : 'light');
    globalStore.theme$.subscribe(t => setThemeName(t));
    globalStore.token$.subscribe(t => setTokenChecked(!!t));     
    globalStore.user$.subscribe(u => setUser(u));     
  }, []);  

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CacheContext.Provider value={new Cache()}>
          <CssBaseline />
          <div className={`app-${themeName}`}>
            {!user
                ? (tokenChecked ? <LoaderPage /> : <LoginPage />)
                :<>              
                  <FormDrawer />  
                  <Toast />     
                  <ConfirmDialog />
                  <ReportModal />
                  
                  <AppRoutes layout={
                    useMobileLayout 
                      ? <MobileLayout /> 
                      : <LayoutPage />
                    }/>                           
                  </>           
            }
          </div>
        </CacheContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function AppRoutes(props: {layout: JSX.Element}) {
  const errorBound = (p: JSX.Element) => <ErrorBoundary>{p}</ErrorBoundary>;
  return errorBound(<BrowserRouter>
            <Routes>
              <Route path="/" element={props.layout}>
                <Route index element={errorBound(<HomePage />)} />
                <Route path='student'>
                  <Route path=':studentId' element={errorBound(<StudentDetail />)}></Route>
                  <Route path='course/:personCourseId' element={errorBound(<StudentCourse />)}></Route>                      
                </Route>
                <Route path='students'>
                  <Route index element={errorBound(<StudentList />)}></Route>
                  <Route path=':studentId' element={errorBound(<Student />)}>
                    <Route path='courses/:personCourseId' element={errorBound(<StudentCourse />)}>
                      <Route path='assignments' element={errorBound(<StudentCourseAssignments />)} />
                      <Route index element={errorBound(<StudentCourseDetail />)} />
                    </Route>
                    <Route index element={errorBound(<StudentDetail />)} />
                  </Route>
                </Route>
                <Route path='activity' >
                  <Route index element={errorBound(<ActivityPage />)}></Route>
                </Route>
                <Route path='schedule' >
                  <Route index element={errorBound(<SchedulePage />)}></Route>
                </Route>
                <Route path='courses'>
                    <Route path=':id' element={errorBound(<CourseDetail />)}></Route>
                    <Route index element={errorBound(<CourseList />)}></Route>
                </Route>
                <Route path='settings'>
                  <Route path='household'>
                    <Route path='person/:id' element={errorBound(<PersonProfile />)}></Route>
                    <Route index element={errorBound(<HouseholdDetail />)}></Route>
                  </Route>
                  <Route path='terms' element={errorBound(<AcademicTermList />)}></Route>
                  <Route path='grades' element={errorBound(<AcademicLevelList />)}></Route>
                  <Route path='subjects' element={errorBound(<SubjectList />)}></Route>
                </Route>
              </Route>
            </Routes>                              
          </BrowserRouter>)
}

export default App;
