import { Checkbox, FormControlLabel, Switch, TextField, TextFieldProps, useAutocomplete } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { FormFieldData } from "./form-data";
import { FormDataContext } from "./Form";

export function FormBool(props: {
    fieldName: string, 
    label: string,    
    type?: 'checkbox' | 'switch',
}) {
    const formData = useContext(FormDataContext);
    const [field, setField] = useState<FormFieldData>(undefined);
    const [value, setValue] = useState<boolean>(false);
    
    const onTouch = () => field.touched$.next(true);
    const onChange = (_, c: boolean) => { 
        field.value$.next(c); 
        onTouch();
    }
    useEffect(() => {
        const cleanup$ = new Subject<void>();
        
        if (formData) {
            if (!(props.fieldName in formData.fields)) throw Error('Key not found in formData: ' + props.fieldName);
            const field = formData.fields[props.fieldName];
            field.value$.pipe(takeUntil(cleanup$)).subscribe(val => setValue(val));     
            setField(field);
        }

        return () => cleanup$.next();
    }, [props.fieldName, formData]);

    return (!!field &&
        <FormControlLabel 
            control={
                props.type === 'switch' 
                    ? <Switch checked={value} onChange={onChange}/>
                    : <Checkbox checked={value} onChange={onChange}/>
            }
            label={props.label}
        />
    );
}