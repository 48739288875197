import { useState } from "react";
import { BehaviorSubject, Observable, shareReplay } from "rxjs";
import { Person } from "../../models/data/person.model";
import { getStudents } from "../../services/person.service";
import { useSubscription } from "../../hooks/useSubscription";
import { Box, Chip } from "@mui/material";
import { Check } from "@mui/icons-material";
import { AppAvatar2 } from "./AppAvatar2";

export function PersonSelectionChips(props: { selected$: BehaviorSubject<string[]>, persons$?: Observable<Person[]> }) {
    const [students, setStudents] = useState<Person[]>(undefined);
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

    useSubscription((props.persons$ || getStudents().pipe(shareReplay(1))), students => {
        setStudents(students);
    }, [props.persons$]);

    useSubscription(props.selected$, s => setSelectedStudents(s), [props.selected$]);

    const toggleStudentSelection = (id: string) => { 
        selectedStudents?.includes(id) 
            ? props.selected$.next(selectedStudents?.filter(s => s !== id) || []) 
            : props.selected$.next(selectedStudents.concat([id]));
    }

    return <>
        {!!students?.length && 
            <Box sx={{mt: 1, justifyItems: 'center'}}>
                { students?.map(s => {
                        return {...s, selected: !!selectedStudents?.includes(s.id)}
                    }).map(s => 
                        <Chip key={s.id}                            
                            label={s.firstName}
                            variant={s.selected ? 'filled' : 'outlined'}
                            avatar={<AppAvatar2 avatarDefinition={s.avatar} size="24px" fontSize="12px" />}
                            deleteIcon={s.selected ? <Check /> : undefined}
                            onClick={_ => toggleStudentSelection(s.id)}
                            onDelete={s.selected ? _ => toggleStudentSelection(s.id) : undefined}
                            sx={{mr: .5, mb: .5}}                            
                        />
                    )
                }
            </Box>
        }
    </>
}