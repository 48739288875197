import { Typography, Stack, Alert } from "@mui/material";
import { Subject } from "rxjs";
import globalStore from "../../../../../app/global.store";
import { Form } from "../../../../shared/form/Form";
import { FormActions } from "../../../../shared/form/FormActions";
import { FormTextField } from "../../../../shared/form/FormTextField";
import { FormFieldData, FormData } from "../../../../shared/form/form-data";
import { requiredString, stringMinLength } from "../../../../shared/form/validators";
import { DrawerFormBody } from "../../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../../layout/form-drawer/DrawerFormHeader";
import { changeSelfPassword } from "../../../../../services/person.service";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { useEffect, useState } from "react";

export function ChangePassword() {
    const [formData, setFormData] = useState<FormData>();
    const [pwValid, setPwValid] = useState(false);
    const passwordsMatch = (v: any, f: FormData): boolean => v && v === f.fields.pw?.value$?.value;

    useEffect(() => {
        const formData = new FormData({
            pw: new FormFieldData('', [requiredString, stringMinLength(8)]),
            pw2: new FormFieldData('', [passwordsMatch], ['pw']),
        });
        setFormData(formData);
    }, []);        

    useSubscription(formData?.fields.pw.isValid$(true), v => setPwValid(v), [formData]);

    const save = () => {
        changeSelfPassword(formData.getValue().pw).subscribe({
            next: _ => {
                globalStore.showToast('success', 'Password changed');
                globalStore.popDrawerFormContent();
            }, 
            error: _ => globalStore.showToast('error', 'Error changing password')
        });
    };

    return <DrawerFormContainer>{ formData &&            
        <Form formData={formData}>            
            <DrawerFormHeader>
                <Typography variant="h6">Change Password</Typography>
            </DrawerFormHeader>            
            <DrawerFormBody>                          
                <Stack spacing={2} sx={{width: '275px'}}>
                    {!pwValid && 
                        <Alert variant="outlined" severity="error" style={{width: '100%', marginTop:'15px'}}>
                            Password must be at least 8 characters
                        </Alert>    
                    }
                    <FormTextField                                       
                        fieldName="pw" required={true} label="New Password" textFieldProps={{type: 'password'}}/>
                    <FormTextField                   
                        fieldName="pw2" required={true} label="Confirm New Password" textFieldProps={{type: 'password'}}/>                    
                </Stack>                             
            </DrawerFormBody>    
            <DrawerFormFooter>
                <FormActions     
                    saveAction={() => save()}                         
                    cancelAction={() => globalStore.popDrawerFormContent()}/>                    
            </DrawerFormFooter>
        </Form>}
    </DrawerFormContainer>;
}