import { MoreVertOutlined, LightMode as LightModeIcon, DarkMode as DarkModeIcon, ArrowBack as BackIcon } from "@mui/icons-material";
import { Button, Divider, Drawer, IconButton, List, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Location } from "react-router-dom";
import globalStateStore from "../../../../../app/global.store";
import { Menus } from "../../../../../app/menus";
import { GlobalFunctions } from "../../../../../models/app/global-functions";
import { Menu, MenuItem } from "../../../../../models/app/menu-item";
import './mobile-nav.scss';


export function MobileNav(props: {    
    contextMenu?: Menu,
}): JSX.Element {    
    const navigate = useNavigate();   
    let location: Location = useLocation();    
    const activeMenus = getActiveMenu(location);        
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentSubmenu, setCurrentSubmenu] = useState(activeMenus.activeMenu?.subMenu);
    const [themeName, setThemeName] = useState<'light' | 'dark'>();
        
    useEffect(() => {
        setCurrentSubmenu(activeMenus.activeMenu?.subMenu);
    }, [activeMenus.activeMenu]);
    
    useEffect(() => {
        globalStateStore.theme$.subscribe(t => setThemeName(t));
    }, []);

    return (   
        <>
        <div className={`bottom-nav`}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', maxWidth: '360px'}}>
                    {Menus.TOP.items.slice(0, 4).map(mi =>                                      
                        <button  
                            className={`nav-button ${activeMenus.activeMenu === mi ? 'active' : ''}`} 
                            onClick={() => 
                                navigate((mi.subMenu && mi.subMenu.items[0].path) || mi.path)
                            } key={mi.text}>                                                        
                            <div>{mi.icon}</div>
                            <div className='nav-button-label'>{mi.text}</div>
                        </button>                      
                    )}
                    <button  
                        className={`nav-button`} 
                        onClick={() => setDrawerOpen(true)} key='more'>                                                        
                        <div><MoreVertOutlined /></div>
                        <div className='nav-button-label'>More</div>
                    </button>                   
                </div>             
            </div>                       
        </div>
        <Drawer
            anchor='bottom'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}            
          >      
          <div className={`nav-drawer ${themeName}`}>
            {!currentSubmenu && <TopMenu />}         
            {currentSubmenu  && <Submenu />}    
            </div>
          </Drawer>
        </>         
    );

    function TopMenu(): JSX.Element {
        return(
            <List>                
                {Menus.TOP.items.slice(4).map(mi =>
                <ListItemButton className={`nav-drawer-button ${activeMenus.activeMenu === mi ? 'active' : ''}`} 
                    onClick={() => {
                        if (mi.path) {
                            setDrawerOpen(false);
                            navigate(mi.path)
                        }
                        else if (mi.subMenu) {
                            setCurrentSubmenu(mi.subMenu);
                        }                        
                    }} 
                    key={mi.text}
                    sx={{justifyContent: 'center'}}
                    >
                    <div className='icon'>{mi.icon}</div>
                    <div className="text">{mi.text}</div>
                </ListItemButton>  
                )}               
                <Divider sx={{my: '12px'}}/>                
                <ListItemButton className={`nav-drawer-button`} onClick={() => globalStateStore.signOut()} key='signout'> 
                    Sign Out       
                </ListItemButton>  
                <div style={{display: 'flex', justifyContent:'center'}}>
                    <IconButton onClick={() => {
                        globalStateStore.toggleTheme();
                        setDrawerOpen(false);
                        }} key='toggletheme'>                        
                        {themeName === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>                     
                </div>                     
            </List>
        );
    }

    function Submenu() {
        return (
            <List>                 
                {currentSubmenu.items.map(mi =>
                <ListItemButton className={`nav-drawer-button ${activeMenus.activeSubmenu === mi ? 'active' : ''}`} 
                    onClick={() => {                        
                        setDrawerOpen(false);
                        navigate(mi.path)                                                                
                    }} 
                    key={mi.text}
                    sx={{justifyContent: 'center'}}
                    >
                    <div className='icon'>{mi.icon && mi.icon}</div>
                    <div className="text">{mi.text}</div>
                </ListItemButton>  
                )}

                <ListItemButton className={`nav-drawer-button`} 
                    onClick={() => {                        
                        setCurrentSubmenu(undefined);
                    }} 
                    key={'back'}
                    sx={{justifyContent: 'center', fontWeight: '900'}}>                    
                    <div className="text">
                        <BackIcon />                        
                    </div>
                </ListItemButton>  
            </List>
        );
    }
}



function getActiveMenu(location: Location): {activeMenu?: MenuItem, activeSubmenu?: MenuItem} {
    const retval: {activeMenu?: MenuItem, activeSubmenu?: MenuItem}  = {};
    retval.activeMenu = Menus.TOP.items.find(mi => menuIsActive(mi, location));
    retval.activeSubmenu = retval.activeMenu?.subMenu && retval.activeMenu.subMenu.items.find(mi => menuIsActive(mi, location));        

    return retval;    
}
function menuIsActive(item: MenuItem, location: Location): boolean {
    let active = false;
    if (item.path === '/') {
      active = location.pathname === '/';
    }
    else {
      active = location.pathname.startsWith(item.basePath || item.path);
    }

    return active;
}