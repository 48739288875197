import { MAX_DATE } from "../../../util/dates";
import { AvatarDefinition } from "../shared/avatar-definition";
import { ScheduledActivity, ScheduledActivityType } from "./scheduled-activity.model";

export interface ScheduledActivityRecurring {
    id?: string,
    personID?: string;
    organizationID?: string;
    type?: ScheduledActivityType;
    recurrencePeriod?: RecurrencePeriod;
    recurrenceDays?: number[];
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    durationMinutes?: number;
    personCourseID?: string;
    personCourseAssignmentID?: string;
    title?: string;
    description?: string;

    personFirstName?: string;
    personMiddleName?: string;
    personLastName?: string; 
    personAvatar?: AvatarDefinition;

    courseName?: string;
    assignmentTitle?: string;
    assignmentDesc?: string;
    completedDurationMinutes?: number;
}

export enum RecurrencePeriod {
    Week = 1,
}

export const makeRecurrenceDateFilter = (date: Date) => 
    (r: ScheduledActivityRecurring) => 
        new Date(r.startDate) <= date 
            && new Date(r.endDate || MAX_DATE) >= date
            && r.recurrenceDays?.includes(date.getDay());