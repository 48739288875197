import { Box, Button, IconButton, SxProps, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import { CSSProperties } from "react";
import { DESKTOP_BREAKPOINT } from "../../../app/constants";
// import styles from './buttons.module.scss';
import './buttons.scss';

type BtnChildren = JSX.Element | JSX.Element | string;


export function AppButton(props: {
    onClick?: (a?: any) => void, 
    text?: string, 
    icon?: JSX.Element, 
    style?: CSSProperties,
    sx?: SxProps,
    size?: 'small'|'medium'|'large',
    // children?: BtnChildren | BtnChildren[],
    type?: 'primary' | 'secondary' | 'success' | 'error' | 'neutral',    
    fullWidth?: boolean,    
    buttonType?: 'button' | 'submit',
    disabled?: boolean,
    // iconOnly?: boolean,
    // iconOnlyMobile?: boolean,
}): JSX.Element {
    const isMobile = !useMediaQuery(DESKTOP_BREAKPOINT);
    
    return (
        //(props.iconOnly || (props.iconOnlyMobile && isMobile)) 
        // ?
        // <IconButton 
        //     // variant="contained"            
        //     size={isMobile ? 'large' : (props.size || 'medium')} 
        //     onClick={props.onClick} 
        //     style={{...props.style}}
        //     className={`appButton ${props.type || 'primary'}`}            
        //     sx={{...props.sx}}        
        //     type={props.buttonType || 'button'}   
        //     disabled={!!props.disabled} >            
        //     {
        //         props.children 
        //         ? props.children 
        //         : props.icon
        //     }            
        // </IconButton>
        // :
        <Button variant="contained"            
            // size={isMobile ? 'large' : (props.size || 'medium')} 
            size={props.size || 'medium'}
            onClick={props.onClick} 
            style={{...props.style}}
            className={`appButton ${props.type || 'primary'}`}
            fullWidth={!!props.fullWidth}
            sx={{...props.sx}}        
            type={props.buttonType || 'button'}   
            disabled={!!props.disabled} 
            >   
            { //props.children ? props.children : 
                <>
                    {!!props.icon && props.icon}
                    {!!props.icon && !!props.text && <>&nbsp;&nbsp;&nbsp;</>}
                    {!!props.text && props.text}
                </>         
            }
            {/* <Stack spacing={1} direction='row'>{
                props.children ? props.children : 
                <>
                    {!!props.icon && <span>{props.icon}</span>}
                    {!!props.text && <span>{props.text}</span>}
                </>
            }</Stack>             */}
        </Button>
    );
}