import { PersonCourseStatistics } from "./person-course-statistics.model";
import * as Util from "../../../util/grade";
import { AvatarDefinition } from "../shared/avatar-definition";

export interface PersonCourse {
    id?: string;
    personID?: string;
    courseID?: string;
    courseVersion?: number;
    academicTermID?: string;
    avatar?: AvatarDefinition;
    
    course_Name?: string;
    academicTerm_Name?: string;
    course_HoursRequired?: number;  

    statistics?: PersonCourseStatistics,
}

export function displayGrade(pc: PersonCourse, decimals?: number): string {
    return pc?.statistics?.displayGrade || ''; 
}