import { TableRow, TableCell, Skeleton, Table, TableBody, TableHead } from "@mui/material";
import { CourseAssignment } from "../../models/data/course/course-assignment.model";
import { CourseSection } from "../../models/data/course/course-section.model";

export function LoadingTable(props: { rows?: number}): JSX.Element {
    const rows = [];
    for (let i = 0; i < (props.rows || 3); i++) {
        rows.push(i);
    }

    return (
        <>
        <Table size="medium">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>                    
                </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((i) =>
            <TableRow key={i}>
                <TableCell>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
            </TableRow>)
            }               
            </TableBody>
        </Table>        
        </>
    );
}