import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Typography, CardContent, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Subscription, distinctUntilChanged } from "rxjs";
import globalStore from "../../../../../../app/global.store";
import { AppButton } from "../../../../../shared/buttons/primary";
import { PersonCourseAssignmentOrSection, StudentCourseContextStore } from "../student-course-context.store";
import { AssignmentStepper } from "./AssignmentStepper";
import { StudentAssignmentEditForm } from "./StudentAssignmentEditForm";
import { useSubscription } from "../../../../../../hooks/useSubscription";

export function StudentCourseAssignmentCard(props: {}): JSX.Element {
    const store = useOutletContext<StudentCourseContextStore>();
    const [assignmentsAndSections, setAssignmentsAndSections] = useState<PersonCourseAssignmentOrSection[]>();
    const navigate = useNavigate();

    useSubscription(store?.personCourseAssignmentsAndSections$, as => setAssignmentsAndSections(as), [store]);
    useSubscription(store?.selectedAssignment$, a => a && globalStore.pushDrawerFormContent(<StudentAssignmentEditForm assignment={a} store={store} />), [store]);

    useEffect(() => {        
        store?.selectedAssignmentId$.next(undefined);        
    }, [store]);

    return <> {assignmentsAndSections && 
        <Card sx={{position: 'relative'}}>
            <CardHeader title={                            
                <Typography component="h6" variant="h6">
                    Assignments
                </Typography>                            
            } 
            // action={
            //     <AppButton type='primary' onClick={() => navigate('./assignments')} text='View All' icon={<FontAwesomeIcon icon={["fas", "list-check"]}/>}/>
            // }
                />
            <CardContent sx={{overflow: 'auto'}} className='no-scrollbars'>                            
                <AssignmentStepper items={assignmentsAndSections} />
            </CardContent>               
        </Card>
    }
    </>;
}