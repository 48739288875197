import { FormData } from "./form-data";

export type FormValidator = (value: any, form?: FormData) => boolean;

export const requiredString: FormValidator = (v: string) => !!v?.length;
export const requiredDate: FormValidator = (d: Date | string) => d && new Date(d).getTime() >= new Date(1900, 0, 1).getTime();
export function maxNumber(max: number): FormValidator {return (n: number) => n <= max }
export function minNumber(min: number): FormValidator {return (n: number) => n >= min }
export const requiredNumber: FormValidator = (n: number) => !!n || n === 0;
export function range(min: number, max: number): FormValidator { return (n: number) => minNumber(min)(n) && maxNumber(max)(n) }
export const stringMinLength = (required: number): FormValidator => (v: string) => v?.length >= required;