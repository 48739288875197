import { Add as AddIcon, Edit as EditIcon, LibraryBooksOutlined as LibraryIcon, MoreVert as MoreVertIcon, 
    ExpandLess as CollapseIcon, 
    ExpandMore as ExpandIcon, UnfoldMore as ChangeOrderIcon, ArrowUpwardOutlined, ArrowDownwardOutlined, 
    Check, AssignmentOutlined as SectionIcon, TaskOutlined as AssignmentIcon, Save, Cancel, CancelOutlined, CloseOutlined, ExpandMore, ExpandLess, Add } from "@mui/icons-material";
import { Course } from "../../../../../models/data/course/course.model";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, Typography, CardContent, MenuItem, Box, Table, TableRow, TableCell, IconButton, TableHead, Menu, TableBody, Button, Stack, CardActions, CardHeader } from "@mui/material";
import { CourseSection } from "../../../../../models/data/course/course-section.model";
import { useEffect, useState } from "react";
import { CourseSectionModal } from "./CourseSectionModal";
import { Toast } from "../../../../shared/Toast";
import { getCourseDetails, reorderCourseItems } from "../../../../../services/course.service";
import { CourseAssignment } from "../../../../../models/data/course/course-assignment.model";
import { AssignmentDetail } from "./AssignmentDetail";
import { LoadingTable } from "../../../../shared/LoadingTable";
import { AppButton } from "../../../../shared/buttons/primary";
import globalStore from "../../../../../app/global.store";
import { Page } from "../../../../shared/Page";
import { CardActionButtons } from "../../../../shared/CardActionButtons";
import { AppAvatar2 } from "../../../../shared/AppAvatar2";

export function AssignmentsTab(props: { course: Course }): JSX.Element {    
    const [loading, setLoading] = useState(true);
    const [sectionForEdit, setSectionForEdit] = useState<CourseSection>();
    const [assignmentForEdit, setAssignmentForEdit] = useState<CourseAssignment>();    

    const [reordering, setReordering] = useState(false);

    const [items, setItems] = useState<SectionOrAssignment[]>();

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        setLoading(true);
        getCourseDetails(props.course.id).subscribe(cd => {            
            setItems(buildTree(cd.sections, cd.assignments));            
            setLoading(false);

        });
    };

    const moveItem = (id: string, direction: 'up' | 'down') => {
        tryMoveTopItem(id, direction) || tryMoveChildAssignment(id, direction);
    };    

    const tryMoveTopItem = (id: string, direction: 'up' | 'down') => {
        const index = items.findIndex(i => i.item.id === id);
        if (index !== -1) {
            const item = items[index];

            let newIndex = -1;
            if (direction === 'up') {
                newIndex = index === 0 ? items.length - 1 : index - 1;
            }
            else {
                newIndex = index === items.length - 1 ? 0  : index + 1;
            }

            const newItems = items.concat();
            newItems[index] = newItems[newIndex];
            newItems[newIndex] = item;

            setItems(newItems);
            return true;
        }

        return false;
    }

    const tryMoveChildAssignment = (id: string, direction: 'up' | 'down') => { 
        const parentIndex = items.findIndex(i => i.childAssignments?.find(a => a.id === id));
        if (parentIndex !== -1) {
            const newArray = items[parentIndex].childAssignments.concat();
            const assignmentIndex = newArray.findIndex(a => a.id === id);
            const a = newArray[assignmentIndex];

            let newIndex = -1;
            if (direction === 'up') {
                newIndex = assignmentIndex === 0 ? newArray.length - 1 : assignmentIndex - 1;
            }
            else {
                newIndex = assignmentIndex === newArray.length - 1 ? 0  : assignmentIndex + 1;
            }

            newArray[assignmentIndex] = newArray[newIndex];
            newArray[newIndex] = a;

            const newItems = items.concat();
            newItems[parentIndex].childAssignments = newArray;
            setItems(newItems);
            return true;
        }

        return false;
    }
        

    return (
        <Page 
            breadcrumbs={[{ text: 'Courses', backNav: true }, { text: `${!props.course?.id ? 'Add' : 'Edit'} Course` }]}
            title={props.course?.name}
            icon={ props.course?.avatar && <AppAvatar2 avatarDefinition={props.course.avatar} size='70px' fontSize="35px"/>}>         
            {!assignmentForEdit &&
                <Grid container spacing={2}>
                    <Grid xs={12} xl={8}>
                        <Card>                            
                            {!loading &&
                                <>
                                    {!!items?.length &&<>
                                        <CardHeader 
                                            title={<Typography variant="h6">Assignments</Typography>}
                                            action={!reordering && <CardActionButtons                                                 
                                                buttons={[
                                                    {icon: <AssignmentIcon />, text: 'Add Assignment', action: () => setAssignmentForEdit({ courseID: props.course.id, order: 0 })},
                                                    {icon: <SectionIcon />, text: 'Add Section', action: () => setSectionForEdit({ courseID: props.course.id, order: 0 })},
                                                    {icon: <ChangeOrderIcon />, text: 'Reorder', action: () => setReordering(true)},
                                                ]}/>
                                            } />                                            
                                        <CardContent>                                                 
                                            {/* <Box>
                                                <AppButton 
                                                    text='Add Assignment' icon={<AssignmentIcon />}                                                    
                                                    onClick={() => setAssignmentForEdit({ courseID: props.course.id, order: 0 })}
                                                />                                                        
                                                <AppButton 
                                                    text='Add Section' icon={<SectionIcon />}
                                                    onClick={() => setSectionForEdit({ courseID: props.course.id, order: 0 })}
                                                    sx={{ml: 1}}                                                
                                                />   
                                            </Box> */}
                                            <Box sx={{mb: 2, float: 'right'}}>  { reordering 
                                                && <span>
                                                    <AppButton type="primary" size="small"
                                                        icon={<Check />}                                                                                                                                         
                                                        onClick={() => {                                                                        
                                                            reorderCourseItems(props.course.id, items.map(i => {return { id: i.item.id, children: i.childAssignments?.map(ca => ca.id) || [] }}))
                                                                .subscribe(_ => {
                                                                    setReordering(false);
                                                                    load();
                                                                    globalStore.showToast('success', 'Saved')
                                                                });
                                                        }}
                                                    />
                                                    <AppButton type="neutral" size="small"
                                                        icon={<CloseOutlined />}                                                                                                                                    
                                                        onClick={() => { setReordering(false); load(); }}
                                                        sx={{ml: 1}}
                                                    />
                                                </span>
                                            } </Box>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableCell width={12} />
                                                    <TableCell />
                                                    <TableCell width={80}/>
                                                </TableHead>
                                                <TableBody>
                                                    {items.map(i => i.itemType === 'section' 
                                                        ? <SectionRow key={i.item.id}
                                                            courseSection={i.item}
                                                            assignments={i.childAssignments}
                                                            setForEdit={setSectionForEdit}
                                                            setAssignmentForEdit={setAssignmentForEdit} 
                                                            moveItem={moveItem} reordering={reordering}/>
                                                        : <AssignmentRow key={i.item.id} courseAssignment={i.item} setForEdit={setAssignmentForEdit} reordering={reordering} moveItem={(d) => moveItem(i.item.id, d)}/>
                                                        )

                                                    }                                                    
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </>}
                                    {!items?.length &&
                                        <div style={{ height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-80px' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography component="h5" variant="h5">
                                                    This course doesn't have any assignments yet
                                                </Typography>
                                                <Box sx={{ mt: 4 }}>
                                                    <Box sx={{ mt: 2 }}>
                                                        <AppButton 
                                                            text='Add Assignment' icon={<AssignmentIcon />}
                                                            size="large" 
                                                            onClick={() => setAssignmentForEdit({ courseID: props.course.id, order: 0 })}
                                                        />                                                        
                                                        <AppButton 
                                                            text='Add Section' icon={<SectionIcon />}
                                                            size="large" 
                                                            onClick={() => setSectionForEdit({ courseID: props.course.id, order: 0 })}
                                                        />                                                                                                                                                                                                                             
                                                    </Box>
                                                </Box>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {loading &&
                            <CardContent>
                                <LoadingTable />
                            </CardContent>
                            }
                        </Card>
                    </Grid>                    
                </Grid>
            }

            {!!assignmentForEdit &&
                <AssignmentDetail assignment={assignmentForEdit} 
                    onSaved={() => { globalStore.showToast('success', 'Assignment saved'); setAssignmentForEdit(undefined); load(); }} 
                    onCanceled={() => setAssignmentForEdit(undefined)} 
                    onError={() => globalStore.showToast('error', 'There was a problem saving assignment')} />
            }
            {!!sectionForEdit &&
                <CourseSectionModal section={sectionForEdit} onSaved={() => { globalStore.showToast('success', 'Section saved'); setSectionForEdit(undefined); load(); }} onCancel={() => setSectionForEdit(undefined)} onError={() => globalStore.showToast('error', 'Error saving section')} />
            }            
        </Page>
    );
}

function AssignmentRow(props: { 
    courseAssignment: CourseAssignment, 
    shift?: boolean,
    setForEdit: (courseAssignment: CourseAssignment) => void,
    reordering: boolean,
    moveItem: (direction: 'up' | 'down') => void,
    }) {

    return (       
        <TableRow className='hover-grow' onClick={() => props.setForEdit(props.courseAssignment)}>            
            <TableCell></TableCell>
            <TableCell sx={{ paddingLeft: props.shift ? '48px' : undefined }}>
                <span style={{display: 'flex', alignItems: 'center'}}>
                    <AssignmentIcon sx={{fontSize: '18px'}}/> <span style={{marginLeft: '12px'}}>{props.courseAssignment.title}</span>
                </span>
            </TableCell>
            <TableCell sx={{padding: 0}} onClick={e => e.stopPropagation()}>
                    {props.reordering &&
                        <MoveUpDownButtons moveUpFunc={() => props.moveItem('up')} moveDownFunc={() => props.moveItem('down')} /> }
                </TableCell>  
        </TableRow>
    );
}

function SectionRow(props: { 
    courseSection: CourseSection, 
    assignments: CourseAssignment[], 
    setForEdit: (courseSection: CourseSection) => void, 
    setAssignmentForEdit: (assignment: CourseAssignment) => void,
    moveItem: (id: string, direction: 'up' | 'down') => void,
    reordering: boolean,
 }) {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <>
            <TableRow className='hover-grow' onClick={() => props.setForEdit(props.courseSection)}>
                <TableCell sx={{padding:0}}>
                    {!!props.assignments?.length &&
                    <IconButton size="small" onClick={e => { e.stopPropagation(); setCollapsed(!collapsed);}}>
                        { collapsed && <ExpandIcon /> }
                        {!collapsed && <CollapseIcon /> }                        
                    </IconButton>
                    }
                </TableCell>
                <TableCell> 
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <SectionIcon sx={{fontSize: '18px'}}/> <span style={{marginLeft: '12px'}}>{props.courseSection.name}</span>
                    </span>
                </TableCell>
                <TableCell sx={{padding: 0}} onClick={e => e.stopPropagation()}>
                    {props.reordering ?
                        <MoveUpDownButtons moveUpFunc={() => props.moveItem(props.courseSection.id, 'up')} moveDownFunc={() => props.moveItem(props.courseSection.id, 'down')} />
                        : <IconButton size="small" onClick={() => props.setAssignmentForEdit({courseID: props.courseSection.courseID, courseSectionID: props.courseSection.id, order: 0})}>
                            <AddIcon />              
                        </IconButton>
                     }
                </TableCell>                
            </TableRow>
            { !collapsed &&
                props.assignments?.map(a => <AssignmentRow key={a.id} courseAssignment={a} shift={true} setForEdit={props.setAssignmentForEdit} reordering={props.reordering} moveItem={d => props.moveItem(a.id, d)}/>)
            }
        </>
    );
}

const MoveUpDownButtons = (props: {moveUpFunc: () => void, moveDownFunc: () => void}) =>
<Stack direction='row'>
    <IconButton size="small" onClick={props.moveUpFunc}>
        <ArrowUpwardOutlined />                  
    </IconButton>
    <IconButton size="small" onClick={props.moveDownFunc}>
        <ArrowDownwardOutlined />                  
    </IconButton>    
</Stack>

type SectionOrAssignment = {
    itemType: 'section' | 'assignment',
    item: CourseSection | CourseAssignment,    
    childAssignments?: CourseAssignment[],    
}

const buildTree = (sections: CourseSection[], assignments: CourseAssignment[]) =>
    (sections || []).map(s => {
        const i = {
            itemType: 'section',
            item: s,
            childAssignments: assignments?.filter(a => a.courseSectionID === s.id).sort((a, b) => a.order - b.order)
        } as SectionOrAssignment;        
        return i;
    }).concat(
        (assignments?.filter(a => !a.courseSectionID) || [])
            .map(a => {
                return {
                    itemType: 'assignment',
                    item: a
                } as SectionOrAssignment
            })
    ).sort(courseOrSectionSort);

const courseOrSectionSort = (a: SectionOrAssignment, b: SectionOrAssignment) => a.item.order - b.item.order;