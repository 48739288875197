import { displayGrade, PersonCourse } from "../../../../../models/data/person-course/person-course.model";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, CardHeader, Typography, CardContent, Box, Divider } from "@mui/material";
import { Activity } from "../../../../../models/data/activity/activity.model";
import { PersonCourseAssignment } from "../../../../../models/data/person-course/person-course-assignment.model";
import { ActivityTimeline } from "../../../activity/shared/ActivityTimeline";
import { AppAvatar } from "../../../../shared/AppAvatar";
import { CourseSection } from "../../../../../models/data/course/course-section.model";
import { Person } from "../../../../../models/data/person.model";
import { useOutletContext } from "react-router-dom";
import { StudentCourseContextStore } from "./student-course-context.store";
import { useState } from "react";
import { filter, take } from "rxjs";
import { Page } from "../../../../shared/Page";
import { LoaderPage } from "../../../../shared/LoaderPage";
import { StudentCourseAssignmentCard } from "./assignments/StudentCourseAssignmentsCard";
import { StudentStore } from "../student.store";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { ActivityTableCard } from "../../../activity/shared/ActivityTableCard";
import { AppAvatar2 } from "../../../../shared/AppAvatar2";

export function StudentCourseDetail(): JSX.Element {      
    const store = useOutletContext<StudentCourseContextStore>();    

    const [personCourse, setPersonCourse] = useState<PersonCourse>();
    const [person, setPerson] = useState<Person>();
    const [assignments, setAssignments] = useState<PersonCourseAssignment[]>();
    const [sections, setSections] = useState<CourseSection[]>();
    const [activity, setActivity] = useState<Activity[]>();
    const [loaded, setLoaded] = useState<boolean>(false);

    useSubscription(store?.personCourse$, pc => setPersonCourse(pc), [store]);
    useSubscription(store?.person$, p => setPerson(p), [store]);
    useSubscription(store?.personCourseAssignments$, pca => setAssignments(pca), [store]);
    useSubscription(store?.courseSections$, s => setSections(s), [store]);
    useSubscription(store?.activity$, a => setActivity(a), [store]);
    useSubscription(store?.loaded$.pipe(filter(l => l), take(1)), () => setLoaded(true), [store]);    

    return (<>{loaded && 
        <Page breadcrumbs={loaded ? [{text: 'Students'}, {text: `${person?.firstName} ${person?.lastName}`, url: '/students/' + person.id}, {text: personCourse?.course_Name}] : []}
            title={personCourse?.course_Name} 
            icon={ personCourse &&
                <AppAvatar2 avatarDefinition={personCourse.avatar} size='70px' fontSize="35px"/>
                }>                                               
            <Grid container spacing={2}>            
                <Grid xs={12} md={6} xl={4}>
                    <Card>
                        <CardHeader
                            title={<Typography component="h6" variant="h6">Overview</Typography>}
                        />                        
                        <CardContent>
                            <Box sx={{display:'flex', alignItems: 'center'}}>                                                    
                                <Box sx={{display: 'inline-block', ml: 4}}>                                
                                    <div>
                                        <Typography component="label" variant="subtitle1">{personCourse.academicTerm_Name}</Typography>                        
                                    </div>
                                    { personCourse.statistics &&
                                        <>
                                            <div>
                                                <Typography component="label" variant="subtitle1">Grade: {displayGrade(personCourse)}</Typography>                        
                                            </div>                                
                                            <div>
                                                <Typography component="label" variant="subtitle1">{personCourse.statistics.sumActivityHours} hours completed</Typography>                        
                                            </div>                        
                                        </>
                                    }  
                                </Box>
                            </Box>                                                
                        </CardContent>
                    </Card>     
                    {!!assignments?.length && <Box sx={{mt: 2}}>
                        <ActivityTableCard 
                            title='Completed Instruction'
                            activity={activity}                            
                            personCourseId={personCourse.id}           
                            personId={person.id}        
                        />                         
                    </Box>}
                </Grid>   
                {!assignments?.length && <Grid xs={12} md={6} xl={8}>
                        <ActivityTableCard 
                                title='Completed Instruction'
                                activity={activity}                            
                                personCourseId={personCourse.id}           
                                personId={person.id}        
                            />             
                    </Grid>                        
                }
                {!!assignments?.length && 
                    <Grid xs={12} md={6} xl={8}>
                        <StudentCourseAssignmentCard />                    
                    </Grid>
                }                
            </Grid>
        </Page>
    }
    {!loaded && <LoaderPage />}</>);
}
