import { Box  } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import globalStateStore from "../../../app/global.store";
import { GlobalFunctions } from "../../../models/app/global-functions";
import { Menu } from "../../../models/app/menu-item";
import { ContextMenu } from "./navigation/mobile/context-menu";
import { MobileNav } from "./navigation/mobile/mobile-nav";
import { Provider } from "react-redux";

export const MobileContextMenuContext = createContext<Menu>(undefined);

export function MobileLayout() {
    const [contextMenu, setContextMenu] = useState<Menu | undefined>();

    useEffect(() => {
        globalStateStore.contextMenu$.subscribe(cm => setContextMenu(cm));
    }, []);

    return (
        <>        
        <Box sx={{ display: 'flex' }}>             
            <Box
                component="main"
                sx={{            
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',                
                    m: 0, 
                    px: 0,
                    pb: '80px',
                }}
                >               
                <Box sx={{ m: 2, pt: 0, px: 0, pb: (contextMenu ? '50px' : 0) }}>       
                    <MobileContextMenuContext.Provider value={contextMenu}>
                        <Outlet />   
                    </MobileContextMenuContext.Provider>                                 
                </Box>            
            </Box>      
        </Box>
        <MobileNav contextMenu={contextMenu}/>
        {/* {contextMenu && 
            <ContextMenu contextMenu={contextMenu} />} */}
        </>
    );
}