import { useState, useEffect } from "react";
import { useParams, useLocation, Outlet, useOutletContext } from "react-router-dom";
import { Person } from "../../../../models/data/person.model";
import { initStudentStoreFromID, initStudentStoreFromStudent, StudentStore } from "./student.store";

export function Student(): JSX.Element {
    const { studentId } = useParams();
    const location = useLocation();            
    const [store, setStore] = useState<StudentStore>(undefined);

    useEffect(() => {                
        const locationState = location.state as any;  
        const store = locationState 
        ? initStudentStoreFromStudent(locationState.student as Person)
        : initStudentStoreFromID(studentId);  
        
        setStore(store);                    
    }, []);

    return (
        <Outlet context={store}/>
    );
}