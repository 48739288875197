import { Close } from "@mui/icons-material";
import { Snackbar, Alert, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { colors } from "../../../app/colors";
import globalStore from "../../../app/global.store";

export function Toast() {
    const [open, setOpen] = useState(false);
    const [config, setConfig] = useState<ToastConfig>(undefined);

    useEffect(() => {
        const sub = globalStore.toastSubject$.subscribe(c => {
            setConfig(c);
            setOpen(true);
        });
    });

    return (
        <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={() => setOpen(false)}                
                autoHideDuration={3000}  
                sx={{width: '300px'}}                                                   
            >
            {config && 
                <Alert severity={config.type} sx={{ width: '100%'}}>
                    {config.message}                
                </Alert>
            }
        </Snackbar>
    );
}

export type ToastConfig = {
    message: string,
    type: ToastType,
};

export type ToastType = 'success' | 'error' | 'info';