import { useContext, useState } from "react"
import { CacheContext } from "../../App"
import { FormSelect, SelectOption, makeSelectOption } from "./form/FormSelect";
import { Subject } from "../../models/data/settings/subject";
import { useSubscription } from "../../hooks/useSubscription";

export function SubjectSelect(props: {fieldName: string, label: string, required?: boolean}) {
    const cache = useContext(CacheContext);
    const [subjects, setSubjects] = useState<Subject[]>(undefined);
    useSubscription(cache.subjects$, s => setSubjects(s), [cache]);

    return subjects && <FormSelect                         
                        label={props.label}
                        fieldName={props.fieldName}
                        fullWidth={false}   
                        required={!!props.required}
                        options={makeOptions(subjects || [])}                         
                    />
}

const makeOptions = (subjects: Subject[], exclude?: string) => {
    const os: SelectOption[] = [undefined];
    const recurseSubject = (s: Subject, depth: number) => {
        const t = <>
            {new Array(depth)?.fill(0).map(_ => <span key={s.id}>&nbsp;&nbsp;&nbsp;</span>)}
            {s.name}
        </>;
        os.push(makeSelectOption(s.id, s.name, t));
        s.subItems?.filter(s => s.id !== exclude).forEach(sub => recurseSubject(sub, depth + 1));
    }

    subjects?.filter(s => s.id !== exclude).forEach(s => recurseSubject(s, 0));

    return os;
};