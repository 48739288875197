import { Add as AddIcon, LibraryBooksOutlined as LibraryIcon } from "@mui/icons-material";
import { Avatar, Box, Button, Card, CardContent, CardHeader, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../app/routes";
import { Course } from "../../../models/data/course/course.model";
import { getCoursesForOrganization, getSharedCourses } from "../../../services/course.service";
import { Page } from "../../shared/Page";
import Grid from '@mui/material/Unstable_Grid2';
import { AppButton } from "../../shared/buttons/primary";
import { AppAvatar } from "../../shared/AppAvatar";
import { SharedCourse } from "../../../models/data/course/shared-course.model";
import { useSubscription } from "../../../hooks/useSubscription";
import { SharedCoursesList } from "./SharedCoursesList";
import { AppAvatar2 } from "../../shared/AppAvatar2";

export function CourseList(props: {}): JSX.Element {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [courses, setCourses] = useState<Course[]>();
    const navigate = useNavigate();
    const [sharedCourses, setSharedCourses] = useState<SharedCourse[]>();    
    const [reload, setReload] = useState({});
    
    useEffect(() => {
        getCoursesForOrganization()
            .subscribe(c => {
                setCourses(c);
                setLoaded(true);
            });

    }, [reload]);    

    useSubscription(getSharedCourses(), sc => setSharedCourses(sc), [reload]);
    
    return (
        <Page title="Courses">            
            {!!sharedCourses?.length && 
                <SharedCoursesList sharedCourses={sharedCourses} onChange={() => setReload({})}/>
            }
            <Grid container spacing={2}>
                <Grid xs={12} md={8} xl={4}>
                    <Card>
                        <CardHeader                         
                        title={<Typography component="h6" variant="h6">My courses</Typography>}
                        action={ !courses?.length ? undefined : 
                            <>                            
                            <AppButton size="medium" onClick={() => navigate(Routes.COURSES_ADD)} icon={<AddIcon />} text='Add New' /> 
                            {/* <AppButton size="medium" style={{marginLeft: '8px'}} icon={<LibraryIcon/>} text='Search Library' /> */}
                            </>
                        }
                        />
                        <CardContent>
                            <>
                            {(!loaded || courses?.length > 0) &&                            
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '30px' }}></TableCell>
                                        <TableCell sx={{width: '300px'}}></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        (courses?.length || 0) > 0 &&
                                        courses.map(c => {                                            
                                            return (
                                                <TableRow key={c.id} className='tr-hover-grow' onClick={() => navigate(c.id)}>
                                                    <TableCell>
                                                        <AppAvatar2
                                                            avatarDefinition={c.avatar}
                                                        />                                                        
                                                    </TableCell>
                                                    <TableCell>{c.name}</TableCell>
                                                    <TableCell>{c.academicLevelName}</TableCell>                                                    
                                                </TableRow>
                                            );
                                        })
                                    }
                                    {!loaded &&
                                        [0, 1, 2].map((v) =>
                                            <TableRow key={v}>
                                                <TableCell colSpan={3}>
                                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                </TableCell>
                                            </TableRow>)
                                    }
                                </TableBody>
                            </Table>
                            }
                            { loaded && !courses?.length &&
                                <div style={{height: 400, width: '100%', display:'flex', justifyContent:'center', alignItems: 'center', marginTop: '-80px'}}>      
                                    <div style={{textAlign: 'center'}}>
                                        <Typography component="h5" variant="h5">
                                            You don't have any courses yet
                                        </Typography>
                                        <Box sx={{mt: 4}}>                                            
                                            <Box sx={{mt:2}}>
                                                <AppButton size="large" onClick={() => navigate(Routes.COURSES_ADD)} icon={<AddIcon />} text='Add New' /> 
                                                {/* <AppButton size="large" style={{marginLeft: '8px'}} icon={<LibraryIcon />} text='Search Library' /> */}
                                            </Box>                                            
                                        </Box>
                                    </div>                              
                                </div>
                            }
                            </>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}