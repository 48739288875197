import { Typography, Stack, Box } from "@mui/material";
import { useEffect, useState} from "react";
import globalStore from "../../../../app/global.store";
import { Form } from "../../../shared/form/Form";
import { FormActions } from "../../../shared/form/FormActions";
import { FormDatePicker } from "../../../shared/form/FormDatePicker";
import { FormTextField } from "../../../shared/form/FormTextField";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { StudentCourseAssignment } from "./StudentCourseAssignment";
import { useSubscription } from "../../../../hooks/useSubscription";
import { FormBool } from "../../../shared/form/FormBool";
import { requiredString, requiredDate, range } from "../../../shared/form/validators";
import { dayOfWeekName, localDateString, } from "../../../../util/dates";
import { ScheduledActivityType } from "../../../../models/data/schedule/scheduled-activity.model";
import { saveScheduledActivity, saveScheduledActivityRecurring } from "../../../../services/schedule.service";
import { RecurrencePeriod } from "../../../../models/data/schedule/scheduled-activity-recurring.model";
import { ScheduleOrRecurrence } from "../../../../models/data/schedule/schedule-or-recurrence.model";

export function SchdeuleAddForm(props: {    
    showPersonAndCourse?: boolean,
    personID?: string,
    date?: string,
    onSaved: () => void,    
}): JSX.Element {    
    const [formData, setFormData] = useState<FormData>();    
    const [isRecurring, setIsRecurring] = useState(false);
    useSubscription(formData?.fields.isRecurring.value$, v => setIsRecurring(!!v), [formData]);
    useEffect(() => setFormData(makeFormData(props.personID, props.date)), []);
    
    return (formData &&
        <DrawerFormContainer>
            <Form formData={formData}>            
                <DrawerFormHeader>
                    <Typography variant="h6">{isRecurring && 'Recurring '}Schedule</Typography>
                </DrawerFormHeader>            
                <DrawerFormBody>              
                    <Stack spacing={1.5} sx={{minWidth: '275px'}}>
                        <StudentCourseAssignment showPersonAndCourse={props.showPersonAndCourse}/>                        
                        <FormDatePicker fieldName="startDate" label={isRecurring ? 'Start Date' : 'Date'} required={true}/>                                                    
                        {isRecurring && <>                            
                            <FormDatePicker fieldName="endDate" label='End Date'/>                                                        
                        </>}
                        <div>
                            <FormTextField fieldName="hours" 
                                label='Hours' required={true}                                 
                                textFieldProps={{type: 'number', sx: {width: '120px', mr: 2}, fullWidth: false}} />
                            <FormTextField fieldName="minutes" 
                                label='Minutes' required={true}
                                textFieldProps={{type: 'number', sx: {width: '120px'}, fullWidth: false}} />                            
                        </div>      
                        <Box>
                            <FormTextField fieldName="description" label='Details' maxLength={200} 
                                textFieldProps={{multiline: true, minRows: 3}}
                            />                            
                        </Box>   
                        <Box>
                            <FormBool label="Is Recurring" fieldName="isRecurring" type='switch' />
                        </Box>
                        {isRecurring && 
                            <Stack spacing={0}>
                                {new Array(7).fill(0).map((_, dow) =>                             
                                    <FormBool label={dayOfWeekName(dow)} fieldName={'dow_' + dow} type='checkbox' key={dow}/>                            
                                )}
                            </Stack>
                        }                                                                      
                    </Stack>                             
                </DrawerFormBody>    
                <DrawerFormFooter>
                    <FormActions     
                        saveAction={() => save()} 
                        cancelAction={() => globalStore.popDrawerFormContent()}/>                    
                </DrawerFormFooter>
            </Form>
        </DrawerFormContainer>);    
        
        function save() {    
            const formValue = formData.getValue();
            console.log('formVAlue', formValue);

            const toSave: ScheduleOrRecurrence = { 
                type: ScheduledActivityType.CourseActivity,                
                startDate: formValue.startDate,
                endDate: formValue.startDate,
                durationMinutes: ((+formValue.hours * 60) + (+formValue.minutes)),                
                personID: formValue.personID,
                personCourseID: formValue.personCourseID,
                personCourseAssignmentID: formValue.personCourseAssignmentID,    
                description: formValue.description,            
            };
            
            if (formValue.isRecurring) {
                saveScheduledActivityRecurring({
                    ...toSave, 
                    endDate: formValue.endDate || undefined,
                    recurrencePeriod: RecurrencePeriod.Week, 
                    recurrenceDays: 
                        Object.keys(formValue)
                            .filter(k => k.startsWith('dow_') && !!formValue[k])
                            .map(k => +k.replace('dow_', '')) || []  
                }).subscribe({
                    next: _ => {
                        props.onSaved && props.onSaved();
                        globalStore.showToast('success', 'Success');                    
                        globalStore.drawerFormContentStackPop$.next();
                    },
                    error: e => globalStore.showToast('error', 'Error saving'),
                });
            }
            else {                                
                saveScheduledActivity(toSave).subscribe({
                    next: _ => {
                        props.onSaved && props.onSaved();
                        globalStore.showToast('success', 'Success');                    
                        globalStore.drawerFormContentStackPop$.next();
                    },
                    error: e => globalStore.showToast('error', 'Error saving'),
                });
            }
        }
}

function makeFormData(personID?: string, date?: string): FormData {          
    return new FormData({
        personID: new FormFieldData(personID, [requiredString]),
        personCourseID: new FormFieldData(undefined, [requiredString]),
        personCourseAssignmentID: new FormFieldData(undefined),        
        startDate: new FormFieldData(date || localDateString(new Date()), [requiredDate]),
        endDate: new FormFieldData(undefined),
        hours: new FormFieldData(0, [range(0, 23)]),
        minutes: new FormFieldData(0, [range(0, 59)]),            
        description: new FormFieldData(''),    
        isRecurring: new FormFieldData(false),
        dow_0: new FormFieldData(false),
        dow_1: new FormFieldData(false),
        dow_2: new FormFieldData(false),
        dow_3: new FormFieldData(false),
        dow_4: new FormFieldData(false),
        dow_5: new FormFieldData(false),
        dow_6: new FormFieldData(false),
    });
}