import { AvatarDefinition } from "../shared/avatar-definition";

export interface Activity {
    id?: string;
    personID?: string;
    personCourseID?: string;
    personCourseAssignmentID?: string;
    startDate?: string;
    endDate?: string;
    durationMinutes?: number;
    description?: string;    
    status?: ActivityStatus;
    atPrimaryLocation?: boolean;
    personFirstName?: string;
    personMiddleName?: string;
    personLastName?: string;
    courseTitle?: string;
    assignmentTitle?: string;
    courseAvatar?: AvatarDefinition;
    personAvatar?: AvatarDefinition;
    scheduledActivityID?: string;
    scheduledActivityRecurringID?: string;    
}

export enum ActivityStatus {
    Started = 1,
    Completed = 2     
}

export function activitySummary(activity: Activity): { displayStart: string, displayHours: string } {
    return {
        displayStart: new Date(activity.startDate).toLocaleDateString(),
        displayHours: (activity.durationMinutes / 60).toFixed(1)
    };
}

export type HoursMinutes = {
    hours: number, minutes: number,
};

export interface ActivitySearchParams {
    academicTermId?: string;
    startDate?: Date | string;
    endDate?: Date | string;    
    students?: string[],
    page?: number;
    pageSize?: number;
}

export function getHoursMinutesFromDuration(durationMinutes: number): HoursMinutes {
    return {
        hours: durationMinutes ? Math.floor(durationMinutes / 60) : 0,
        minutes: durationMinutes ? durationMinutes % 60 : 0,
    };
}

export interface GradingModel {
    pointsEarned?: number;
    completedDate?: string;
}

export interface ActivitySaveModel {
    activity?: Activity;
    grading?: GradingModel;
}