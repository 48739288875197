import { Stack, useMediaQuery } from "@mui/material";
import { DESKTOP_BREAKPOINT } from "../../app/constants";
import { PopupButton } from "./buttons/PopupMenu";
import { MoreVert } from "@mui/icons-material";
import { AppButton } from "./buttons/primary";

export function CardActionButtons(props: {buttons: CardActionButton[]}) {
    const isMobile = !useMediaQuery(DESKTOP_BREAKPOINT);

    return isMobile 
    ? <PopupButton 
        icon={<MoreVert />} 
        items={props.buttons?.map(b => {
            return {icon: b.icon, text: b.text, action: () => b.action()}
        })}/> 
    : <Stack spacing={1} direction='row'>
        {props.buttons?.map((b, i) => 
            <AppButton type='primary' 
                onClick={() => b.action()} 
                text={b.text}
                icon={b.icon} key={b.text}/>
        )}
    </Stack>
    ; 
}

export interface CardActionButton {
    icon?: JSX.Element,
    text?: string,
    action: () => void,
}