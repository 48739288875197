import { School as SchoolIcon, Terrain, Water } from "@mui/icons-material";

export function Brand(props: {style?: any, iconColor?: string, fontSize?: number}): JSX.Element {
    const fontSize = props.fontSize || 26;
    return (
        <div style={{
            fontFamily: 'Monaco',
            fontSize: fontSize, 
            fontWeight: '900',            
            backgroundColor: 'transparent',
            // height: '70px',
            // width: '70px',
            textAlign: 'center',
            justifyContent: 'center', 
            alignItems: 'center',
            display: 'flex',
            borderRadius: '800px',
            ...props?.style
            }}>

        <span style={{color: '#47B5FF', display: 'flex', alignItems: 'center', fontWeight: '900'}}>
            {/* [<SchoolIcon sx={{color: (props?.iconColor || 'white'), fontSize: (fontSize * 1.1)}}/>]             */}

            <span style={{marginRight: -7}}>[</span><Water sx={{color: (props?.iconColor || 'white'), fontSize: (fontSize * 1.2)}}/><span style={{marginLeft: -7}}>]</span>
            
            
                {/* <Terrain sx={{color: (props?.iconColor || 'white'), fontSize: (fontSize * 1), marginTop: -3}}/>
                <Water sx={{ fontSize: (fontSize * .8), marginLeft: -6, mt: 4}}/>
                <Terrain sx={{color: (props?.iconColor || 'white'), fontSize: (fontSize * 1.4), transform: 'scaleX(-1)'}}/> */}
            

            {/* <span style={{color: (props?.iconColor || 'white'), fontSize: (fontSize * 1), marginLeft: 0}}>beluga</span> */}
            {/* hub */}
            {/* <span style={{color: '#888', fontSize: (fontSize * 1), marginLeft: 0}}>hub</span> */}

            {/* &<span style={{color: (props?.iconColor || 'white'), fontSize: (fontSize * 1), marginLeft: 0}}>hub</span> */}
        </span>
    </div>
    );
}