import { Alert, Stack, Typography } from "@mui/material";
import { Course } from "../../../../../models/data/course/course.model";
import { Form } from "../../../../shared/form/Form";
import { FormData, FormFieldData } from "../../../../shared/form/form-data";
import { requiredString } from "../../../../shared/form/validators";
import { DrawerFormContainer } from "../../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormHeader } from "../../../layout/form-drawer/DrawerFormHeader";
import globalStore from "../../../../../app/global.store";
import { FormActions } from "../../../../shared/form/FormActions";
import { FormSelect } from "../../../../shared/form/FormSelect";
import { FormTextField } from "../../../../shared/form/FormTextField";
import { DrawerFormBody } from "../../../layout/form-drawer/DrawerFormBody";
import { DrawerFormFooter } from "../../../layout/form-drawer/DrawerFormFooter";
import { shareCourse } from "../../../../../services/course.service";
import { useState } from "react";
import { Shortcut } from "@mui/icons-material";

export function ShareCourseForm(props: {courseId: string}) {
    const [validationError, setValidationError] = useState<string>();    
    const formData = new FormData({
        targetAccount: new FormFieldData('', [requiredString]),        
    });

    const save = () => {
        setValidationError(undefined);

        shareCourse(props.courseId, formData.getValue().targetAccount).subscribe(result => {
            switch(result) {
                case 'success':
                    globalStore.showToast('success', 'Course shared successfully');
                    globalStore.popDrawerFormContent();
                    break;
                case 'user-not-found':
                    setValidationError('User not found');                    
                    break;
                default:
                    globalStore.showToast('error', 'Unexpected result');
                    break;
            }
        })
    }
    
    return <DrawerFormContainer>
    <Form formData={formData}>            
        <DrawerFormHeader>
            <Typography variant="h6">Share Course</Typography>
        </DrawerFormHeader>            
        <DrawerFormBody>              
            <Stack spacing={2} sx={{width: '275px'}}>
                {validationError &&
                <Alert variant="outlined" severity="error" style={{width: '100%', marginTop:'15px'}}>
                    {validationError}
                </ Alert>
                }
                <FormTextField label="User Account" required={true} fieldName="targetAccount" />                
                <Typography variant="body2">Enter the account name of the user you would like to invite to share a copy of this course</Typography>
            </Stack>                             
        </DrawerFormBody>    
        <DrawerFormFooter>
            <FormActions     
                saveAction={() => save()}                         
                saveIcon={<Shortcut />}
                cancelAction={() => globalStore.popDrawerFormContent()}/>                    
        </DrawerFormFooter>
    </Form>
</DrawerFormContainer>;
}