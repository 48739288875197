import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { SideMenu } from "./navigation/SideMenu/SideMenu";
import { GlobalFunctions } from "../../../models/app/global-functions";
import { Menu } from "../../../models/app/menu-item";
import { FormDrawer } from "./form-drawer/FormDrawer";
import { ErrorBoundary } from "../../shared/ErrorBoundary";


export function Layout(): JSX.Element {     
    return (
        <Box sx={{ display: 'flex' }}> 
            <SideMenu />
            <Box
                component="main"
                sx={{            
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',                
                    m: 0, 
                    px: 0
                }}
                >                   
                <Box sx={{ m: 2, p: 0 }}>                                        
                    <Outlet />                   
                </Box>            
            </Box>                
        </Box>        
    );
}