import { addDays, addWeeks, isSameDay } from "date-fns";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, CardContent, CardHeader, Chip, Stack, Typography, useMediaQuery } from "@mui/material";
import { getAvatarColorForText } from "../../../../models/data/person.model";
import { AppAvatar } from "../../../shared/AppAvatar";
import { firstForGroup, sumReducer } from "../../../../util/array";
import { minutesToHours } from "../../../../util/dates";
import globalStore from "../../../../app/global.store";
import { DayDetailDrawer } from "../ScheduleDetail/DayDetailDrawer";
import { useContext, useState } from "react";
import { ScheduleMonthDataContext } from "./ScheduleMonth";
import { ScheduleOrRecurrence, ScheduleViewDay, ScheduleViewItem } from "../../../../models/data/schedule/schedule-or-recurrence.model";
import { useSubscription } from "../../../../hooks/useSubscription";
import { ScheduleStore } from "../schedule.store";
import { AppAvatar2 } from "../../../shared/AppAvatar2";
import { AvatarDefinition } from "../../../../models/data/shared/avatar-definition";

export function ScheduleMonthGrid(props: {startDate: Date, endDate: Date, }) {        
    if ( props.startDate.getTime() > props.endDate.getTime() || 
        !(props.startDate.getDay() === 0 && props.endDate.getDay() === 6)) {            
            throw new Error(`Invalid date range for calendar: ${props.startDate} (${dayOfWeekName(props.startDate)}) - ${props.endDate} (${dayOfWeekName(props.endDate)})`);    
        }         
    const isCondensed = !useMediaQuery("(min-width:1000px)");

    const datesGrid = getSundays(props.startDate, props.endDate).map(getWeekDates);

    const store = useContext(ScheduleMonthDataContext);
    const [itemsByDay, setItemsByDay] = useState<ScheduleViewDay[]>();
    
    useSubscription(store?.scheduleViewItemsForEachDate$(datesGrid.flat()), i => setItemsByDay(i), [store, props.startDate, props.endDate]);

    return !!itemsByDay && <Grid container spacing={1}>{
        datesGrid.map(w => ScheduleWeek(w, itemsByDay, isCondensed, store)) }
    </Grid>;
}

const ScheduleWeek = (week: Date[], itemsByDay: ScheduleViewDay[], isCondensed: boolean, store: ScheduleStore) => 
    <Grid key={`week_${week[0].toISOString()}`} container columnSpacing={.5} sx={{minHeight: '140px', width: '100%', padding: 0, marginBottom: .25}}>
        { week.map(d => ScheduleDay(d, itemsByDay.filter(i => isSameDay(i.date, d)).flatMap(i => i.items), isCondensed, store)) }
    </Grid>;

const ScheduleDay = (date: Date, items: ScheduleViewItem[], isCondensed: boolean, store: ScheduleStore) => {         
    return (
        <Grid key={date.toISOString()} xs alignItems={'center'} className='pointer' 
            onClick={() => 
                globalStore.pushDrawerFormContent(<DayDetailDrawer date={date} scheduleStore={store} />)
            }>        
            <Card sx={{height: '100%'}}>
                <CardHeader title={
                    <Typography variant="h6" fontSize={'smaller'}>{date.getDate()}</Typography>
                } sx={{padding: '8px'}} />
                <CardContent sx={{paddingY: 0, paddingX: 1}}>
                    <Stack spacing={.5} alignItems={isCondensed ? 'center' : undefined}>
                        {calendarItems(items)?.sort((a, b) => a.personFirstName.localeCompare(b.personFirstName)).map(ci =>                            
                            <Stack key={ci.personID} direction={'row'} alignItems='center' spacing={.5}>
                                {isCondensed 
                                    ? Avatar(ci, () => {})                                    
                                    : ItemChip(ci, () => {})
                                }
                            </Stack>)
                        }
                    </Stack>
                </CardContent>
            </Card>        
        </Grid>) 
}

const getSundays = (start: Date, end: Date) => Array(6).fill(1).map((_, i) => addWeeks(start, i)).filter(d => d.getTime() < end.getTime());
const getWeekDates = (sunday: Date) => Array(7).fill(1).map((_, i) => !i ? sunday : addDays(sunday, i));

const dayOfWeekName = (d: Date) => d.toLocaleDateString(undefined, {weekday: 'long'});

const Avatar = (ci: CalendarItem, onClick: () => void) => {
    return AppAvatar2({
        avatarDefinition: ci.personAvatar,
        size: '24px',
        fontSize: '12px',        
    }); 
}

const ItemChip = (ci: CalendarItem, onClick: () => void) => {    
    return (        
        <Chip                            
            label={<>{ci.totalHours} hrs</>}
            variant={'filled'}
            avatar={<AppAvatar2 avatarDefinition={ci.personAvatar} size="24px" fontSize="12px" />}            
            sx={{mr: .5, mb: .5, fontSize: '.8em', cursor: 'pointer'}}                            
        />        
    );
}

type CalendarItem = { 
    personID: string,
    personFirstName: string,
    personLastName: string,
    scheduledActivity: ScheduleViewItem[],
    totalHours: string,
    personAvatar: AvatarDefinition,
}

const calendarItems = (viewItems: ScheduleViewItem[]): CalendarItem[] => 
    viewItems                
        .filter(firstForGroup('personID'))
        .map(g => {
            const personItems = viewItems.filter(sa => sa.personID === g.personID);            
            return {
                personID: g.personID,
                personFirstName: g.personFirstName,
                personLastName: g.personLastName,
                personAvatar: g.personAvatar,
                scheduledActivity: personItems,
                totalHours: minutesToHours(
                    personItems.map(sa => (sa.completedActivityID ? sa.completedDurationMinutes : sa.durationMinutes) || 0).reduce(sumReducer, 0)
                    , 1),
            }
        });
