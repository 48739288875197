import { Page } from "../shared/Page";
import Grid from "@mui/material/Unstable_Grid2";
import { ScheduleCard } from "./schedule/shared/ScheduleCard";
import globalStore from "../../app/global.store";

export function Home(): JSX.Element {
    return (
        <Page title={`Welcome ${globalStore.user$.value?.firstName}`}>
            <Grid container spacing={2}>            
                <Grid xs={12} md={6} lg={4}>
                    <ScheduleCard />
                </Grid>      
            </Grid>
        </Page>
    );
}