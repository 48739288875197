import { LightMode as LightModeIcon, DarkMode as DarkModeIcon, Logout } from "@mui/icons-material";
import { IconButton, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import globalStateStore from "../../../../../app/global.store";
import { GlobalFunctions } from "../../../../../models/app/global-functions";
import { Menu, MenuItem } from "../../../../../models/app/menu-item";
import './SubMenu.scss'

export function SubMenu(props: {
    menu: Menu,     
    showSignOut?: boolean, 
    showToggleTheme?: boolean}): JSX.Element {

    const navigate = useNavigate();    
    const [active, setActive] = useState<MenuItem | undefined>();
    const buttonStyle = {justifyContent: 'left', borderRadius: '0px', marginTop: '3px', marginBottom: '3px'};
    const [themeName, setThemeName] = useState<'light' | 'dark'>();

    useEffect(() => {
        setActive(props.menu.items?.find(i => i.active) || props.menu.items[0]);    
    }, [props.menu]);

    useEffect(() => {
        globalStateStore.theme$.subscribe(t => setThemeName(t));
    }, []);

   return (
        <div className="sub-menu" style={{display: 'flex'}}>
            <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                <div>                        
                <h3 style={{textAlign: 'left', marginLeft: '10px'}}>                                                               
                    {props.menu.title}                                   
                </h3>                      
                <hr style={{borderWidth: '.75px', borderStyle:'solid', marginLeft:'10px', marginRight: '10px', opacity: '.3'}}/>  
                {props.menu.items.map(mi =>                     
                    <ListItemButton className={`sub-menu-button ${active === mi ? 'active' : ''}`}
                        onClick={() => {
                            setActive(mi);
                            if (mi.onClick) {
                                mi.onClick();                            
                            }
                            else {
                                navigate(mi.path);
                            }
                        }} sx={buttonStyle} key={mi.text}>
                        {mi.text}        
                    </ListItemButton>  
                
                )}        
            </div>                         
            <div style={{marginTop: 'auto', marginBottom: '20px'}}>
                {props.showSignOut && 
                    <ListItemButton onClick={() => globalStateStore.signOut()} sx={{...buttonStyle, justifyContent: 'center'}} key='signout'>
                        Sign Out       
                    </ListItemButton>  
                }
                {props.showToggleTheme &&
                    <IconButton className='toggle-theme-button' onClick={() => globalStateStore.toggleTheme()} key='toggletheme'>                        
                    {themeName === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>            
                }
                               
            </div>        
        </div>                    
    </div>
   );
}