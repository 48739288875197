export class Routes {
    static SETTINGS = '/settings';
    static SETTINGS_HOUSEHOLD = `${Routes.SETTINGS}/household`;
    static SETTINGS_HOUSEHOLD_PROFILE = `${Routes.SETTINGS_HOUSEHOLD}/profile`;
    
    static SETTINGS_GRADES = `${Routes.SETTINGS}/grades`;
    static SETTINGS_TERMS = `${Routes.SETTINGS}/terms`;
    static SETTINGS_SUBJECTS = `${Routes.SETTINGS}/subjects`;
    

    static COURSES = `/courses`;
    static COURSES_ADD = `/courses/add`;

    static ACTIVITY = '/activity';
    static SCHEDULE = '/schedule';
    static STUDENTS = '/students';

}