import { View, Text  } from "@react-pdf/renderer";
import {Style} from "@react-pdf/stylesheet"
import React from "react";

const style: Style = {
};
const textStyle: Style = {
    fontSize: '24px',
    fontWeight: 'bold',
};

export function Header(props: {children: string}): JSX.Element {
    return (
        <View style={{...style}}>
            <Text style={{...textStyle}}>{props.children}</Text>
        </View>
    );
}