export function DrawerFormHeader(props: {children: JSX.Element}): JSX.Element {
    return (   
        <div>
            <div style={{
                    display: 'flex', 
                    justifyContent: 'center',                 
                    marginTop: '25px',
                    marginBottom: '25px',
                    flexGrow: 1,
                }}>
                {props.children}
            </div>
        </div>                         
    );
}