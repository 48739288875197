import { Box, Button, IconButton, List, Stack } from "@mui/material";
import {SaveOutlined as SaveIcon, CloseOutlined as CloseIcon, Delete, DeleteOutline } from "@mui/icons-material";
import { AppButton } from "./buttons/primary";
import globalStore from "../../app/global.store";

export function SaveCancelActions(props: { 
    saveAction?: () => void, saveDisabled?: boolean, disableAll?: boolean,
    saveIcon?: JSX.Element,
    cancelAction: () => void, 
    deleteAction?: () => void, 
    deleteConfirmation?: {title: string, text: string}}): JSX.Element {

    const d = () => props.deleteConfirmation ? globalStore.showConfirm({
        title: props.deleteConfirmation.title, 
        body: props.deleteConfirmation.text,
        confirmAction: () => props.deleteAction(),
    })
    : props.deleteAction();

    return (       
        <div style={{display: 'inline-block', width: '100%'}}>            
            <div style={{display: 'inline-block'}}>       
                {props.deleteAction &&                
                    <IconButton color='error' onClick={() => d()} disabled={!!props.disableAll}><DeleteOutline /></IconButton>
                }          
            </div>
             <Stack direction='row' spacing={1} sx={{float: 'right'}}>
                 {props.cancelAction && 
                    <AppButton type='neutral' onClick={props.cancelAction} disabled={!!props.disableAll} icon={<CloseIcon />}/>                
                }
                {props.saveAction &&
                    <AppButton type="primary" onClick={props.saveAction} disabled={!!props.saveDisabled || !!props.disableAll} 
                        icon={props.saveIcon || <SaveIcon />}/>                    
                }
            </Stack>                
        </div>
                            
    );
}