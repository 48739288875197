import { Observable } from "rxjs";
import { Activity, ActivitySaveModel, ActivitySearchParams } from "../models/data/activity/activity.model";
import { apiDelete, apiGet, apiPost, apiPostReportRequest } from "./api-service";

export function saveActivity(model: ActivitySaveModel): Observable<Activity> {    
    return apiPost<Activity>('activity/save', model);
}
export function getActivityForPersonCourse(personCourseID: string): Observable<Activity[]> {
    return apiGet<Activity[]>(`activity/person-course/${personCourseID}`);
}
export function searchActivity(search: ActivitySearchParams): Observable<Activity[]> {
    return apiPost<Activity[]>(`activity/search`, search);
}
export function searchActivityCount(search: ActivitySearchParams): Observable<number> {
    return apiPost<number>(`activity/search/count`, search);
}
export function searchActivity_Print(search: ActivitySearchParams): Observable<Blob> {
    return apiPostReportRequest(`activity/search/print`, search);
}
export function searchActivity_csv(search: ActivitySearchParams): Observable<Blob> {
    return apiPostReportRequest(`activity/search/csv`, search);
}

export const getActivity = (id: string) => apiGet<Activity>(`activity/${id}`);

export const deleteActivity = (id: string) => apiDelete(`activity/${id}`);
