import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    HomeOutlined as HomeIcon,
    GroupOutlined as GroupIcon,
    LightModeOutlined as LightModeIcon,
    MenuBookTwoTone as BookIcon,
    AccessTimeOutlined as TimeIcon,
    SettingsOutlined as SettingsIcon,
    DarkMode as DarkModeIcon,
    CalendarMonthOutlined as CalendarIcon,
    DashboardOutlined as DashboardIcon,
    LibraryBooks as ResourcesIcon,    
    PersonOutline as PersonIcon,
    FormatListBulleted,
} from "@mui/icons-material";
import { Menu } from "../models/app/menu-item";
import { Routes } from "./routes";

const iconStyle = {color: 'white', fontSize: 26};
export class Menus {    
    
    static SETTINGS: Menu = {
        title: 'Settings',
            items: [            
            {
                text: 'Household',                
                path: Routes.SETTINGS_HOUSEHOLD,

            },            
            {
                text: 'Terms',
                path: Routes.SETTINGS_TERMS,            
            },
            // {
            //     text: 'Grades',
            //     path: Routes.SETTINGS_GRADES,
            // },
            {
                text: 'Subjects',
                path: Routes.SETTINGS_SUBJECTS,            
            }
            // ,
            // {
            //     text: 'Courses',
            //     path: Routes.COURSES,
            // }
        ]
    };
    
    static TOP: Menu = { 
        items: [
            {
                text: 'Home',
                icon: <DashboardIcon sx={iconStyle}/>,
                path: '/'        
            },
            {
                text: 'Students',
                icon: <GroupIcon sx={iconStyle}/>,
                path: Routes.STUDENTS,          
            },
            {
                text: 'Schedule',
                icon: <CalendarIcon sx={iconStyle}/>,
                path: Routes.SCHEDULE,          
            },
            {
                text: 'Instruction',
                // icon: <TimeIcon sx={iconStyle}/>,
                icon: <FormatListBulleted />, 
                    // <FontAwesomeIcon icon={['fas', 'person-chalkboard']} style={{fontSize: '24px'}}/>,
                path: Routes.ACTIVITY,          
            },
            // {
            //     text: 'Resources',
            //     icon: <ResourcesIcon sx={iconStyle}/>,
            //     path: '/resources',          
            // },
            {
                text: 'Courses',
                icon: <BookIcon sx={iconStyle}/>,
                path: Routes.COURSES,          
            },            
            // {
            //     text: 'Groups',
            //     icon: <FontAwesomeIcon icon={['fas', 'people-group']} style={{fontSize: '20px'}}/>,                
            //     path: '/groups',          
            // },
            {
                text: 'Settings',
                icon: <SettingsIcon sx={iconStyle}/>,
                basePath: Routes.SETTINGS,   
                subMenu: Menus.SETTINGS,     
            }
        ]
    };    
}