import { Card, CardHeader, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Skeleton, Typography, Box } from "@mui/material";
import { Routes } from "react-router-dom";
import { SharedCourse } from "../../../models/data/course/shared-course.model";
import Grid from '@mui/material/Unstable_Grid2';
import { AppAvatar } from "../../shared/AppAvatar";
import { AppButton } from "../../shared/buttons/primary";
import { acceptSharedCourse, declineSharedCourse } from "../../../services/course.service";
import { Check, Close } from "@mui/icons-material";
import globalStore from "../../../app/global.store";

export function SharedCoursesList(props: { sharedCourses: SharedCourse[], onChange: () => void}) {    
    const accept = (sc: SharedCourse) => acceptSharedCourse(sc).subscribe(_ => {
        globalStore.showToast('success', 'Course added to your library');
        props.onChange(); });
    const decline = (sc: SharedCourse) => declineSharedCourse(sc).subscribe(_ => {
        globalStore.showToast('success', 'Course declined');
        props.onChange(); });

    return <>
        <Grid container spacing={2}>
            <Grid xs={12} md={8} xl={4}>
                <Card>
                    <CardHeader      
                        title={<Typography component="h6" variant="h6">Shared with me</Typography>}/>
                    <CardContent>                                       
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: '30px'}}></TableCell>
                                    <TableCell>Course</TableCell>
                                    <TableCell>Shared by</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.sharedCourses?.map(c => {                                            
                                        return (
                                            <TableRow key={c.id}>
                                                <TableCell>                                                    
                                                    <AppAvatar
                                                        text={c.courseName}
                                                        // bgColor={c.avatarBgColor}
                                                        // textColor={c.avatarTextColor}
                                                        // icon={c.avatarIcon}
                                                    />                                                        
                                                </TableCell>
                                                <TableCell>{c.courseName}</TableCell>
                                                <TableCell>{c.sharedByFirstName} {c.sharedByLastName}</TableCell>  
                                                <TableCell>
                                                    <AppButton size="small" text="Accept" type="primary" icon={<Check />} 
                                                        onClick={() => accept(c)}/>
                                                    <AppButton size="small" text="Decline" type="error" icon={<Close />} 
                                                        onClick={() => decline(c)}/>
                                                </TableCell>                                                 
                                            </TableRow>
                                        );
                                    })
                                }                                    
                            </TableBody>
                        </Table>                        
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>;
}