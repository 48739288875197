import { Document, Page, View, Text, Circle, Svg } from "@react-pdf/renderer";
import { ScheduleViewDay, ScheduleViewItem } from "../../../models/data/schedule/schedule-or-recurrence.model";
import { Header } from "../shared/Header";
import { SubHeader1 } from "../shared/SubHeader1";
import { TableRow } from "../shared/TableRow";
import { distinctFilter } from "../../../util/array";
import dayjs from "dayjs";
import { minutesToHours } from "../../../util/dates";
import { TableCell } from "../shared/TableCell";

const style = {
    padding: '24pt',  };

export function ScheduleWeekReport(viewDays: ScheduleViewDay[], studentPageBreak: boolean, dayPageBreak: boolean): JSX.Element {
    return studentPageBreak ? GroupedByStudent(viewDays, dayPageBreak) : GroupedByDay(viewDays, dayPageBreak); 
}

function GroupedByStudent(viewDays: ScheduleViewDay[], dayPageBreak: boolean) {
    const items = viewDays.flatMap(d => d.items);    
    const students = items.map(i => i.personID).filter(distinctFilter).map(id => {
        const first = items.find(i => i.personID === id);
        return { personID: id, firstName: first?.personFirstName, lastName: first?.personLastName };
    }).sort((a, b) => a.firstName.localeCompare(b.firstName));
    const viewDaysFilteredByStudent = (studentID: string) => viewDays.map(d => ({date: d.date, items: d.items.filter(i => i.personID === studentID)})).filter(d => d.items?.length);

    return <Document style={{margin: '24pt'}}>
        {students.map(s => dayPageBreak 
            ? StudentGroup_WithDayBreak(s, viewDaysFilteredByStudent(s.personID)) 
            : StudentGroup(s, viewDaysFilteredByStudent(s.personID)))
        }
    </Document>;
}

const StudentGroup = (student: {personID: string, firstName: string, lastName: string}, studentViewDays: ScheduleViewDay[]) => 
<Page size="A4" style={{...style}} key={student.personID}>      
    <Header>{`${student.firstName} ${student.lastName}`}</Header>                  
    <View style={{marginTop: '20pt', marginBottom: '6pt'}}>
        {studentViewDays.map(d => <View key={d.date.toISOString()}>
           <SubHeader1 style={{marginBottom: '12pt'}}>{formattedDate(d.date)}</SubHeader1> 
            <ItemsList items={d.items} />
        </View>)
        }
    </View>    
</Page>;

const StudentGroup_WithDayBreak = (student: {personID: string, firstName: string, lastName: string}, studentViewDays: ScheduleViewDay[]) => studentViewDays?.map(d => 
<Page size="A4" style={{...style}} key={d.date.toISOString()}>      
    <Header>{`${student.firstName} ${student.lastName}`}</Header>                  
    <SubHeader1 style={{marginBottom: '12pt'}}>{formattedDate(d.date)}</SubHeader1> 
    <View style={{marginBottom: '6pt'}}>
        <ItemsList items={d.items} />                
    </View>    
</Page>);

function GroupedByDay(viewDays: ScheduleViewDay[], dayPageBreak: boolean) {
    return <Document style={{margin: '24pt'}}>
        {dayPageBreak 
        ? DayGroup_WithDayBreak(viewDays)
            : DayGroup(viewDays)
        }
    </Document>;
}

const DayGroup = (viewDays: ScheduleViewDay[]) => 
<Page size="A4" style={{...style}}>      
    <Header>{`Schedule`}</Header>                  
    <View style={{marginBottom: '6pt'}}>
        {viewDays.map(d => 
            <View key={d.date.toISOString()} style={{marginTop: '18pt'}}>
                <Text style={{marginBottom: '12pt', fontSize: '16pt'}}>{formattedDate(d.date)}</Text> 
                {groupByPerson(d.items).map(g => <View>
                    <SubHeader1 style={{marginBottom: '12pt'}}>{`${g.scheduledActivity[0].personFirstName} ${g.scheduledActivity[0].personLastName}`}</SubHeader1> 
                    <ItemsList items={g.scheduledActivity} />
                </View>)}
            </View>
        )}
    </View>      
</Page>;

const DayGroup_WithDayBreak = (viewDays: ScheduleViewDay[]) => viewDays?.map(d => 
    <Page size="A4" style={{...style}} key={d.date.toISOString()}>      
        <Header>{formattedDate(d.date)}</Header>                  
        
        {groupByPerson(d.items).map(g => 
            <View style={{marginTop: '20pt', marginBottom: '6pt'}}>
                <SubHeader1 style={{marginBottom: '12pt'}}>{`${g.scheduledActivity[0].personFirstName} ${g.scheduledActivity[0].personLastName}`}</SubHeader1> 
                <ItemsList items={g.scheduledActivity} />
            </View>)}
    </Page>);

const ItemsList = (props: {items: ScheduleViewItem[]}) => <>{props.items?.map(i => <View wrap={false}>
    <TableRow style={{marginBottom: '10pt', alignItems: 'center'}}>       
        <View style={{height: '18pt', width: '18pt', border: '2pt solid black'}} />                                        
        <Text style={{fontSize: '12pt', fontWeight: 'bold', marginLeft: 12, marginRight: 8}}>{i.courseName}</Text> 
        <Text style={{fontSize: '12pt'}}>|</Text> 
        
        {!!i.assignmentTitle?.length && <><Text style={{fontSize: '12pt', marginLeft: 8, marginRight: 8}}>{i.assignmentTitle}</Text><Text style={{fontSize: '12pt'}}>|</Text> </>}
        <Text style={{fontSize: '12pt', marginLeft: 8}}>{minutesToHours(i.durationMinutes, 1).replace('.0', '')} hours</Text>            
    </TableRow>        
    {!!i.assignmentDesc &&     
        <TableRow style={{marginBottom: '10pt'}}>            
            <Text style={{fontSize: '10pt', width: '34px'}}>Details</Text>
            <View style={{marginLeft: '10pt', flexGrow: 1, flexDirection: 'column'}}>            
            <Text style={{fontSize: '10pt', marginRight: '40pt'}}>{i.assignmentDesc}</Text>
            </View>       
        </TableRow>    
    }
      {!!i.description &&     
        <TableRow style={{marginBottom: '10pt'}}>            
            <Text style={{fontSize: '10pt', width: '32px'}}>{i.assignmentDesc?.length && 'Addl '}Details</Text>
            <View style={{marginLeft: '10pt', flexGrow: 1, flexDirection: 'column'}}>            
            <Text style={{fontSize: '10pt', marginRight: '40pt'}}>{i.description}</Text>
            </View>       
        </TableRow>    
    }
    <TableRow style={{marginBottom: '10pt'}}>
        <Text style={{fontSize: '10pt', width: '32px'}}>Notes</Text>
        <View style={{marginLeft: '12pt', flexGrow: 1, flexDirection: 'column'}}>            
            {Array(3).fill(0).map(_ => <NotesLine />)}
        </View>       
    </TableRow>    
</View>)}</>;

const NotesLine = () => <View style={{height: '24pt', borderBottom: '1pt solid black', marginRight: '24pt'}}></View>;
const formattedDate = (d: Date) => dayjs(d).format('M/D/YY');
const groupByPerson = (scheduledActivity: ScheduleViewItem[]) => scheduledActivity.map(sa => sa.personID).filter(distinctFilter).map(id => {    
    return {
        personID: id,
        scheduledActivity: scheduledActivity.filter(sa => sa.personID === id),        
    }
});

const Dot = () => <Svg width={4} height={4}><Circle r={2} cx={2} cy={2} fill="black" /></Svg>;

// const PageFooter = () => <View 
//             style={{
//                 position: 'absolute',
//                 fontSize: 12,
//                 bottom: 30,
//                 left: 0,
//                 right: 0,
//                 textAlign: 'center',
//                 color: 'grey',
//             }} >
//           <Text             
//             render={({ pageNumber, totalPages, subPageNumber, subPageTotalPages }) => (
//             `${pageNumber} / ${totalPages}`
//       )} fixed />
// </View>; 