import { AvatarDefinition } from "../shared/avatar-definition";

export interface ScheduledActivity {
    id?: string;
    personID?: string;
    organizationID?: string;
    type?: ScheduledActivityType;
    startDate?: string;
    startTime?: string;
    endDate?: string;
    endTime?: string;
    durationMinutes?: number;
    personCourseID?: string;
    personCourseAssignmentID?: string;
    title?: string;
    description?: string;
    correlationID?: string;

    personFirstName?: string;
    personMiddleName?: string;
    personLastName?: string; 
    personAvatar?: AvatarDefinition;

    courseName?: string;
    assignmentTitle?: string;
    assignmentDesc?: string;
    completedDurationMinutes?: number;
}

export enum ScheduledActivityType {
    CourseActivity = 1,
    OtherInstruction = 2,
    Event = 3
}