import { useEffect, useState } from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { PersonCourseAssignmentOrSection, StudentCourseContextStore } from "../student-course-context.store";
import { PersonCourseAssignment } from "../../../../../../models/data/person-course/person-course-assignment.model";
import { PersonCourseSection } from "../../../../../../models/data/person-course/person-course-section.model";
import { useOutletContext } from "react-router-dom";
import * as colors from '../../../../../../app/colors';
import { AppAvatar } from "../../../../../shared/AppAvatar";
import { Box, Typography } from "@mui/material";
import { StudentAssignmentEditForm } from "./StudentAssignmentEditForm";

export function AssignmentStepper(props: {items: PersonCourseAssignmentOrSection[]}): JSX.Element {    
    const store = useOutletContext<StudentCourseContextStore>();
    const [activeStep, setActiveStep] = useState(0);    
                
    return (store && <Stepper orientation="vertical" activeStep={activeStep}>
        {
        props.items.map((item, i) => {
            if (item.type === 'assignment') {
                const a = item as PersonCourseAssignment;                
                return <Step key={item.id} completed={!!a.completedDate}>
                    <AssignmentStep assignment={a} onClick={() => {
                            setActiveStep(i);
                            store.selectedAssignmentId$?.next(a.id);
                    }} />
                </Step>;
            }
            else {               
                const s = item as PersonCourseSection;                
                return <Step key={item.id} completed={!s.assignments.some(a => !a.completedDate)}>
                    <SectionStep section={s} assignments={s.assignments} onClick={() => setActiveStep(i)}/>
                </Step>;
            }
            })
        }
    </Stepper>);
}

function AssignmentStep(props: { assignment: PersonCourseAssignment, onClick: Function,}) {
    const store = useOutletContext<StudentCourseContextStore>();
    return (
    <>
        <StepLabel style={labelStyle} onClick={() => props.onClick()} 
            icon={
                <AppAvatar 
                    bgColor={(!!props.assignment.completedDate ? colors.colors.success : '#aaa')}
                    icon={!!props.assignment.completedDate ? 'check'  : undefined}
                    size="24px"
                />
            }>
                {props.assignment.title}                
        </StepLabel>         
        {/* <StepContent>
            <StudentAssignmentEditForm assignment={props.assignment} store={store}/>
        </StepContent>         */}
    </>);
}
function SectionStep(props: { section: any, assignments: PersonCourseAssignment[], onClick: Function, }) {
    const complete = !props.section.assignments.some(a => !a.completedDate);
    return (
    <>
        <StepLabel style={labelStyle} onClick={() => props.onClick()}
            icon={
                <AppAvatar 
                    bgColor={(complete ? colors.colors.success : '#aaa')}
                    icon={complete ? 'check'  : undefined}
                    size="24px"
                />
            }>        
            {props.section.name}
        </StepLabel>     
        <StepContent>
            <AssignmentStepper items={props.assignments.map(a => {return {...a, type: 'assignment'} as PersonCourseAssignmentOrSection})} />
        </StepContent>   
    </>);
}

const labelStyle = {
    cursor: 'pointer'
};