import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { FormField } from "../../models/app/form-field";
import { setFormValue, hasLengthValidator } from "../../util/form-util"

export function FormFieldSelect(props: {
    label?: string,
    options: FormFieldSelectOption[], 
    formField: FormField,
    setter: (val: any) => void,
    validator?: (val: any) => string[],
    includeNone?: any
 }) {
    return(
        <FormControl sx={{ width: '100%' }} size="small">
            {props.label && <InputLabel id="grade-select-label">{props.label}</InputLabel>}
            <Select
                labelId="grade-select-label"
                value={props.formField.value}
                label={props.label}
                onChange={(e) => {
                    setFormValue(e.target.value, props.setter, props.validator)
                }}                                        
            >
                {props.includeNone &&
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                }

                {props.options?.map(o => (
                    <MenuItem
                        key={o.value}
                        value={o.value}
                    >
                        {o.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>  
    );
}

export interface FormFieldSelectOption {
    value: string;
    text: string;
}