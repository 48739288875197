import { Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Switch, TextField, TextFieldProps, useAutocomplete } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { FormFieldData } from "./form-data";
import { FormDataContext } from "./Form";

export function FormRadioGroup(props: {
    fieldName: string,
    label?: string,
    radios: {label: string, value: string}[],
    direction?: 'row' | 'column',
}) {
    const formData = useContext(FormDataContext);
    const [field, setField] = useState<FormFieldData>(undefined);
    const [value, setValue] = useState<string>();
    
    const onTouch = () => field.touched$.next(true);
    const onChange = (_, v: string) => { 
        field.value$.next(v); 
        onTouch();
    }

    useEffect(() => {
        const cleanup$ = new Subject<void>();
        
        if (formData) {
            if (!(props.fieldName in formData.fields)) throw Error('Key not found in formData: ' + props.fieldName);
            const field = formData.fields[props.fieldName];
            field.value$.pipe(takeUntil(cleanup$)).subscribe(val => setValue(val));     
            setField(field);
        }

        return () => cleanup$.next();
    }, [props.fieldName, formData]);

    return (!!field &&
        <FormControl>
        {!!props.label && <FormLabel>{props.label}</FormLabel>}
        <RadioGroup
            value={value}
            onChange={onChange}
            row={props.direction !== 'column'}>
            {props.radios.map(r => <FormControlLabel key={r.value} value={r.value} control={<Radio />} label={r.label} />)}
        </RadioGroup>
      </FormControl>
    );
}

