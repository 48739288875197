import { View, Text } from "@react-pdf/renderer";
import React from "react";

const style = {        
};
const textStyle = {
    fontSize: '12pt',    
};
export function TableCell(props: {
    text: string,
    style?: any, 
    width?: string,        

}): JSX.Element {    
    return (
        <View style={{...style,             
            width: props.width || '100pt',            
            }}>                
            <Text style={{...textStyle, ...props.style,}}>{props.text}</Text>        
        </View>
    );
}