import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert, Card, CardContent, Stack } from '@mui/material';
import * as AuthService from '../../services/auth.service';
import { Brand } from '../shared/Brand';
import { AppButton } from '../shared/buttons/primary';
import globalStore from '../../app/global.store';
import img from '../../content/login-bg.jpg';
import { Form } from '../shared/form/Form';
import { FormData, FormFieldData } from '../shared/form/form-data';
import { FormTextField } from '../shared/form/FormTextField';
import { requiredString } from '../shared/form/validators';
import { useSubscription } from '../../hooks/useSubscription';
import { FormBool } from '../shared/form/FormBool';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://knik.tech">
        Knik Tech
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function LoginPage() {
  const [formData, setFormData] = useState<FormData>();  
  const [formValid, setFormValid] = useState(false);

  const login = () => {
    setLoginFailed(false);
    const formValue = formData.getValue();
    const creds = {
      username: formValue.email,
      password: formValue.password,
    };

    globalStore.login$(creds.username, creds.password).subscribe(result => {
      !result && setLoginFailed(true);
      !!result && formValue.remember && localStorage.setItem('account', formValue.email);
      !!result && !formValue.remember && localStorage.removeItem('account');
    })
  };

  useEffect(() => {
    const username = localStorage.getItem('account');
    setFormData(new FormData({
      email: new FormFieldData(username || '', [requiredString]),
      password: new FormFieldData('', [requiredString]),
      remember: new FormFieldData(!!username),
    }));
  }, []);

  useSubscription(formData?.isValid$(), v => setFormValid(v), [formData]);

  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const handleSubmit = (e) => {
    l('handleSubmit')
    e.preventDefault();
    formValid && login();
  };

  return (
    <Box display={'flex'} height={'100vh'} style={{ backgroundImage: `url(${img})`, backgroundSize: 'cover' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Brand iconColor='white' fontSize={50} style={{ borderRadius: 100, backgroundColor: '#000', padding: 20, marginBottom: 12 }} />
          <Card>
            <CardContent>    
              <Box padding={2} component="form" onSubmit={handleSubmit} noValidate>
              {loginFailed &&
                <Alert variant="outlined" severity="error" style={{ width: '100%', marginTop: '15px' }}>
                  Invalid username or password
                </ Alert>
              }
              <Form formData={formData}>                          
                  <FormTextField fieldName="email"
                      label='Email Address' required={true}                                            
                      textFieldProps={{margin: 'normal', name: 'email', id: 'email', size: 'medium'}}/>              

                  <FormTextField fieldName="password"
                      label='Password'
                      required={true}
                      textFieldProps={{margin: 'normal', type: 'password', size: 'medium'}}/>  

                  <FormBool fieldName='remember' label='Remember me'/>
                  <div style={{ textAlign: 'center' }}>                    
                    <AppButton
                      text='Sign In'
                      sx={{ mt: 3, mb: 2, width: '160px' }}                      
                      size='large'
                      onClick={() => login()}
                      disabled={!formValid}
                      buttonType='submit'
                    />
                  </div>                                                                                                                                                  
              </Form> 
              </Box>          
              {/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  className='at-text-input'
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  className='at-text-input'
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <div style={{ textAlign: 'center' }}>
                  <AppButton
                    text='Sign In'
                    sx={{ mt: 3, mb: 2, width: '160px' }}
                    buttonType='submit'
                    size='large'
                  />
                </div>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              <Copyright sx={{ mt: 8, mb: 4 }} /> */}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}