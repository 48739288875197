import { Stack } from "@mui/material";
import { combineLatest } from "rxjs";
import globalStore from "../../app/global.store";
import { AvatarDefinition } from "../../models/data/shared/avatar-definition";
import { DrawerFormBody } from "../pages/layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../pages/layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../pages/layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../pages/layout/form-drawer/DrawerFormHeader";
import { Form } from "./form/Form";
import { FormActions } from "./form/FormActions";
import { FormSelect, makeSelectOption } from "./form/FormSelect";
import { FormTextField } from "./form/FormTextField";
import { FormData, FormFieldData } from "./form/form-data";
import { requiredString } from "./form/validators";
import { getContrastColor, materialColors } from "../../util/color";
import { AppAvatar2, getFaIcon } from "./AppAvatar2";
import { useState } from "react";
import { useSubscription } from "../../hooks/useSubscription";
import { FormRadioGroup } from "./form/FormRadioGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Check } from "@mui/icons-material";
import { miscIcons } from "../../util/icons";


export function AvatarEditForm(props: {avatar: AvatarDefinition, onSaved: (a: AvatarDefinition) => void}) {
    const [formData] = useState(new FormData({
        bgColor: new FormFieldData(props.avatar.bgColor, 
            [requiredString]
        ),
        icon: new FormFieldData(props.avatar.icon || '', 
            [(v, f) => f.fields.contentType?.value$?.value === 'text' || requiredString(v)],
            ['contentType']
        ),
        text: new FormFieldData(props.avatar.text || '',
            [(v, f) =>  f.fields.contentType?.value$?.value === 'icon' || requiredString(v)],
            ['contentType']
        ),
        contentType: new FormFieldData(!!props.avatar.icon ? 'icon' : 'text', 
            [requiredString]
        ),                
    }));

    const [contentType, setContentType] = useState<AvatarContentType>();
    useSubscription(formData?.fields.contentType.value$, ct => setContentType(ct), [formData]);

    const save = () => { 
        const formValue = formData.getValue();

        props.onSaved({
            bgColor: formValue.bgColor,
            textColor: getContrastColor(formValue.bgColor),
            icon: formValue.contentType === 'icon' ? formValue.icon : undefined,
            text: formValue.contentType === 'text' ? formValue.text : undefined,
        });
    };
    
    const [avatarState, setAvatarState] = useState<AvatarDefinition>(props.avatar);
    useSubscription(combineLatest([formData.fields.bgColor.value$, formData.fields.icon.value$, formData.fields.text.value$, formData.fields.contentType.value$]), 
        ([bg, i, t, ct]) => {
            setAvatarState({
                bgColor: bg,
                textColor: bg && getContrastColor(bg),
                icon: ct === 'icon' ? i : undefined,
                text: ct === 'text' ? t : undefined,
            });
        }, [formData])
    
    return <DrawerFormContainer>
        <Form formData={formData}>            
            <DrawerFormHeader>
                <AppAvatar2 avatarDefinition={avatarState} size="50px" fontSize="25px" />
            </DrawerFormHeader>            
            <DrawerFormBody>              
                <Stack spacing={2} sx={{minWidth: '275px'}}>
                    <FormRadioGroup fieldName="contentType" label="" radios={[{label: 'Text', value: 'text'}, {label: 'Icon', value: 'icon'}]} />
                    
                    <Stack direction={'row'} spacing={1}>
                        <FormSelect                         
                            label='Color'
                            fieldName="bgColor"                 
                            fullWidth={false}   
                            required={true}
                            options={makeColorOptions(props.avatar?.bgColor)}  
                            width={'100px'}                     
                        />
                        
                        {contentType === 'icon' 
                            ? <FormSelect fieldName="icon" label='Icon' options={iconOptions} fullWidth={false} required={true} width={'100px'} />
                            : <FormTextField fieldName="text" label='Text' maxLength={2} required={true} textFieldProps={{sx: {width: '100px'}}}/>}
                    </Stack>
                </Stack>                             
            </DrawerFormBody>    
            <DrawerFormFooter>
                <FormActions     
                    saveAction={() => save()}                         
                    saveIcon={<Check />}
                    cancelAction={() => globalStore.popDrawerFormContent()}/>                    
            </DrawerFormFooter>
        </Form>
    </DrawerFormContainer>;
}

const makeColorOptions = (current: string) => 
    (!current || materialColors.find(c => c === current) ? materialColors : [current].concat(materialColors))
        .map(c => makeSelectOption(c, undefined, <AppAvatar2 avatarDefinition={{bgColor: c}} size="24px" />));

type AvatarContentType = 'icon' | 'text';

const iconOptions = [undefined, ...miscIcons]
    .map(i => i && makeSelectOption(i, '', <FontAwesomeIcon icon={getFaIcon(i)} fontSize='large' />))