import { useContext, useState, useEffect } from "react";
import { Subject, takeUntil, distinctUntilChanged, tap, switchMap, of } from "rxjs";
import { PersonCourseAssignment } from "../../../../models/data/person-course/person-course-assignment.model";
import { PersonCourse } from "../../../../models/data/person-course/person-course.model";
import { Person } from "../../../../models/data/person.model";
import { getCoursesForPerson, getPersonCourseAssignments } from "../../../../services/person-course.service";
import { getStudents } from "../../../../services/person.service";
import { FormDataContext } from "../../../shared/form/Form";
import { FormAutocomplete } from "../../../shared/form/FormAutocomplete";
import { makeSelectOption } from "../../../shared/form/FormSelect";
import { TextField } from "@mui/material";
import { ScheduleOrRecurrence } from "../../../../models/data/schedule/schedule-or-recurrence.model";

export function StudentCourseAssignment(props: {showPersonAndCourse?: boolean}) {            
    const formData = useContext(FormDataContext);

    const [students, setStudents] = useState<Person[]>(undefined);
    const [courses, setCourses] = useState<PersonCourse[]>(undefined);
    const [assignments, setAssignments] = useState<PersonCourseAssignment[]>(undefined);             

    useEffect(() => {        
        getStudents().subscribe(s => setStudents(s))        
    }, []);

    useEffect(() => {
        const td$ = new Subject<void>();
        if (formData) {
            formData.fields.personID.value$.pipe(takeUntil(td$), distinctUntilChanged(),
                tap(_ => { setAssignments(undefined); setCourses(undefined); }),
                switchMap(id => id ? getCoursesForPerson(id) : of(undefined)),
                tap(c => setCourses(c))             
            ).subscribe();
            formData.fields.personCourseID.value$.pipe(takeUntil(td$), distinctUntilChanged(),
                tap(_ => setAssignments(undefined)),
                switchMap(id => id ? getPersonCourseAssignments(id, 'abc') : of(undefined)),
                tap(a => setAssignments(a))             
            ).subscribe();
        }
        return () => td$.next();
    }, [formData]);

    return <>
        {props.showPersonAndCourse && <>
            <FormAutocomplete fieldName="personID"
                label="Student" required={true}
                options={students?.map(s => makeSelectOption(s.id, `${s.firstName} ${s.lastName}`))}
            />
            <FormAutocomplete fieldName="personCourseID"
                label="Course" required={true} disabled={!courses}
                options={courses?.map(c => makeSelectOption(c.id, `${c.course_Name}`))}
            />    
        </>}    
        {assignments?.length > 0 && <FormAutocomplete fieldName="personCourseAssignmentID"
            label="Assignment" disabled={!assignments}
            options={assignments?.map(a => makeSelectOption(a.id, a.title))}
        />}        
    </>;
}

export function StudentCourseAssignmentReadonly(props: {sa: ScheduleOrRecurrence, showPersonAndCourse?: boolean}) {
    return <>
        {props.showPersonAndCourse && <>
            <TextField label='Student' disabled value={`${props.sa.personFirstName} ${props.sa.personLastName}`} size='small' />      
            <TextField label='Course' disabled value={props.sa.courseName} size='small' />                                
        </>}        
        {props.sa.personCourseAssignmentID && 
            <TextField label='Assignment' disabled size='small' value={props.sa.assignmentTitle}/>      
        }
    </>;
}