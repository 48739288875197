import { defer, from, map, Observable, of, switchMap, take,} from "rxjs";
import globalStore from "../app/global.store";
import { prettyJson } from "../util/pretty-json";

export function apiGet<T>(url: string): Observable<T | undefined> {    
    const apiHost = process.env.REACT_APP_API_HOST;        
    const token = globalStore.token$?.value;
    return defer(
        () => from(
            fetch(
                `${apiHost}/api/${url}`, 
                {
                    method: 'get',
                    mode: 'cors',        
                    headers: {
                        'Authorization': token ? 'Bearer ' + token : undefined,
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    }        
                }
        ))).pipe(
            take(1),
            switchMap(response => handleResponse<T>(response)),
            map(data => data ? data as T : undefined),            
        );   
}

export function apiPost<T>(url: string, data: any): Observable<T | undefined> {     
    const apiHost = process.env.REACT_APP_API_HOST;        
    const token = globalStore.token$?.value;
    return defer(
        () => from(
            fetch(
                `${apiHost}/api/${url}`, 
                {
                    method: 'post',                  
                    mode: 'cors',        
                    headers: {
                        'Authorization': token ? 'Bearer ' + token : undefined,
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                    body: JSON.stringify(data),
                }
        ))).pipe(     
            take(1),       
            switchMap(response => handleResponse<T>(response)),
            map(data => data ? data as T : undefined),
        );       
}


function handleResponse<T>(response: Response): Observable<any> {    
    if (!response) return undefined;

    if (response.status === 401) {
        globalStore.signOut();      
        return of(undefined);
    }
    else if (response.status < 200 || response.status > 299) {
        throw new Error('Request unsuccessful. Status: ' + response.status);
    }    
    return from(response.json());
}
function handleResponseBlob(response: Response): Observable<Blob> {    
    if (!response) return undefined;

    if (response.status < 200 || response.status > 299) {
        throw new Error('Request unsuccessful. Status: ' + response.status);
    }    
    return from(response.blob());
}


export function apiPostReportRequest(url: string, data: any): Observable<Blob> { 
    const apiHost = process.env.REACT_APP_API_HOST;        
    const token = globalStore.token$?.value;
    return defer(
        () => from(
            fetch(
                `${apiHost}/api/${url}`, 
                {
                    method: 'post',                  
                    mode: 'cors',        
                    headers: {
                        'Authorization': token ? 'Bearer ' + token : undefined,
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                    body: JSON.stringify(data),
                }
        ))).pipe(        
            take(1),    
            switchMap(r => handleResponseBlob(r)),
        );   
}

export function apiDelete(url: string): Observable<undefined> {    
    const apiHost = process.env.REACT_APP_API_HOST;        
    const token = globalStore.token$?.value;
    return defer(
        () => from(
            fetch(
                `${apiHost}/api/${url}`, 
                {
                    method: 'delete',
                    mode: 'cors',        
                    headers: {
                        'Authorization': token ? 'Bearer ' + token : undefined,
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    }        
                }
        ))).pipe(
            take(1),
            switchMap(response => handleResponse<void>(response)),                        
        );   
}
