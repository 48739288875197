import { Typography, Stack } from "@mui/material";
import { useState} from "react";
import globalStore from "../../../../app/global.store";
import { Form } from "../../../shared/form/Form";
import { FormActions } from "../../../shared/form/FormActions";
import { FormDatePicker } from "../../../shared/form/FormDatePicker";
import { FormTextField } from "../../../shared/form/FormTextField";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { StudentCourseAssignmentReadonly } from "./StudentCourseAssignment";
import { FormBool } from "../../../shared/form/FormBool";
import { requiredDate, range } from "../../../shared/form/validators";
import { dayOfWeekName, localDateString } from "../../../../util/dates";
import { saveScheduledActivityRecurring } from "../../../../services/schedule.service";
import { ScheduledActivityRecurring } from "../../../../models/data/schedule/scheduled-activity-recurring.model";
import { getHoursMinutesFromDuration } from "../../../../models/data/activity/activity.model";

export function RecurrenceEditForm(props: {    
    scheduleRecurrence: ScheduledActivityRecurring,
    showPersonAndCourse?: boolean,
    onSaved: () => void,    
}): JSX.Element {    
    const [formData] = useState<FormData>(makeFormData(props.scheduleRecurrence));    
    
    return (
        <DrawerFormContainer>
            <Form formData={formData}>            
                <DrawerFormHeader>
                    <Typography variant="h6">Recurring Schedule</Typography>
                </DrawerFormHeader>            
                <DrawerFormBody>              
                    <Stack spacing={1.5} sx={{minWidth: '275px'}}>
                        <StudentCourseAssignmentReadonly showPersonAndCourse={props.showPersonAndCourse} sa={props.scheduleRecurrence}/>                        
                        <FormDatePicker fieldName="startDate" label='Start Date' required={true}/>                                                                            
                        <FormDatePicker fieldName="endDate" label='End Date'/>                                                                                
                        <div>
                            <FormTextField fieldName="hours" 
                                label='Hours' required={true}                                 
                                textFieldProps={{type: 'number', sx: {width: '120px', mr: 2}, fullWidth: false}} />
                            <FormTextField fieldName="minutes" 
                                label='Minutes' required={true}
                                textFieldProps={{type: 'number', sx: {width: '120px'}, fullWidth: false}} />                            
                        </div>            
                        <div>
                            <FormTextField fieldName="description" label='Details' maxLength={200} 
                                textFieldProps={{multiline: true, minRows: 3}}
                            />                            
                        </div>                       
                        <Stack spacing={0}>
                            {new Array(7).fill(0).map((_, dow) =>                             
                                <FormBool label={dayOfWeekName(dow)} fieldName={'dow_' + dow} type='checkbox' key={dow}/>                            
                            )}
                        </Stack>                                                                   
                    </Stack>                             
                </DrawerFormBody>    
                <DrawerFormFooter>
                    <FormActions     
                        saveAction={() => save()} 
                        cancelAction={() => globalStore.popDrawerFormContent()}/>                    
                </DrawerFormFooter>
            </Form>
        </DrawerFormContainer>);    
        
        function save() {    
            const formValue = formData.getValue();
        
            const toSave: ScheduledActivityRecurring = { ...props.scheduleRecurrence,                
                startDate: formValue.startDate,
                endDate: formValue.endDate ? formValue.endDate : null,
                durationMinutes: ((+formValue.hours * 60) + (+formValue.minutes)),                                                
                description: formValue.description,
                recurrenceDays: 
                        Object.keys(formValue)
                            .filter(k => k.startsWith('dow_') && !!formValue[k])
                            .map(k => +k.replace('dow_', '')) 
                        || [],

            };
            
            saveScheduledActivityRecurring(toSave).subscribe({
                next: _ => {
                    props.onSaved && props.onSaved();
                    globalStore.showToast('success', 'Success');                    
                    globalStore.drawerFormContentStackPop$.next();
                },
                error: e => globalStore.showToast('error', 'Error saving'),
            });
        }
}

function makeFormData(r: ScheduledActivityRecurring): FormData {
    const hoursMinutes = getHoursMinutesFromDuration(r.durationMinutes);          

    return new FormData({        
        startDate: new FormFieldData(r.startDate, [requiredDate]),
        endDate: new FormFieldData(r.endDate),
        hours: new FormFieldData(hoursMinutes.hours, [range(0, 23)]),
        minutes: new FormFieldData(hoursMinutes.minutes, [range(0, 59)]),
        description: new FormFieldData(r.description),
        dow_0: new FormFieldData(!!r.recurrenceDays?.includes(0)),
        dow_1: new FormFieldData(!!r.recurrenceDays?.includes(1)),
        dow_2: new FormFieldData(!!r.recurrenceDays?.includes(2)),    
        dow_3: new FormFieldData(!!r.recurrenceDays?.includes(3)),    
        dow_4: new FormFieldData(!!r.recurrenceDays?.includes(4)),        
        dow_5: new FormFieldData(!!r.recurrenceDays?.includes(5)),    
        dow_6: new FormFieldData(!!r.recurrenceDays?.includes(6)),    
    });
}