import { BehaviorSubject, combineLatest, filter, map, shareReplay, switchMap } from "rxjs";
import { getScheduledActivity, getScheduledActivityRecurring } from "../../../services/schedule.service";
import { searchActivity } from "../../../services/activity.service";
import { ScheduledActivity } from "../../../models/data/schedule/scheduled-activity.model";
import { ScheduleOrRecurrence, ScheduleViewDay, ScheduleViewItem } from "../../../models/data/schedule/schedule-or-recurrence.model";
import { ScheduledActivityRecurring, makeRecurrenceDateFilter } from "../../../models/data/schedule/scheduled-activity-recurring.model";
import { isSameDay } from "date-fns";
import { Activity } from "../../../models/data/activity/activity.model";
import { sumReducer } from "../../../util/array";
import { dateFromShortDateString, dateOnly, localDateString } from "../../../util/dates";

export class ScheduleStore {
    init(start: Date, end?: Date, personIDs?: string[]) {
        this.dateRange$.next({start: dateOnly(start), end: !!end ? dateOnly(end) : undefined});
        this.personIDs$.next(personIDs);
    }
    dateRange$ = new BehaviorSubject<{start: Date, end?: Date}>(undefined);
    personIDs$ = new BehaviorSubject<string[]>(undefined);

    refresh() {
        this.dateRange$?.next({... this.dateRange$.value})
    }

    items$ = combineLatest([this.dateRange$, this.personIDs$]).pipe(            
        filter(([dr]) => !!dr),
        switchMap(([dr, p]) => 
            getScheduledActivity(
                localDateString(dr.start),
                localDateString(dr.end || dr.start),
                p)
        ), 
        shareReplay(1));  
        
    recurringItems$ = combineLatest([this.dateRange$, this.personIDs$]).pipe(            
        filter(([dr]) => !!dr), 
        switchMap(([dr, p]) => 
            getScheduledActivityRecurring(
                localDateString(dr.start),
                localDateString(dr.end || dr.start),
                p)
        ),
        shareReplay(1));

    activity$ = combineLatest([this.dateRange$, this.personIDs$]).pipe(            
        filter(([dr]) => !!dr),
        switchMap(([dr, p]) => 
            searchActivity({startDate: localDateString(dr.start), endDate: localDateString(dr.end || dr.start), students: p})), 
        shareReplay(1));  
       
    scheduleViewItemsForDay$ = (date: Date, personId?: string) => combineLatest([this.items$, this.recurringItems$, this.activity$]).pipe(
        map(([i, r, a]) => filterAndMakeViewItemsForDate(i, r, a, personId)(date)));
    
    scheduleViewItemsForEachDate$ = (dates: Date[], personId?: string) => combineLatest([this.items$, this.recurringItems$, this.activity$]).pipe(
            map(([i, r, a]) => { 
                const makeViewItems = filterAndMakeViewItemsForDate(i, r, a, personId);
                return dates.map(d => {
                    return {
                        date: d,
                        items: makeViewItems(d),
                    } as ScheduleViewDay;
                });            
            }));    
}   

const filterAndMakeViewItemsForDate = (sa: ScheduledActivity[], sar: ScheduledActivityRecurring[], activity: Activity[], personID?: string) => (date: Date) => {
    date = dateOnly(date);    
    const personIdFilter = makePersonFilter(personID);
    const recurrenceDateFilter = makeRecurrenceDateFilter(date);
    return []
        .concat(sa
            .filter(i => isSameDay(new Date(i.startDate), date) && personIdFilter(i))
            .map(i => itemToScheduleView(i, activity.filter(a => a.scheduledActivityID === i.id))))
        .concat(sar
            .filter(r => recurrenceDateFilter(r) && personIdFilter(r))
            .map(r => recurrenceToScheduleView(date, r, activity.filter(a => a.scheduledActivityRecurringID === r.id && isSameDay(dateFromShortDateString(a.startDate), date)))))
        .sort(sortByName);
}

const itemToScheduleView = (item: ScheduledActivity, activity: Activity[]): ScheduleViewItem => {
    return {
        ...item,
        date: new Date(item.startDate),
        isRecurrence: false,
        completedActivityID: activity?.length ? activity[0].id : undefined,
        completedDurationMinutes: activity?.map(a => a.durationMinutes || 0).reduce(sumReducer, 0),
    };
}
const recurrenceToScheduleView = (date: Date, recurrence: ScheduledActivityRecurring, activity: Activity[]): ScheduleViewItem => {
    return {
        ...recurrence,
        date: date,
        isRecurrence: true,
        completedActivityID: activity?.length ? activity[0].id : undefined,
        completedDurationMinutes: activity?.map(a => a.durationMinutes || 0).reduce(sumReducer, 0),
    };
}
const sortByName = (a: ScheduleOrRecurrence, b: ScheduleOrRecurrence) => a.courseName.localeCompare(b.courseName);
const makePersonFilter = (personID?: string) => (r: ScheduleOrRecurrence) => (!personID || r.personID === personID);
