import { Card, CardContent, Typography, Divider, Box } from "@mui/material";
import { AppAvatar } from "./AppAvatar";
import { AvatarDefinition } from "../../models/data/shared/avatar-definition";
import { AppAvatar2 } from "./AppAvatar2";

export function AvatarCard(props: {
    avatar: AvatarDefinition,
    cardTitle?: string,
    children?: JSX.Element | JSX.Element[],
    className?: string,
    onClick?: () => void,
}) {
    return <Card className={props.className} onClick={props.onClick}>
        <CardContent>
            <Box sx={{display:'flex', alignItems: 'center'}}>                        
                <Box sx={{display: 'inline-block'}}>
                    <AppAvatar2 avatarDefinition={props.avatar}
                        size='45px' fontSize="22px"/>
                </Box>
                <Box sx={{display: 'inline-block', ml: 4}}>
                    {props.cardTitle &&
                        <Typography component="h6" variant="h6">
                        {props.cardTitle}
                       </Typography>                                         
                    }
                    {props.children} 
                </Box>
            </Box>                                                
        </CardContent>
    </Card>;
}