import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { FormField } from "../../../../../models/app/form-field";
import { CourseSection } from "../../../../../models/data/course/course-section.model";
import { saveCourseSection, deleteSection } from "../../../../../services/course.service";
import { setFormValue, hasLengthValidator } from "../../../../../util/form-util";
import { SaveCancelActions } from "../../../../shared/SaveCancelActions";
import Grid from "@mui/material/Unstable_Grid2";
import globalStore from "../../../../../app/global.store";

export function CourseSectionModal(
    props: {
        section: CourseSection, 
        onSaved: (section: CourseSection) => void, 
        onCancel: () => void,
        onError: () => void,
    }) {
    const [name, setName] = useState<FormField>({value: props.section?.name || ''});    
    const [busy, setBusy] = useState(false);
    const save = () => {
        setBusy(true);

        saveCourseSection({
            ...props.section,
            name: name.value,            
        }).subscribe(
            {
                next: s => props.onSaved(s),
                error: e => props.onError(),
            }
        )
    }
    const d = () => {
        setBusy(true);
        
        deleteSection(props.section).subscribe({
            next: _ => {                
                props.onSaved(undefined);
                globalStore.showToast('success', 'Deleted successfully');
            },
            error: _ => globalStore.showToast('error', 'Error deleting'), 
        });
    };
    return (
        <Dialog open={true} fullWidth={true} maxWidth='sm'>                   
            <DialogTitle>{props.section.id ? 'Edit' : 'Add'} Section</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{mt: 3}}>
                    <Grid xs={12}>
                        <TextField 
                            label="Name" 
                            variant="outlined" 
                            size="small" 
                            fullWidth                   
                            onChange = {(e)=> { 
                                setFormValue(e.target.value, setName, hasLengthValidator);
                            }}             
                            value = {name.value}   
                            required = {true}         
                            error = {!!name.errors?.length}                                                   
                        />
                    </Grid>                                         
                </Grid>       
            </DialogContent>                              
            <DialogActions>
                <SaveCancelActions saveAction={save} cancelAction={props.onCancel} disableAll={busy} 
                    deleteAction={props.section?.id ? () => d() : undefined} 
                    deleteConfirmation={{title: 'Confirm', text: "Are you sure you want to delete this section and all it's assignments?"}} />                                
            </DialogActions>
        </Dialog> 
    );    
}