import { addDays } from "date-fns";
import { Card, CardContent, CardHeader, Chip, Stack, Typography, useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import { ScheduleWeekDataContext } from "./ScheduleWeek";
import { ScheduleViewDay, ScheduleViewItem } from "../../../../models/data/schedule/schedule-or-recurrence.model";
import { useSubscription } from "../../../../hooks/useSubscription";
import { ScheduleStore } from "../schedule.store";
import { ScheduleDayList } from "../shared/ScheduleDayList";
import dayjs from "dayjs";

export function ScheduleWeekGrid(props: { weekDates: Date[] }) {            
    const isCondensed = !useMediaQuery("(min-width:1200px)");    
    const store = useContext(ScheduleWeekDataContext);     
    const [itemsByDay, setItemsByDay] = useState<ScheduleViewDay[]>();
    
    useSubscription(store?.scheduleViewItemsForEachDate$(props.weekDates), i => setItemsByDay(i), [store, props.weekDates]);

    return !!itemsByDay && <Stack spacing={1} direction={ isCondensed ? 'column' : 'row'}>{
        props.weekDates.map(d => ScheduleDay(d, itemsByDay.find(i => i.date.getTime() === d.getTime())?.items || [], isCondensed, store)) }
    </Stack>;
}

const ScheduleDay = (date: Date, items: ScheduleViewItem[], isCondensed: boolean, store: ScheduleStore) => {         
    return (        
        <Card key={date.toISOString()} sx={{height: '100%', padding: 1, maxWidth: '500px', minWidth: '120px'}}>
            <CardHeader title={<>                    
                <Typography variant="h6" fontSize={'medium'} mb={1.5}>{dayjs(date).format('ddd M/D')}</Typography>
            </>} sx={{padding: '8px', justifyContent: 'center'}} />
            <CardContent sx={{paddingY: 0, paddingX: 1}}>
                <ScheduleDayList scheduleStore={store} date={date} />
            </CardContent>
        </Card>        
        ) 
}
