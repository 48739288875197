import { makeSelectOption } from "../../../components/shared/form/FormSelect";
import { EnumMap, EnumTextMap } from "../../../util/enum-map";
import { AvatarDefinition } from "../shared/avatar-definition";
import { CourseAssignment } from "./course-assignment.model";
import { CourseSection } from "./course-section.model";

export interface Course {
    id?: string;
    organizationID?: string;
    name?: string;
    description?: string;
    subjectID?: string;
    academicLevelID?: string;    
    hoursRequired?: number;    
    version?: number;
    academicLevelName?: string;
    avatar?: AvatarDefinition;
    gradingMethod?: GradingMethod;
    creditType?: CreditType;

}

export interface CourseDetails {
    assignments: CourseAssignment[];
    sections: CourseSection[];
}

export enum GradingMethod {
    Percent = 0,
    GPA = 1,
    Letter = 2,
}

export const gradingMethodText: EnumTextMap<GradingMethod> = {
    [GradingMethod.Percent]: 'Percent',
    [GradingMethod.GPA]: 'GPA',
    [GradingMethod.Letter]: 'Letter',
}

export enum CreditType {
    Hours = 0,
    HighSchool = 1,
}

