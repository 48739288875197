import { Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import globalStore from "../../../app/global.store";
import ReactPDF, { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Close, Download } from "@mui/icons-material";
import { AppButton } from "../../shared/buttons/primary";
import { CenteredContent } from "../../shared/CenteredContent";

export function ReportModal(): JSX.Element {
    const [pdfContent, setPdfContent] = useState<JSX.Element>();    
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {        
        const sub = globalStore.reportContent$.subscribe(c => {
            setOpen(!!c);
            !!c && setPdfContent(c);
                        
        });

        return () => sub?.unsubscribe();
    }, []);    

    const close = () => {
        globalStore.reportContent$.next(undefined);
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth='xl' onClose={() => close()}>                                       
            <DialogTitle>
                <IconButton size="small" onClick={() => close()} sx={{padding: 0, float: 'right'}}><Close /></IconButton>            
            </DialogTitle>
            {navigator?.pdfViewerEnabled ?
                <DialogContent sx={{height: '90vh', padding: 0}}>                
                    <PDFViewer width={'100%'} height={'99%'}>
                        {pdfContent}
                    </PDFViewer> 
                </DialogContent>          
                : <DialogContent>
                    <CenteredContent>                    
                        <PDFDownloadLink document={pdfContent} onClick={() => globalStore.popModalContent()}>
                            <AppButton text="Download" icon={<Download />} />                        
                        </PDFDownloadLink>                      
                    </CenteredContent>
                </DialogContent>  
            }                              
        </Dialog>     
    );
}
