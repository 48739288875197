import { prettyJson } from "./util/pretty-json";

export {};
declare global {
    function l(message: string, obj?: any) : boolean;
}

const _global = window as any
_global.l = function(message: string, obj?: any) {
    if (obj) console.log(`${message}: ${prettyJson(obj)}`);
    else console.log(message);    
    return true;
}