import { Observable } from "rxjs";
import { PersonCourseAssignment } from "../models/data/person-course/person-course-assignment.model";
import { PersonCourse } from "../models/data/person-course/person-course.model";
import { apiGet, apiPost } from "./api-service";

export function getCoursesForPerson(personID: string, academicTermId?: string): Observable<PersonCourse[]> {
    return apiGet<PersonCourse[]>(`person/${personID}/courses${(academicTermId ? '?academicTermID=' + academicTermId : '')}`);
}

export function getPersonCourseById(personCourseID: string, personID: string): Observable<PersonCourse> {
    return apiGet<PersonCourse>(`person/${personID}/courses/${personCourseID}`);
}

export  function getPersonCourseAssignments(personCourseID: string, personID: string): Observable<PersonCourseAssignment[]> {
    return apiGet<PersonCourseAssignment[]>(`person/${personID}/courses/${personCourseID}/assignments`);
}

export  function getPersonCourseAssignment(personCourseAssignmentID: string): Observable<PersonCourseAssignment> {
    return apiGet<PersonCourseAssignment>(`person/abc/courses/assignments/${personCourseAssignmentID}`);
}

export function savePersonCourse(personCourse: PersonCourse): Observable<PersonCourse> {
    return apiPost<PersonCourse>(`person/${personCourse.personID}/courses/save`, personCourse);
}

export function savePersonCourseAssignment(assignment: PersonCourseAssignment): Observable<PersonCourseAssignment> {
    return apiPost<PersonCourseAssignment>(`person/all/courses/${assignment.personCourseID}/assignments/save`, assignment);
}

export function getCompletedAssignments(): Observable<PersonCourseAssignment[]> {
    return apiGet<PersonCourseAssignment[]>('person/all/courses/completedAssignments');
}
