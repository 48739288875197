import { Page } from "../../shared/Page";
import Grid from "@mui/material/Unstable_Grid2";
import { ScheduleMonth } from "./ScheduleMonth/ScheduleMonth";
import { createContext, useEffect, useState } from "react";
import { ScheduleWeek } from "./ScheduleWeek/ScheduleWeek";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { CalendarViewMonth, ViewWeek } from "@mui/icons-material";
import { BehaviorSubject, shareReplay, take } from "rxjs";
import { getStudents } from "../../../services/person.service";
export const SchedulePersonSelectorContext = createContext<SchedulePersonSelectorStore>(undefined);

export function SchedulePage(): JSX.Element {    
    const [mode, setMode] = useState<'month' | 'week'>('week');
    const [personSelectorStore, setPersonSelectorStore] = useState<SchedulePersonSelectorStore>();

    useEffect(() => {
        const c = new SchedulePersonSelectorStore();
        c.students$.pipe(take(1)).subscribe(students => {
            c.selectedStudents$ = new BehaviorSubject(students.map(s => s.id));
            setPersonSelectorStore(c);
        });
    }, []);

    return (
        <Page title={<>Schedule
            <ToggleButtonGroup
                color="primary"
                value={mode}
                exclusive
                size="small"
                onChange={(_, val) => setMode(val)} sx={{ml: 3}}>
                <ToggleButton value="week"><ViewWeek /></ToggleButton>
                <ToggleButton value="month"><CalendarViewMonth /></ToggleButton>            
            </ToggleButtonGroup>
        </>}>
            <Grid container spacing={2}>   
                {!!personSelectorStore &&      
                    <SchedulePersonSelectorContext.Provider value={personSelectorStore}>
                        <Grid xs={12}>
                            {(mode === 'month' && <ScheduleMonth />)
                                ||
                            (mode === 'week' && <ScheduleWeek />)}                    
                        </Grid> 
                    </SchedulePersonSelectorContext.Provider>                                    
                }
            </Grid>
        </Page>
    );
}

export class SchedulePersonSelectorStore {
    students$ = getStudents().pipe(shareReplay(1));
    selectedStudents$: BehaviorSubject<string[]>;
}