import { useEffect, useState } from "react";
import { Page } from "../../../shared/Page";
import { Person } from "../../../../models/data/person.model";
import { RoleType } from '../../../../models/data/enums/role-types';
import { getPersonsForOrganization } from '../../../../services/person.service';
import { PersonListCard } from "./PersonListCard";
import Grid from '@mui/material/Unstable_Grid2';
import globalStore from "../../../../app/global.store";

export function HouseholdDetail() {
    const [parents, setParents] = useState<Person[]>([]);
    const [students, setStudents] = useState<Person[]>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {        
        getPersonsForOrganization(globalStore.user$.value.organizationID)
        .subscribe(persons => {
            setParents(persons.filter(p => p.roleType === RoleType.Parent));
            setStudents(persons.filter(p => p.roleType === RoleType.Student)); 
            setLoaded(true);           
        });
    }, []);    
    return (
    <Page breadcrumbs={[{text: 'Settings'}, {text: 'Household'}]} title='Household'>      
        <Grid container spacing={2}>            
            <Grid xs={12} md={6} xl={3}>                
                <PersonListCard title='Students' persons={students} roleType={RoleType.Student} loaded={loaded} />            
            </Grid>
            <Grid xs={12} md={6} xl={3}>                
                <PersonListCard title='Parents' persons={parents} roleType={RoleType.Parent} loaded={loaded}/>            
            </Grid>
        </Grid>                              
    </Page>
    );
}