import { Card, CardHeader, CardContent } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { BehaviorSubject, shareReplay } from "rxjs";
import globalStore from "../../../../app/global.store";
import { useSubscription } from "../../../../hooks/useSubscription";
import { weekEnd, weekStart } from "../../../../util/dates";
import { ScheduleStore } from "../schedule.store";
import { Add } from "@mui/icons-material";
import { SchdeuleAddForm } from "../edit-drawer/ScheduleAddForm";
import { ScheduleWeekTitle } from "./ScheduleWeekTitle";
import { ScheduleWeekGrid } from "./ScheduleWeekGrid";
import { CardActionButtons } from "../../../shared/CardActionButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays } from "date-fns";
import { ScheduleWeekPrintOptions } from "./ScheduleWeekPrintOptions";
import { PersonSelectionChips } from "../../../shared/PersonSelectionChips";
import { getStudents } from "../../../../services/person.service";
import { SchedulePersonSelectorContext } from "../Schedule";
export const ScheduleWeekDataContext = createContext<ScheduleStore>(undefined);

export function ScheduleWeek(): JSX.Element {
    const [selectedWeek$] = useState(new BehaviorSubject<Date>(weekStart(new Date())));
    useSubscription(selectedWeek$, d => d && setStartEnd(d), [selectedWeek$]);        
    
    const [startDate, setStartDate] = useState<Date>(undefined);
    const [endDate, setEndDate] = useState<Date>(undefined);
    
    const [store] = useState<ScheduleStore>(new ScheduleStore());    
    
    const [selectedStudents, setSelectedStudents] = useState<string[]>();
    const personSelectorStore = useContext(SchedulePersonSelectorContext);
    useSubscription(personSelectorStore.selectedStudents$, s => setSelectedStudents(s));
    

    useEffect(() => {
        startDate && endDate && selectedStudents && store.init(startDate, endDate, selectedStudents);
    }, [startDate, endDate, selectedStudents]);    

    const setStartEnd = (sunday: Date) => {        
        setStartDate(weekStart(sunday));
        setEndDate(weekEnd(sunday));
    };

    const print = () => globalStore.pushDrawerFormContent(<ScheduleWeekPrintOptions store={store} dates={getWeekDates(startDate)} />);

    return (
        <ScheduleWeekDataContext.Provider value={store}>
            <Card sx={{position: 'relative'}} style={{backgroundColor: 'transparent'}}>
                <CardHeader         
                    title={<>
                        <ScheduleWeekTitle selectedWeekStart$={selectedWeek$} />
                        <PersonSelectionChips selected$={personSelectorStore.selectedStudents$} persons$={personSelectorStore.students$} />
                    </>}    
                    action={     
                        <CardActionButtons buttons={[
                            {text: 'Add', icon: <Add />, 
                                action: () =>  
                                    globalStore.pushDrawerFormContent(
                                                <SchdeuleAddForm                                 
                                                    onSaved={() => store.refresh()} 
                                                    showPersonAndCourse={true}/> )
                            },
                            {text: 'Print', icon: <FontAwesomeIcon icon={['fas', 'file-pdf']} />, action: () => print() },
                        ]} />               
                    }
                />
                <CardContent>                            
                    <ScheduleWeekGrid weekDates={getWeekDates(startDate)} />
                </CardContent>        
            </Card>
        </ScheduleWeekDataContext.Provider>
    );    
}

const getWeekDates = (sunday: Date) => Array(7).fill(1).map((_, i) => !i ? sunday : addDays(sunday, i));



