// https://github.com/isuru88/random-material-color
import * as randomColor from 'random-material-color';
// https://www.npmjs.com/package/contrast-color
import * as ContrastColor from 'contrast-color';

import { amber, blue, blueGrey, brown, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from "@mui/material/colors";

export function getAvatarColorForText(text: string): {backgroundColor: string, color: string,} {
    const bgColor = randomColor.getColor(
        {
            text: `${text}`
        });

    const cc = new ContrastColor({});
      
    return {
        backgroundColor: bgColor,
        color: cc.contrastColor({bgColor: bgColor}),
    };
}

export function getContrastColor(bgColor: string): string {    
    const cc = new ContrastColor({bgColor: bgColor});
    return cc.contrastColor();
}

export const materialColors: string[] = []
    .concat(getColorShades(red))
    .concat(getColorShades(pink))
    .concat(getColorShades(purple))
    .concat(getColorShades(deepPurple))
    .concat(getColorShades(indigo))
    .concat(getColorShades(blue))
    .concat(getColorShades(lightBlue))
    .concat(getColorShades(cyan))
    .concat(getColorShades(teal))
    .concat(getColorShades(green))
    .concat(getColorShades(lightGreen))
    .concat(getColorShades(lime))
    .concat(getColorShades(yellow))
    .concat(getColorShades(amber))
    .concat(getColorShades(orange))
    .concat(getColorShades(deepOrange))
    .concat(getColorShades(brown))
    .concat(getColorShades(grey))
    .concat(getColorShades(blueGrey))
    ;

function getColorShades(color: any) { 
    const shades = ['300', '500', '700'];
    return Object.keys(color).filter(k => !!shades.find(s => s === k)).map(k => color[k] as string);
}

export function randomMaterialColor(): {backgroundColor: string, color: string,} {
    const i = Math.floor((Math.random() * 100)) % materialColors.length;
    const bg = materialColors[i];
    return {backgroundColor: bg, color: getContrastColor(bg)};
}