import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { delay, of, switchMap, switchScan, tap } from "rxjs";
import globalStore from "../../../../app/global.store";

export function FormDrawer(props: {}): JSX.Element {
    const [stack, setStack] = useState<JSX.Element[]>([]);    
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {        
        const sub = globalStore.drawerFormContentStack$.subscribe(c => {
            setOpen(!!c?.length);
            !!c?.length && setStack(c);
        });

        return () => sub?.unsubscribe();
    }, []);

    return (<> {stack?.map((content, i) => 
        <Drawer
            key={i}
            anchor='right'
            open={open}                        
            className={`app-${globalStore.theme$?.value || 'light'}`}         
            hideBackdrop={i > 0}   
            onClose={() => globalStore.popDrawerFormContent()}
        >          
            {content}
      </Drawer>
      )}
      </>
    );
}
