import { useEffect, useState } from "react";
import { Course, CreditType, gradingMethodText } from "../../../../../models/data/course/course.model";
import Grid from "@mui/material/Unstable_Grid2";
import { Card, CardContent, CardActions, Skeleton, Box, CardHeader, Typography, IconButton, Stack } from "@mui/material";
import { saveCourse } from "../../../../../services/course.service";
import { AcademicLevel } from "../../../../../models/data/settings/academic-level";
import { useNavigate } from "react-router-dom";
import { getAvatarColorForText, getContrastColor } from "../../../../../models/data/person.model";
import { AppAvatar, getFaIcon } from "../../../../shared/AppAvatar";
import { FormData, FormFieldData } from "../../../../shared/form/form-data";
import { minNumber, requiredString } from "../../../../shared/form/validators";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { FormTextField } from "../../../../shared/form/FormTextField";
import { Form } from "../../../../shared/form/Form";
import { FormSelect, makeSelectOption } from "../../../../shared/form/FormSelect";
import { FormActions } from "../../../../shared/form/FormActions";
import globalStore from "../../../../../app/global.store";
import { SubjectSelect } from "../../../../shared/SubjectSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Edit, FourMpRounded, Shortcut } from "@mui/icons-material";
import { ShareCourseForm } from "../assignment/ShareCourseForm";
import { Page } from "../../../../shared/Page";
import { CurriculumList } from "./CurriculumList";
import { CardActionButtons } from "../../../../shared/CardActionButtons";
import { AvatarEditForm } from "../../../../shared/AvatarEditForm";
import { AvatarDefinition } from "../../../../../models/data/shared/avatar-definition";
import { AppAvatar2 } from "../../../../shared/AppAvatar2";
import { randomMaterialColor } from "../../../../../util/color";
import { FormRadioGroup } from "../../../../shared/form/FormRadioGroup";
import { FormBool } from "../../../../shared/form/FormBool";

export function DetailTab(props: { course: Course, academicLevels: AcademicLevel[], onSave: (course: Course) => void }): JSX.Element {
    const navigate = useNavigate();
    
    const [course, setCourse] = useState(props.course);
    const [form, setForm] = useState<FormData>();
    
    const [name, setName] = useState('');

    const [avatar, setAvatar] = useState<AvatarDefinition>();

    useEffect(() => setCourse(props.course), [props.course]);
    useEffect(() => course && setForm(makeForm(course)), [course]);

    useSubscription(form?.fields.name.value$, n => {
        setName(n);        
        if (avatar && !avatar.icon && !avatar.text && !!n?.length) {
            form.fields.avatar.value$.next({...avatar, text: n[0]});
        }
    }, [form]);
    
    useSubscription(form?.fields.avatar.value$, a => setAvatar(a), [form]);

    const share = () => globalStore.pushDrawerFormContent(<ShareCourseForm courseId={course.id} />)

    return (
        <Page 
            breadcrumbs={[{ text: 'Courses', backNav: true }, { text: `${!course?.id ? 'Add' : 'Edit'} Course` }]}
            title={name}
            icon={ avatar && <AppAvatar2 avatarDefinition={avatar} size='70px' fontSize="35px"/>}
            >
            <Form formData={form}>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Card>                        
                            {course && <>
                                <CardHeader 
                                    title={<Typography variant="h6">Details</Typography>}
                                    action={course?.id && <CardActionButtons                                         
                                        buttons={[
                                            {icon: <Shortcut />, text: 'Share this course', action: () => share()},                                        
                                        ]}/>}
                                />                            
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid xs={12} md={6} lg={3}>
                                            <FormTextField fieldName="name" label="Course Name" required={true} />                                            
                                        </Grid>
                                        <Grid xs={6} lg={3}>
                                            {props.academicLevels &&
                                                <FormSelect fieldName="academicLevelID"                                                     
                                                    label="Grade" 
                                                    options={[undefined].concat(props.academicLevels.map(al => makeSelectOption(al.id, al.name)))} />
                                            }                                        
                                        </Grid>
                                        <Grid xs={6} lg={3}>                                        
                                            <SubjectSelect fieldName="subjectID" label='Subject'
                                                required={true} />                                                                            
                                        </Grid>             
                                        <Grid xs={12} md={6} lg={3}>
                                            <FormTextField fieldName="hoursRequired" label="Required Hours" 
                                                textFieldProps={{type: 'number'}}/>                                            
                                        </Grid>        
                                        <Grid xs={6} lg={3}>
                                            { avatar &&
                                                <Stack direction={'row'} spacing={1}>
                                                    <AppAvatar2 avatarDefinition={avatar} size="40px" fontSize="20px" />                                                    
                                                    <IconButton 
                                                        onClick={() => globalStore.pushDrawerFormContent(
                                                            <AvatarEditForm avatar={avatar} 
                                                                onSaved={a => {
                                                                    form.fields.avatar.value$.next(a);
                                                                    globalStore.popDrawerFormContent();
                                                                }} 
                                                            />)
                                                        }>
                                                        <Edit />
                                                    </IconButton>
                                                </Stack>
                                            }
                                        </Grid>                   
                                        <Grid xs={6} lg={3}>
                                            <FormBool label="High school credit" fieldName="isHighSchool" type='switch' />                                                                                        
                                        </Grid>
                                        <Grid xs={6} lg={6}>
                                            <FormRadioGroup 
                                                label="Grading Method" 
                                                fieldName="gradingMethod" 
                                                radios={gradingMethodOptions} />                                            
                                        </Grid>                                        
                                        <Grid xs={12}>
                                            <FormTextField fieldName="description" label='Description' 
                                                textFieldProps={{
                                                    fullWidth: true,
                                                    multiline: true,
                                                    minRows: 3,
                                                }}/>                                        
                                        </Grid>                             
                                    </Grid>
                                    <CardActions sx={{ mt: 2, float: 'right' }}>
                                        <FormActions saveAction={() => save()} cancelAction={() => navigate(-1)} />     
                                    </CardActions>
                                </CardContent>
                            </>}
                            {!props.course &&
                                <CardContent>
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    <Skeleton variant="rectangular" sx={{ height: 30 }} />
                                </CardContent>
                            }
                        </Card>
                    </Grid>
                    {course?.id && 
                        <Grid xs={12} md={6} xl={4}>
                            <CurriculumList courseID={course.id} />
                        </Grid>
                    }
                </Grid>            
            </Form>
        </Page>
    );

    function save() {            
        const formVal = form.getValue();
        saveCourse({
            ...props.course,
            ...formVal,
            isHighSchool: undefined,
            creditType: formVal.isHighSchool ? CreditType.HighSchool : CreditType.Hours,
            academicLevelID: formVal.academicLevelID || undefined,            
            gradingMethod: +formVal.gradingMethod,
            hoursRequired: formVal.hoursRequired || undefined,
        }).subscribe({
            next: c => {
                globalStore.showToast('success', 'Course saved');
                setCourse(c);
                props.onSave(c);
            },
            error: e => {
                globalStore.showToast('error', 'There was an error saving')
            }
        });       
    }
}

function makeForm(c: Course) {
    const randomColor = randomMaterialColor();

    const form = new FormData({
        name: new FormFieldData(c.name, [requiredString]),
        academicLevelID: new FormFieldData(c.academicLevelID),        
        avatar: new FormFieldData(c.avatar || ({bgColor: randomColor.backgroundColor, textColor: randomColor.color} as AvatarDefinition)),
        description: new FormFieldData(c.description),
        subjectID: new FormFieldData(c.subjectID, [requiredString]), 
        gradingMethod: new FormFieldData(c.gradingMethod),       
        isHighSchool: new FormFieldData(c.creditType === CreditType.HighSchool),
        hoursRequired: new FormFieldData(c.hoursRequired || '', [v => (!v && v !== 0) || v > 0]),
    });    

    return form;
}

export const gradingMethodOptions = Object.keys(gradingMethodText).map(gm => ({label: gradingMethodText[gm], value: gm}));