import { Card, CardHeader, Typography, IconButton, CardContent, Table, TableBody, TableRow, TableCell, TableHead, Avatar, useTheme, Skeleton} from "@mui/material";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { getAvatarColor, Person } from "../../../../models/data/person.model";
import { useNavigate } from "react-router-dom";
import { AppButton } from "../../../shared/buttons/primary";
import { RoleType } from "../../../../models/data/enums/role-types";
import { AppAvatar2 } from "../../../shared/AppAvatar2";

export function PersonListCard(props: {title: string, persons: Person[], roleType: number, loaded: boolean}) {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
    <Card>
        <CardHeader title={
            <Typography component="h6" variant="h6">
                {props.title}
            </Typography>
        }
        action={props.roleType === RoleType.Student &&
            <AppButton size="medium" icon={<PersonAddAlt1Icon />} text='New' 
                onClick={() => navigate('person/add', { state: { person: ({roleType: props.roleType} as Person)} })}/> 
        }
        />  
        <CardContent>          
            <Table size="small" >    
                <TableHead>
                    <TableRow>                        
                        <TableCell></TableCell>
                        <TableCell></TableCell>                        
                    </TableRow>
                </TableHead>            
                <TableBody>
                {
                    (props.persons?.length || 0) > 0 &&
                    props.persons.map(p => {
                        const avatarColor = getAvatarColor(p);      
                        return (
                        <TableRow key={p.id} className='tr-hover-grow' onClick={() => navigate('person/' + p.id, { state: { person: p}})}>                            
                            <TableCell sx={{width: '30px'}}>                                
                                {p?.avatar && <AppAvatar2 avatarDefinition={p.avatar} size="30px" fontSize="15px" />}
                            </TableCell>                
                            <TableCell>                                                                               
                                {p.firstName} {p.lastName}                                                                                                                                        
                            </TableCell>                                                                                     
                        </TableRow>
                        );
                    })
                } 
                { !props.loaded &&
                [0,1,2].map((v) => 
                <TableRow key={v}>
                    <TableCell colSpan={3}>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>                    
                </TableRow>)
                }                           
                </TableBody>
            </Table>                                                    
        </CardContent>              
    </Card>
    );
}