import { Box, CircularProgress, Container, LinearProgress, Skeleton, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { DESKTOP_BREAKPOINT } from "../../app/constants";

export function LoaderPage() {
    const size = 85;    
    const useMobileLayout = !useMediaQuery(DESKTOP_BREAKPOINT);
    const [top, setTop] = useState(calcTop());
    
    return (      
        <Container sx={{textAlign: 'center', marginTop: `${top}px`}}>
            <CircularProgress size={size + 'px'} thickness={4} color='inherit'/>       
            {/* <LinearProgress />                         */}
        </Container>                   
    );

    function calcTop() {
        return window.innerHeight/2 - size/2 - (useMobileLayout ? 36 : 0);
    }
}