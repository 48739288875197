import { Typography, Stack, Box } from "@mui/material";
import { useState, useEffect } from "react";
import globalStore from "../../../../app/global.store";
import { getHoursMinutesFromDuration } from "../../../../models/data/activity/activity.model";
import { ScheduledActivity, ScheduledActivityType } from "../../../../models/data/schedule/scheduled-activity.model";
import { saveScheduledActivity } from "../../../../services/schedule.service";
import { Form } from "../../../shared/form/Form";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { FormActions } from "../../../shared/form/FormActions";
import { FormDatePicker } from "../../../shared/form/FormDatePicker";
import { FormTextField } from "../../../shared/form/FormTextField";
import { requiredString, requiredDate, range } from "../../../shared/form/validators";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { StudentCourseAssignmentReadonly } from "./StudentCourseAssignment";
import { localDateString } from "../../../../util/dates";


export function ScheduledActivityEditForm(props: {
    scheduleActivity: ScheduledActivity,
    showPersonAndCourse?: boolean,
    onSaved: (saved: ScheduledActivity) => void,    
}): JSX.Element {    
    const [formData, setFormData] = useState<FormData>();
    useEffect(() => {
        setFormData(makeFormData(props.scheduleActivity));
    }, [props.scheduleActivity]);

    return (
        <DrawerFormContainer>
            <Form formData={formData}>            
                <DrawerFormHeader>
                    <Typography variant="h6">Schedule</Typography>
                </DrawerFormHeader>            
                <DrawerFormBody>              
                    <Stack spacing={2} sx={{minWidth: '275px'}}>
                        <StudentCourseAssignmentReadonly sa={props.scheduleActivity} showPersonAndCourse={props.showPersonAndCourse}/>
                        <Box>
                            <FormDatePicker fieldName="startDate" label='Date' required={true}/>                            
                        </Box> 
                        <Box>
                            <FormTextField fieldName="hours" 
                                label='Hours' required={true}                                 
                                textFieldProps={{type: 'number', sx: {width: '120px', mr: 2}, fullWidth: false}} />
                            <FormTextField fieldName="minutes" 
                                label='Minutes' required={true}
                                textFieldProps={{type: 'number', sx: {width: '120px'}, fullWidth: false}} />                            
                        </Box>     
                        <Box>
                            <FormTextField fieldName="description" label='Details' maxLength={200} 
                                textFieldProps={{multiline: true, minRows: 3}}
                            />                            
                        </Box>                                                                            
                    </Stack>                             
                </DrawerFormBody>    
                <DrawerFormFooter>
                    <FormActions     
                        saveAction={() => save()} 
                        cancelAction={() => globalStore.popDrawerFormContent()}/>                    
                </DrawerFormFooter>
            </Form>
        </DrawerFormContainer>);    
        
        function save() {    
            const formValue = formData.getValue();
            console.log('formValue', formValue)
            const toSave: ScheduledActivity = {...props.scheduleActivity,                 
                type: props.scheduleActivity.type || ScheduledActivityType.CourseActivity,                
                startDate: formValue.startDate,
                endDate: formValue.startDate,
                durationMinutes: ((+formValue.hours * 60) + (+formValue.minutes)),                
                personID: formValue.personID,
                personCourseID: formValue.personCourseID,
                personCourseAssignmentID: formValue.personCourseAssignmentID,
                description: formValue.description,
            };
            
            saveScheduledActivity(toSave).subscribe({
                next: sa => {
                    props.onSaved && props.onSaved(sa);
                    globalStore.showToast('success', 'Success');                    
                    globalStore.drawerFormContentStackPop$.next();
                },
                error: e => globalStore.showToast('error', 'Error saving'),
            });
        }
}

function makeFormData(activity: ScheduledActivity): FormData {      
    const hoursMinutes = getHoursMinutesFromDuration(activity.durationMinutes);
    return new FormData({
        personID: new FormFieldData(activity.personID, [requiredString]),
        personCourseID: new FormFieldData(activity.personCourseID, [requiredString]),
        personCourseAssignmentID: new FormFieldData(activity.personCourseAssignmentID),        
        startDate: new FormFieldData(activity.startDate, [requiredDate]),
        hours: new FormFieldData(hoursMinutes.hours, [range(0, 23)]),
        minutes: new FormFieldData(hoursMinutes.minutes, [range(0, 59)]),              
        description: new FormFieldData(activity.description),  
    });
}
