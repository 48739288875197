import { Box, Checkbox, FormControlLabel, TextField, Typography, Stack } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState } from "react";
import globalStore from "../../../../../../app/global.store";
import { FormField } from "../../../../../../models/app/form-field";
import { PersonCourseAssignment } from "../../../../../../models/data/person-course/person-course-assignment.model";
import { setFormValue } from "../../../../../../util/form-util";
import { SaveCancelActions } from "../../../../../shared/SaveCancelActions";
import { DrawerFormBody } from "../../../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../../../layout/form-drawer/DrawerFormHeader";
import { StudentCourseContextStore } from "../student-course-context.store";
import * as service from '../../../../../../services/person-course.service';

export function StudentAssignmentEditForm(props: {
    assignment: PersonCourseAssignment, 
    store: StudentCourseContextStore,    
    
}): JSX.Element {
    const [completedDate, setCompletedDate] = useState<FormField>({value: props.assignment.completedDate});
    const dateOnChange = (newValue) => {
        setFormValue(newValue, setCompletedDate);                          
    };  
    const [pointsEarned, setPointsEarned] = useState<FormField>({value: props.assignment.pointsEarned || ''});
    const gradeOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {        
        const newValue: number | null = ['', null].includes(e.target.value) ? null : +e.target.value;
        if (newValue > (props.assignment.pointsPossible || 0)) {            
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        setFormValue(newValue, setPointsEarned);                
    };        

    const [busy, setBusy] = useState<boolean>(false);

    return (<DrawerFormContainer>
            <DrawerFormHeader>
                <Typography variant="h6">{props.assignment.title}</Typography>
            </DrawerFormHeader>            
            <DrawerFormBody>              
                <Stack spacing={2} sx={{minWidth: '275px', maxWidth: '500px'}}>
                    <Box>
                        <FormControlLabel control={
                            <Checkbox checked={!!completedDate?.value}
                                onChange={() => dateOnChange(completedDate?.value ? null : new Date())} />} label='Complete'/>                                                            
                    </Box>                
                    {completedDate?.value &&
                    <>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>                                
                                <DatePicker
                                    label="Date"
                                    value={completedDate.value}
                                    onChange={dateOnChange}
                                    renderInput={(params) => <TextField {...params}
                                    select={false}
                                    size='small'                        
                                    required
                                    sx={{mr: 2}}
                                    
                                    />}
                                />
                            </LocalizationProvider>                    
                        </Box>
                        {!!props.assignment?.pointsPossible && 
                        <Box>
                            <TextField
                                label="Score"
                                variant="outlined"                    
                                size="small"         
                                type='number'      
                                value={pointsEarned.value}     
                                onChange={(e) => {   
                                    gradeOnChange(e)                     
                                }}                 
                                sx={{width: '120px'}}                       
                            />  
                            <Typography sx={{display: 'inline-block', ml: 1}}>
                                of {props.assignment?.pointsPossible} possible
                            </Typography>
                        </Box>
                        }       
                    </>
                    }         

                    {!!props.assignment?.description?.length && 
                    <Box>
                        <Typography variant="h6">Details</Typography>
                        <Typography variant="body1">{props.assignment.description}</Typography>
                    </Box>
                    }
                </Stack>                             
            </DrawerFormBody>    
            <DrawerFormFooter>
                <SaveCancelActions 
                    saveAction={save} 
                    cancelAction={() => globalStore.drawerFormContentStackPop$.next()} 
                    saveDisabled={busy}/>
            </DrawerFormFooter>
        </DrawerFormContainer>);    
        
        function save() {
            setBusy(true);                    
            service.savePersonCourseAssignment({...props.assignment,
                completedDate: completedDate?.value,
                pointsEarned: +pointsEarned?.value || 0,
            }).subscribe({
                next: (pca) => {                
                    globalStore.popDrawerFormContent();
                    props.store.selectedAssignmentId$.next(undefined);
                    const assignments = props.store.personCourseAssignments$.value.concat();
                    const i = assignments.findIndex(a => a.id === pca.id);
                    if (i > -1) {
                        assignments[i] = pca;
                        props.store.personCourseAssignments$.next(assignments);
                    }                    
                    props.store.reloadCourse();                                        
                    globalStore.toastSubject$.next({type: 'success', message: 'Saved'});
                    setBusy(false);                    
                },
                error: () => {
                    globalStore.toastSubject$.next({type: 'error', message: 'Error'});
                    setBusy(false);
                }
            });
        };
}