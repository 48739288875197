import React, { Children } from "react";
import { View } from "@react-pdf/renderer";

const style= {
    display: 'flex', 
    flexDirection: 'row',
    marginBottom: '5pt',
    
};

export function TableRow (props: {children: JSX.Element[] | JSX.Element, style?: any}): JSX.Element {
    return (
        <View style={{...style, ...props.style}}>
            {props.children}
        </View>
    );
}