export function setFormValue(val, setter: (val) => void, validator?: (val: any) => string[]) {
    setter({value: val, errors: validator && validator(val)});    
};    

export function setFormValueMultiValidator(val, setter: (val) => void, validators?: ((val: any) => string[])[]) {
    setter({value: val, 
        errors: validators?.length 
            && validators
                .map(v => v(val))
                .reduce((errors, agg) => (agg || []).concat((errors || [])))
            });    
};    

export function notNullValidator (val): string[] {
    return !val ? ['Required'] : undefined;
}

export function hasLengthValidator (val): string[] {
    return !(val && val.length) ? ['Required'] : undefined;
}

export function maxValidator (maxValue: number, val: number): string[] {
    return +val > maxValue ? [`Maximum value ${maxValue}`] : undefined;
}

export function greaterThanZeroValidator (val: number): string[] {    
    return +val < 0 ? ['Must be greater than zero'] : undefined;
}
