import { useEffect } from "react";
import { Observable, Subject, takeUntil } from "rxjs";

export function useSubscription<T>(obs: Observable<T>, next?: (arg: T) => void, deps?: any[]) {
    useEffect(() => {
        const cleanup$ = new Subject<void>();
        obs?.pipe(takeUntil(cleanup$)).subscribe(next);
        return () => cleanup$.next();
    }, 
    deps || []);        
}