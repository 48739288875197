import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormField } from "../../../../models/app/form-field";
import { Course } from "../../../../models/data/course/course.model";
import { Person } from "../../../../models/data/person.model";
import { AcademicTerm } from "../../../../models/data/settings/academic-term";
import { getCoursesForOrganization } from "../../../../services/course.service";
import { getAcademicTerms } from "../../../../services/settings.service";
import { FormFieldSelect } from "../../../shared/FormFieldSelect";
import { SaveCancelActions } from "../../../shared/SaveCancelActions";
import { savePersonCourse } from "../../../../services/person-course.service";
import { combineLatest, forkJoin, tap } from "rxjs";
import globalStore from "../../../../app/global.store";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { StudentStore } from "./student.store";
import { PersonAcademicTerm } from "../../../../models/data/person-academic-term-model";
type CourseOptionType = {label: string, course: Course};

export function AddCourseForm(props: {student: Person, store: StudentStore, onSaved: () => void}): JSX.Element {
    const [loaded, setLoaded] = useState(false);
    const [terms, setTerms] = useState<PersonAcademicTerm[]>([]);
    const [selectedTermId, setSelectedTermId] = useState<FormField>({ value: '' });    
    const [courseOptions, setCourseOptions] = useState<CourseOptionType[]>([]);
    const [selectedCourseOption, setSelectedCourseOption] = useState<CourseOptionType | null>(null);
    const [courseOptionHasError, setCourseOptionHasError] = useState(false);


    useEffect(() => {        
        combineLatest([getCoursesForOrganization(), props.store.academicTerms$, props.store.academicTerm$]).subscribe(([c, t, selected]) => {
            setCourseOptions(c?.map((c) => {return {label: c.name, course: c}}));
            setTerms(t);
            setSelectedTermId({ value: (selected.academicTermID || (t?.length && t[0].academicTermID)) || '' })
            setLoaded(true);
        });
    }, [props.student, props.store]);

    return (
        <DrawerFormContainer width="300px">                   
            <DrawerFormHeader>
                <Typography variant="h6">Add Course</Typography>
            </DrawerFormHeader>
            <DrawerFormBody>
                {loaded &&                
                <Stack spacing={2}>
                    <Box>
                        <FormFieldSelect
                            label='Academic Term'
                            options={terms?.map(t => { return { text: t.academicTermName, value: t.academicTermID } })}
                            formField={selectedTermId}
                            setter={setSelectedTermId}
                        />
                    </Box>
                    <Box>
                        <Autocomplete            
                            size="small"                    
                            id="combo-box-demo"
                            value={selectedCourseOption}
                            options={courseOptions}                                
                            renderInput={(params) => <TextField size="small" {...params} label="Course" required error={courseOptionHasError}/>}
                            onChange={(e, v) => trySetSelectedCourseOption(v)}        
                            sx={{maxWidth: '100%'}}                                                                                                                                                                            
                        />                          
                    </Box>
                </Stack>                                                                                        
                }
                {!loaded && 
                <>
                    <Box>
                        <Skeleton />
                    </Box>
                    <Box>
                        <Skeleton />
                    </Box>
                </>                                        
                }
            </DrawerFormBody>                              
            <DrawerFormFooter>
                <SaveCancelActions saveAction={() => save()} cancelAction={() => globalStore.drawerFormContentStackPop$.next()} saveDisabled={!formValid()}/>
            </DrawerFormFooter>
        </DrawerFormContainer>  
    );

    function trySetSelectedCourseOption(value: any) {
        if (value?.course) {
            setSelectedCourseOption(value as CourseOptionType);
            setCourseOptionHasError(false);
        }
        else {
            setSelectedCourseOption(null);
            setCourseOptionHasError(true);
        }
    }

    function save() {
        formValid() &&
        savePersonCourse({
            personID: props.student.id,
            courseID: selectedCourseOption.course.id,
            courseVersion: selectedCourseOption.course.version || 0,
            academicTermID: selectedTermId.value
        }).subscribe(pc => {            
            props.onSaved();
            globalStore.drawerFormContentStackPop$.next();
        });    
    }

    function formValid(): boolean {
        return selectedCourseOption?.course && selectedTermId?.value;
    }
}