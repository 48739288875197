import { Card, CardHeader, Typography, CardContent, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Skeleton, SpeedDial, SpeedDialAction, SpeedDialIcon, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import { Subject } from "../../../../models/data/settings/subject";
import { getSubjects } from "../../../../services/settings.service";
import { Page } from "../../../shared/Page";
import Grid from "@mui/material/Unstable_Grid2";
import globalStore from "../../../../app/global.store";
import { SubjectEditForm } from "./SubjectEditForm";
import { AppButton } from "../../../shared/buttons/primary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../../app/colors";
import { CoreIndicator } from "./CoreIndicator";

export function SubjectList() {
    const [subjects, setSubjects] = useState<Subject[]>();
    const [loaded, setLoaded] = useState(false);
    const [r, reload] = useState({});

    useEffect(() => {           
        setLoaded(false);

        getSubjects().subscribe((s) =>{            
            setSubjects(s);
            setLoaded(true);
        });
    }, [r]);

    const edit = (item: Subject) => globalStore.pushDrawerFormContent(<SubjectEditForm subject={item} existing={subjects} onSaved={() => reload({})} />);

    return(
        <Page breadcrumbs={[{text: 'Settings'}, {text: 'Subjects'}]} title='Subjects'>             
            <Grid container spacing={2}>                                            
                <Grid xs={12} md={6} xl={3}>   
                    <Card>
                        <CardHeader
                            action={
                                <AppButton type='primary' 
                                    onClick={() => edit({ id: undefined, parentSubjectID: undefined, name: '', description: undefined})} 
                                    text='Add'
                                    icon={<FontAwesomeIcon icon={["fas", "plus"]}/>} />
                            }
                        />  
                        <CardContent>          
                            <Table size="small" >    
                                <TableHead>
                                    <TableRow>                        
                                        <TableCell></TableCell>                                                       
                                    </TableRow>
                                </TableHead>            
                            <TableBody >
                                {loaded &&
                                    subjects?.map(s => <SubjectRow key={s.id} item={s} level={0} />)
                                } 
                                { !loaded &&
                                [0,1,2].map((v) => 
                                <TableRow key={v}>
                                    <TableCell colSpan={2}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </TableCell>                    
                                </TableRow>)
                                }                           
                                </TableBody>
                            </Table>    
                        </CardContent>              
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );

    function SubjectRow(props: {item: Subject, level: number}): JSX.Element {
        return (
            <>
            <TableRow key={props.item.id} className='hover-grow' onClick={() => edit(props.item)}>                                                                
                <TableCell>                                                                                    
                    <div style={{marginLeft: `${(props.level * 30).toString()}px`}}>                                   
                        {props.item.name}
                        {props.item.isCore && <CoreIndicator />}    
                    </div>                                                                                                                         
                </TableCell>                                                         
            </TableRow>
            { props.item.subItems?.map(si => <SubjectRow item={si} level={props.level+1} key={si.id}/>)}
            </>
        );
    }
}
