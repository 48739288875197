import { Typography, Stack, Box, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { distinctUntilChanged, of, Subject, switchMap, take, takeUntil, tap } from "rxjs";
import globalStore from "../../../../app/global.store";
import { Activity, ActivityStatus, GradingModel, getHoursMinutesFromDuration } from "../../../../models/data/activity/activity.model";
import { PersonCourseAssignment } from "../../../../models/data/person-course/person-course-assignment.model";
import { PersonCourse } from "../../../../models/data/person-course/person-course.model";
import { Person } from "../../../../models/data/person.model";
import { deleteActivity, saveActivity } from "../../../../services/activity.service";
import { getCoursesForPerson, getPersonCourseAssignment, getPersonCourseAssignments } from "../../../../services/person-course.service";
import { getStudents } from "../../../../services/person.service";
import { Form, FormDataContext } from "../../../shared/form/Form";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { FormActions } from "../../../shared/form/FormActions";
import { FormAutocomplete } from "../../../shared/form/FormAutocomplete";
import { FormDatePicker } from "../../../shared/form/FormDatePicker";
import { makeSelectOption } from "../../../shared/form/FormSelect";
import { FormTextField } from "../../../shared/form/FormTextField";
import { FormValidator, minNumber, range, requiredDate, requiredNumber, requiredString } from "../../../shared/form/validators";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { dayjs_format, localDateString } from "../../../../util/dates";
import { useSubscription } from "../../../../hooks/useSubscription";
import { FormBool } from "../../../shared/form/FormBool";
import dayjs from "dayjs";

export function ActivityEditForm(props: {
    activity: Activity,
    showPersonAndCourse?: boolean,    
    onSaved: (activity: Activity) => void,    
}): JSX.Element {    
    const [formData, setFormData] = useState<FormData>();    

    useEffect(() => {
        setFormData(makeFormData(props.activity));
    }, [props.activity]); 
    
    
    const d = () => {
        deleteActivity(props.activity.id).subscribe(_ => {
            props.onSaved(undefined);
            globalStore.popDrawerFormContent();
            globalStore.showToast('success', 'Success');        
        });
    }

    const fields = <>
        <Box>
            <FormDatePicker fieldName="startDate" label='Date' required={true}/>                            
        </Box> 
        <Box>
            <FormTextField fieldName="hours" 
                label='Hours' required={true}                                 
                textFieldProps={{type: 'number', sx: {width: '120px', mr: 2}, fullWidth: false}} />
            <FormTextField fieldName="minutes" 
                label='Minutes' required={true}
                textFieldProps={{type: 'number', sx: {width: '120px'}, fullWidth: false}} />                            
        </Box>       
        <Box>
            <FormBool fieldName="atPrimaryLocation" label="Primary location" />
        </Box>                    
        <Box>
            <FormTextField fieldName="description" label="Notes" 
                textFieldProps={{multiline: true, minRows: 3}}
            />                            
        </Box> 
    </>;

    return (
        <DrawerFormContainer>
            <Form formData={formData}>            
                <DrawerFormHeader>
                    <Typography variant="h6">Instruction</Typography>
                </DrawerFormHeader>            
                <DrawerFormBody>              
                    <Stack spacing={2} sx={{minWidth: '275px'}}>
                        { props.activity.id 
                            ? <ActivityBasicDetail activity={props.activity} showPersonAndCourse={props.showPersonAndCourse}>{fields}</ActivityBasicDetail>
                            : <ActivityBasicDetailFormGroup activity={props.activity} showPersonAndCourse={props.showPersonAndCourse}>{fields}</ActivityBasicDetailFormGroup>
                        }
                    </Stack>                             
                </DrawerFormBody>    
                <DrawerFormFooter>
                    <FormActions     
                        saveAction={() => save()}                         
                        cancelAction={() => globalStore.popDrawerFormContent()}
                        deleteAction={props.activity?.id ? () => d() : undefined}
                        deleteConfirmation={{title: 'Confirm', text: 'Are you sure you want to delete this instruction?'}}
                        />                    
                </DrawerFormFooter>
            </Form>
        </DrawerFormContainer>);    
        
        function save() {    
            const formValue = formData.getValue();
            const toSave: Activity = {...props.activity,                 
                startDate: formValue.startDate,
                durationMinutes: ((+formValue.hours * 60) + (+formValue.minutes)),                
                status: ActivityStatus.Completed,
                description: formValue.description,
                personID: formValue.personID,
                personCourseID: formValue.personCourseID,
                personCourseAssignmentID: formValue.personCourseAssignmentID, 
                atPrimaryLocation: formValue.atPrimaryLocation,                               
            };

            const grading: GradingModel | undefined = formValue.complete ? {
                completedDate: toSave.startDate,
                pointsEarned: formValue.pointsEarned,
            } : undefined;
            
            saveActivity({activity: toSave, grading: grading}).subscribe({
                next: a => {
                    props.onSaved && props.onSaved(a);
                    globalStore.showToast('success', 'Success');                    
                    globalStore.popDrawerFormContent();
                },
                error: e => globalStore.showToast('error', 'Error saving'),
            });
        }
}

function ActivityBasicDetail(props: {activity: Activity, showPersonAndCourse?: boolean, children?: JSX.Element}) {
    const formData = useContext(FormDataContext);

    const [selectedAssignment, setSelectedAssignment] = useState<PersonCourseAssignment>(undefined);         
    useSubscription(props.activity.personCourseAssignmentID ? getPersonCourseAssignment(props.activity.personCourseAssignmentID) : of(), a => setSelectedAssignment(a), [props.activity]);

    return <>
        {props.showPersonAndCourse && <>
            <TextField label='Student' disabled value={`${props.activity.personFirstName} ${props.activity.personLastName}`} size='small' />      
            <TextField label='Course' disabled value={props.activity.courseTitle} size='small' />                                
        </>}        
        {props.activity.personCourseAssignmentID && 
            <TextField label='Assignment' disabled value={props.activity.assignmentTitle} size='small' />      
        }
        { props.children }

        {!!selectedAssignment && !selectedAssignment.completedDate && <GradingFields selectedAssignment={selectedAssignment} formData={formData}/> }
    </>;
}
function ActivityBasicDetailFormGroup(props: {activity: Activity, showPersonAndCourse?: boolean, children?: JSX.Element}) {            
    const formData = useContext(FormDataContext);

    const [students, setStudents] = useState<Person[]>(undefined);
    const [courses, setCourses] = useState<PersonCourse[]>(undefined);
    const [assignments, setAssignments] = useState<PersonCourseAssignment[]>(undefined);       
    const [selectedAssignmentID, setSelectedAssignmentID] = useState<string>(undefined);       
    const [selectedAssignment, setSelectedAssignment] = useState<PersonCourseAssignment>(undefined);       
          

    useEffect(() => {
        const teardownSubject = new Subject<void>();        
        getStudents().pipe(take(1)).subscribe(s => setStudents(s))
        return () => teardownSubject.next();
    }, [props.activity]);

    useEffect(() => {
        const td$ = new Subject<void>();
        if (formData) {
            formData.fields.personID.value$.pipe(takeUntil(td$), distinctUntilChanged(),
                tap(_ => { setAssignments(undefined); setCourses(undefined); }),
                switchMap(id => id ? getCoursesForPerson(id) : of(undefined)),
                tap(c => setCourses(c))             
            ).subscribe();

            formData.fields.personCourseID.value$.pipe(takeUntil(td$), distinctUntilChanged(),
                tap(_ => setAssignments(undefined)),
                switchMap(id => id ? getPersonCourseAssignments(id, 'abc') : of(undefined)),
                tap(a => setAssignments(a))             
            ).subscribe();

            formData.fields.personCourseAssignmentID.value$.pipe(takeUntil(td$), distinctUntilChanged(),
                tap(s => setSelectedAssignmentID(s)), 
            ).subscribe();
        }
        return () => td$.next();
    }, [formData]);

    useEffect(() => {
        setSelectedAssignment(selectedAssignmentID && assignments?.find(a => a.id === selectedAssignmentID));
    }, [selectedAssignmentID, assignments]);

    return <>
        {props.showPersonAndCourse && <>
            <FormAutocomplete fieldName="personID"
                label="Student" required={true}
                options={students?.map(s => makeSelectOption(s.id, `${s.firstName} ${s.lastName}`))}
            />
            <FormAutocomplete fieldName="personCourseID"
                label="Course" required={true} disabled={!courses}
                options={courses?.map(c => makeSelectOption(c.id, `${c.course_Name}`))}
            />    
        </>}    
        <FormAutocomplete fieldName="personCourseAssignmentID"
            label="Assignment" disabled={!assignments}
            options={assignments?.map(a => makeSelectOption(a.id, a.title))}
        />        

        { props.children }

        {!!selectedAssignment && !selectedAssignment.completedDate && <GradingFields selectedAssignment={selectedAssignment} formData={formData}/> }
    </>;
}

function GradingFields(props: { selectedAssignment: PersonCourseAssignment, formData: FormData }) {
    const [complete, setComplete] = useState(false);
    useSubscription(props.formData.fields.complete.value$, c => setComplete(c));
    return <>        
        <FormBool fieldName="complete" label="Complete" />
        {complete && <>
            {!!props.selectedAssignment.pointsPossible && <Box>
                <FormTextField fieldName="pointsEarned" 
                    label='Grade' required={true}
                    textFieldProps={{type: 'number', sx: {width: '120px'}, fullWidth: false}} />  
                    <Typography sx={{display: 'inline-block', ml: 1}}>
                        of {props.selectedAssignment.pointsPossible} possible
                    </Typography>
            </Box>}
        </>}
    </>;
}

function makeFormData(activity: Activity): FormData {          
    const hoursMinutes = getHoursMinutesFromDuration(activity.durationMinutes);
    return new FormData({
        personID: new FormFieldData(activity.personID, [requiredString]),
        personCourseID: new FormFieldData(activity.personCourseID, [requiredString]),
        personCourseAssignmentID: new FormFieldData(activity.personCourseAssignmentID),        
        startDate: new FormFieldData(activity.startDate || dayjs().format(dayjs_format), [requiredDate]),        
        hours: new FormFieldData(hoursMinutes.hours, [range(0, 23)]),
        minutes: new FormFieldData(hoursMinutes.minutes, [range(0, 60)]),
        description: new FormFieldData(activity.description),
        atPrimaryLocation: new FormFieldData(activity.atPrimaryLocation),
        complete: new FormFieldData(false),
        pointsEarned: new FormFieldData(0, 
            [(v, f) => !completeTrue(v, f) || (requiredNumber(v, f) && minNumber(0)(v, f))],
            ['complete']),
    });
}

const completeTrue = (v: any, f: FormData): boolean => !!(f.fields.complete?.value$.value);