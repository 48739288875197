import { backdropClasses } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { colors } from './colors';

export const text_color_light: string = '#303030';
export const border_light = '1px solid #a0a0a0';

export const text_color_dark: string = '#fff';
export const border_dark = '1px solid #777';


const base = {
  typography: {
    fontFamily: [
      'Inter var',
      // '-apple-system',
      // 'BlinkMacSystemFont',
      //'"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      // 'Arial',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(','),
    fontWeightRegular: 500,
  },  
};

const baseComponents = {
  MuiTypography: {
    styleOverrides: {
      h2: {fontWeight: 800, fontSize: '2.75rem'},
      h6: {fontWeight: 700, fontSize: '1.25rem'}
    }
  }
};

export const atTheme = createTheme({  
  ...base,
  palette: {    
    primary: {
      // light: will be calculated from palette.primary.main,
      main: colors.primary, //'#47B5FF',    
      dark: '#0086cb',
      light: '#85e7ff',          
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main            
    },
    background: {default: '#fff'},
  },  
  components: {
    ...baseComponents,
    MuiCard: {
      styleOverrides: { root: { 
        border: border_light, borderRadius: '12px',
        boxShadow: 'none'      
      }}
    },    
  },
});

export const atThemeDark = createTheme({   
  ...base, 
  palette: {    
    mode: 'dark',
    primary: {      
      main: '#47B5FF',
      dark: '#0086cb',
      light: '#85e7ff',      
    },    
    background: {default: '#202020'}
  },
  components: {        
    ...baseComponents,
    MuiCard: {
      styleOverrides: { root: { 
        //border: border_dark, 
        boxShadow: 'none', 
        backgroundColor: '#2e2e2e',
        backgroundImage: 'none'  } }
    },        
  }
});