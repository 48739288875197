import { BehaviorSubject, filter, interval, map, mergeMap, Observable, of, Subject, switchMap, take, takeUntil, tap } from "rxjs";
import { ToastConfig, ToastType } from "../components/pages/layout/Toast";
import { Menu } from "../models/app/menu-item";
import { User } from "../models/app/user";
import * as authService from "../services/auth.service";
import { getPerson } from "../services/person.service";
import { ConfirmDialogOptions } from "../components/shared/confirm-dialog/confirm-dialog-options";
import { decodeJWT } from "../util/jwt";

class GlobalStore {
    constructor() { 
        this.sideMenu$ = new BehaviorSubject(undefined);
        this.theme$ = new BehaviorSubject('light');
        this.user$ = new BehaviorSubject(undefined);        
        this.contextMenu$ = new BehaviorSubject(undefined);
        this.token$ = new BehaviorSubject(undefined);

        this.drawerFormContentStackPush$.subscribe(c => this.drawerFormContentStack$.next(this.drawerFormContentStack$.value.concat(c)));
        this.drawerFormContentStackPop$.subscribe(() => this.drawerFormContentStack$.next(this.drawerFormContentStack$.value.slice(0, -1)));
    }

    sideMenu$: BehaviorSubject<Menu | undefined>; 
    theme$: BehaviorSubject<'light' | 'dark'>;
    user$: BehaviorSubject<User | undefined>;
    contextMenu$: BehaviorSubject<Menu | undefined>;
    token$: BehaviorSubject<string | undefined>;

    login$(username: string, password: string): Observable<boolean> {
        return authService.login(username, password).pipe(
            switchMap(t => t 
                ? this.loginSuccess$(t).pipe(
                    switchMap(u => {
                        this.user$.next(u);
                        return of(!!u);
                    }))
                : of(false)
        ));
    }
    loginSuccess$(model: authService.TokenModel): Observable<User> {    
        const decoded = decodeJWT(model.token); 

        globalStore.token$.next(model.token);
        localStorage.setItem('token', model.token);
        localStorage.setItem('refresh', model.refresh);
            
        this.resetRefreshTokenInterval$.next();

        this.refreshTokenInterval$((decoded.exp - 60) * 1000).subscribe();
                
        return getPerson(decoded.id).pipe(
            map(p => {
                return p ? {...p, policyMap: decoded.policyMap} : undefined
            }),
            tap(u => globalStore.user$.next(u)),
        );
    }

    resetRefreshTokenInterval$ = new Subject<void>();

    toggleTheme() {
        const newVal = this.theme$.value === 'light' ? 'dark' : 'light';
        localStorage.setItem('theme', newVal);
        this.theme$.next(newVal);        
    }
    signOut() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        this.token$.next(undefined);
        this.user$.next(undefined);
    }        

    refreshTokenInterval$(expiration: number) {
        return interval(expiration - new Date().getTime()).pipe(            
            takeUntil(this.resetRefreshTokenInterval$),
            take(1),
            map(_ => localStorage.getItem('refresh')),                        
            filter(rt => !!rt),            
            switchMap(rt => authService.refreshAuthToken({token: localStorage.getItem('token'), refresh: rt })),
            switchMap(t => this.loginSuccess$(t)),
        )
    }    

    pushDrawerFormContent(c: JSX.Element) {
        this.drawerFormContentStack$.next(this.drawerFormContentStack$.value.concat(c));
    }
    popDrawerFormContent() {        
        this.drawerFormContentStack$.next(this.drawerFormContentStack$.value.slice(0, -1))
    }

    drawerFormContentStack$ = new BehaviorSubject<JSX.Element[]>([]);    
    drawerFormContentStackPush$ = new Subject<JSX.Element>();    
    drawerFormContentStackPop$ = new Subject<void>();    

    reportContent$ = new BehaviorSubject<JSX.Element>(undefined);    
    showReport(c: JSX.Element) {
        this.reportContent$.next(c);
    }
    popModalContent() {        
        this.reportContent$.next(undefined)
    }

    toastSubject$ = new Subject<ToastConfig>();     
    showToast = (type: ToastType, message: string) => this.toastSubject$.next({type, message});

    confirmDialogSubject$ = new Subject<ConfirmDialogOptions>();
    showConfirm = (options: ConfirmDialogOptions) => this.confirmDialogSubject$.next(options);
}

const globalStore = Object.freeze(new GlobalStore());
export default globalStore;

