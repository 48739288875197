import { NavigateNext, PropaneSharp } from "@mui/icons-material";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MobileContextMenuContext } from "../pages/layout/mobile-layout";
import { ContextMenu } from "../pages/layout/navigation/mobile/context-menu";

export function Page(props: { 
    title?: string | JSX.Element,
    icon?: JSX.Element, 
    breadcrumbs?: _Breadcrumb[],     
    children: JSX.Element | JSX.Element[],
    topRight?: JSX.Element,
}) {
    const navigate = useNavigate();           
    const mobileContextMenu = useContext(MobileContextMenuContext);
    
    return (
        <>                        
            { props.breadcrumbs?.length > 0 &&
            <Breadcrumbs aria-label="breadcrumb"
                separator={<NavigateNext fontSize="small" />}
                sx={{mb: 2}}>  
                {mobileContextMenu && 
                    <ContextMenu contextMenu={mobileContextMenu} />}

                { props.breadcrumbs.length > 1 &&    
                    props.breadcrumbs.map((b, i) => i < (props.breadcrumbs.length - 1) &&
                    <Link
                        key={i}
                        underline="none" color="inherit" sx={{cursor: b.url || b.backNav ? 'pointer' : undefined}}
                        onClick={() => {
                            if (b.url) {                                
                                navigate(b.url)
                            }
                            else if (b.backNav) {
                                navigate(-1);
                            }
                        }}>
                        {b.text}                        
                    </Link>)                                    
                }                                    
                <Typography color="text.primary">{props.breadcrumbs[props.breadcrumbs.length - 1].text}</Typography>
            </Breadcrumbs>   
            }            

            {props.topRight && <Box sx={{float: 'right'}}>
                    {props.topRight}
                </Box>
            }

            {props.icon && 
                <Box sx={{display: 'inline-block', mr: '12px'}}>                                                                                        
                    {props.icon}
                </Box>             
            }            
            { props.title &&
                <Typography variant='h2' sx={{mb: 2, display: 'inline-block'}}>
                    {props.title}
                </Typography>          
            }
            {            
                props.children
            }            

            {/* <FormDrawer />  */}
        </>
    );
}

export type _Breadcrumb = { text: string, url?: string, backNav?: boolean };