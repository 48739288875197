import { BehaviorSubject, combineLatest, filter, forkJoin, map, Observable, of } from "rxjs";
import { Activity } from "../../../../../models/data/activity/activity.model";
import { CourseSection } from "../../../../../models/data/course/course-section.model";
import { PersonCourseAssignment } from "../../../../../models/data/person-course/person-course-assignment.model";
import { PersonCourseSection } from "../../../../../models/data/person-course/person-course-section.model";
import { PersonCourse } from "../../../../../models/data/person-course/person-course.model";
import { Person } from "../../../../../models/data/person.model";
import { getActivityForPersonCourse } from "../../../../../services/activity.service";
import { getCourseSections } from "../../../../../services/course.service";
import { getPersonCourseAssignments, getPersonCourseById } from "../../../../../services/person-course.service";
import { getPerson } from "../../../../../services/person.service";
import { allLoaded } from "../../../../../util/store.helper";
import { reloadCourses, StudentStore } from "../student.store";

export class StudentCourseContextStore {
    person$ = new BehaviorSubject<Person>(undefined);
    personCourseID: string;
    personCourse$ = new BehaviorSubject<PersonCourse>(undefined);    
    activity$ = new BehaviorSubject<Activity[]>(undefined);
    activityLoader: () => void;
    courseSections$ = new BehaviorSubject<CourseSection[]>(undefined);
    personCourseAssignments$ = new BehaviorSubject<PersonCourseAssignment[]>(undefined);
    
    selectedAssignmentId$ = new BehaviorSubject<string>(undefined);

    studentStore: StudentStore;

    loaded$ = allLoaded([
        this.person$,
        this.personCourse$,
        this.activity$,
        this.courseSections$,
        this.personCourseAssignments$,
    ]);    
    personCourseAssignmentsAndSections$ = combineLatest([this.personCourseAssignments$, this.courseSections$]).pipe(
        filter(([a,s]) => !!a && !!s),
        map(([assignments, sections]) => 
            sections.map(s => {
                const sectionAssignments = assignments.filter(a => a.courseSectionID === s.id)
                return {...s, 
                    assignments: sectionAssignments,
                    completed: !sectionAssignments.some(sa => !sa.completedDate), 
                    type: 'section',                    
                } as PersonCourseAssignmentOrSection
            })                     
            .concat(assignments.filter(a => !a.courseSectionID).map(a => {return {...a, type: 'assignment'} as PersonCourseAssignmentOrSection}))
            .sort((a, b) => a.order - b.order),            
        )
    );
    selectedAssignment$ = combineLatest([this.personCourseAssignments$, this.selectedAssignmentId$]).pipe(
        filter(([a,s]) => !!a && !!s),
        map(([assignments, selected]) => assignments.find(a => a.id === selected)),
    );

    reloadCourse() {
        if (this.studentStore) {
            reloadCourses(this.studentStore);            
        }        
    }
}

export function initFromStudentStore(studentStore: StudentStore, personCourseID: string) {    
    const store = new StudentCourseContextStore();
    store.studentStore = studentStore;
    studentStore.student$.pipe(filter(p => !!p)).subscribe(p => {
        store.person$.next(p);
        getPersonCourseAssignments(personCourseID, p.id).subscribe(pca => {
                store.personCourseAssignments$.next(pca);                
            });
    });
    store.personCourseID = personCourseID;
    studentStore.courses$.subscribe(cs => {
        const c = cs?.find(c => c.id === personCourseID);
        store.personCourse$.next(c);
        c && getCourseSections(c.courseID).subscribe(s => store.courseSections$.next(s));
    });
    store.activityLoader = () => getActivityForPersonCourse(personCourseID).subscribe(a => store.activity$.next(a));
    store.activityLoader();
    return store;
}

export type PersonCourseAssignmentOrSection = (PersonCourseAssignment | PersonCourseSection) & {type: 'assignment' | 'section'};
