import { Button, IconButton, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useLocation, useNavigate, Location } from "react-router-dom";
import { Brand } from "../../../../shared/Brand";
import './SideMenu.scss';
import { Menu, MenuItem } from "../../../../../models/app/menu-item";
import { GlobalFunctions } from "../../../../../models/app/global-functions";
import { Menus } from "../../../../../app/menus";
import { SubMenu } from "../SubMenu/SubMenu";
import { useEffect, useState } from "react";
import globalStateStore from "../../../../../app/global.store";
import { LightMode as LightModeIcon, DarkMode as DarkModeIcon, Logout } from "@mui/icons-material";
import brandImg from '../../../../../content/accessor-edu-brand.svg';

export function SideMenu(): JSX.Element {    
    const navigate = useNavigate();   
    let location: Location = useLocation();
    const activeMenus = getActiveMenu(location);        
    const [contextMenu, setContextMenu] = useState<Menu | undefined>();
    const [themeName, setThemeName] = useState<'light' | 'dark'>();

    useEffect(() => {
        globalStateStore.contextMenu$.subscribe(cm => setContextMenu(cm));
        globalStateStore.theme$.subscribe(t => setThemeName(t));
    }, []);

    return (
        <>
            <div className="side-menu">
                {/* <img src={brandImg} style={{width: '80px', marginTop: '12px'}}/> */}
                <Brand style={{marginTop: '12px'}}/>
                <div style={{marginTop: 30}}>    
                    <Stack direction='column'>
                        {Menus.TOP.items.map(mi =>    
                            <button className={`side-menu-button ${activeMenus.activeMenu === mi ? 'active' : ''}`} 
                                style={{backgroundColor: 'transparent', cursor: 'pointer'}}
                                onClick={() => 
                                    navigate((mi.subMenu && mi.subMenu.items[0].path) || mi.path)
                                } 
                                key={mi.text} title={mi.text}>
                                <div>{mi.icon}</div>
                                <div style={{fontSize: 14}}>{mi.text}</div>

                            </button>                                                    
                        )}
                    </Stack>                                    
                </div>                      
            </div>
            { (contextMenu || activeMenus?.activeMenu?.subMenu) &&
                <SubMenu 
                    menu={contextMenu || activeMenus?.activeMenu?.subMenu}                     
                    showSignOut={!contextMenu}
                    showToggleTheme={!contextMenu}/>
            }
        </>
    );
}

function getActiveMenu(location: Location): {activeMenu?: MenuItem, activeSubmenu?: MenuItem} {
    const retval: {activeMenu?: MenuItem, activeSubmenu?: MenuItem}  = {};
    retval.activeMenu = Menus.TOP.items.find(mi => menuIsActive(mi, location));
    retval.activeSubmenu = retval.activeMenu?.subMenu && retval.activeMenu.subMenu.items.find(mi => menuIsActive(mi, location));        

    return retval;    
}
function menuIsActive(item: MenuItem, location: Location): boolean {
    let active = false;
    if (item.path === '/') {
      active = location.pathname === '/';
    }
    else {
      active = location.pathname.startsWith(item.basePath || item.path);
    }

    return active;
}