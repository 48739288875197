import { createContext, useContext, useState } from "react";import { ScheduleStore } from "../schedule.store";
import { Stack, FormControlLabel, Checkbox, Typography, Box, Divider, Chip, CircularProgress } from "@mui/material";
import globalStore from "../../../../app/global.store";
import { getAvatarColorForText } from "../../../../models/data/person.model";
import { deleteActivity, getActivity } from "../../../../services/activity.service";
import { distinctFilter } from "../../../../util/array";
import { AppAvatar } from "../../../shared/AppAvatar";
import { ActivityEditForm } from "../../activity/shared/ActivityEditForm";
import { ScheduledActivityEditForm } from "../edit-drawer/ScheduledActivityEditForm";
import { dayjs_format, minutesToHours } from "../../../../util/dates";
import { useSubscription } from "../../../../hooks/useSubscription";
import { ScheduleOrRecurrence, ScheduleViewItem } from "../../../../models/data/schedule/schedule-or-recurrence.model";
import { RecurrenceEditForm } from "../edit-drawer/RecurrenceEditForm";
import dayjs from "dayjs";
import { switchMap, tap } from "rxjs";
import { AppAvatar2 } from "../../../shared/AppAvatar2";


export const ScheduleDayDataContext = createContext<ScheduleStore>(undefined);
type ScheduledActivityGroup = { personID: string, scheduledActivity: ScheduleViewItem[], };

export function ScheduleDayList(props: {
    date: Date,    
    scheduleStore: ScheduleStore,    
    personID?: string,
    loadedCountCallback?: (count: number) => void }){    
        
    const [items, setItems] = useState<ScheduleViewItem[]>([]); 
    useSubscription(props.scheduleStore.scheduleViewItemsForDay$(props.date, props.personID), i => setItems(i), [props.date, props.scheduleStore, props.personID]);   

    return (!!items?.length &&
        <ScheduleDayDataContext.Provider value={props.scheduleStore}>
            {groupedByPerson(items).sort((a, b) => a.scheduledActivity[0].personFirstName.localeCompare(b.scheduledActivity[0].personFirstName)).map((group, i, a) => 
                                <GroupDetail group={group} key={group.personID} isFirst={i === 0}  />)
            }
        </ScheduleDayDataContext.Provider>      
    );
}

const groupedByPerson = (scheduledActivity: ScheduleViewItem[]) => scheduledActivity.map(sa => sa.personID).filter(distinctFilter).map(id => {    
    return {
        personID: id,
        scheduledActivity: scheduledActivity.filter(sa => sa.personID === id),        
    } as ScheduledActivityGroup
});

const GroupDetail = (props: {group: ScheduledActivityGroup, isFirst: boolean }) => {        
    const group = props.group;
    return (
        <Box key={group.personID} sx={{mb: 1}}>
            <Divider textAlign="center">
                <Stack direction={'row'} spacing={1.5} sx={{mb: 1}}>
                    <AppAvatar2 avatarDefinition={group.scheduledActivity[0].personAvatar} size="24px" fontSize="12px" />
                    {/* <PersonAvatar sa={group.scheduledActivity[0]} />  */}
                    <span>{`${group.scheduledActivity[0].personFirstName}`}</span>
                </Stack>                
                </Divider>            
            <Stack spacing={1} sx={{mt: 0, ml: '0px'}}>
                {group.scheduledActivity.map(sa => <SAItem sa={sa} key={sa.id} />)}
            </Stack>
        </Box>);
};


const SAItem = (props: { sa: ScheduleViewItem }) => {
    const store = useContext(ScheduleDayDataContext);        
    const handleCheckClicked = () => {
        if (!props.sa?.completedActivityID) {
            globalStore.pushDrawerFormContent(
                <ActivityEditForm showPersonAndCourse={true}
                    activity={{ 
                        startDate: dayjs(props.sa.date).format(dayjs_format),                         
                        personID: props.sa.personID, 
                        personCourseID: props.sa.personCourseID, 
                        personCourseAssignmentID: props.sa.personCourseAssignmentID, 
                        durationMinutes: props.sa.durationMinutes,
                        scheduledActivityID: !props.sa.isRecurrence ? props.sa.id : undefined,
                        scheduledActivityRecurringID: props.sa.isRecurrence ? props.sa.id : undefined,
                        atPrimaryLocation: true,
                     }} 
                    onSaved={() => store.refresh()} />)
        }
        else {
            globalStore.showConfirm({
                title: 'Confirm',
                body: 'Are you sure you want to delete this instruction?',
                confirmAction: () => {                    
                    deleteActivity(props.sa.completedActivityID).pipe(                
                        tap(_  => store.refresh()),                        
                    ).subscribe();
                }
            });
        }
    };

    return (
        <Stack direction={'row'}>             
            <Checkbox checked={!!props.sa.completedActivityID}                
                color="success"
                size="medium"                
                onClick={handleCheckClicked} 
                sx={{pl: 0}}/>
            
            <Box fontSize={'small'} className='pointer hover-underline'
                onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();                    
                    globalStore.drawerFormContentStackPush$.next(
                        props.sa.isRecurrence 
                        ? <RecurrenceEditForm scheduleRecurrence={props.sa} onSaved={() => store.refresh()} showPersonAndCourse={true}/>
                        : <ScheduledActivityEditForm scheduleActivity={props.sa} onSaved={saved => store.refresh()} showPersonAndCourse={true}/>
                    )                    
                }}>
                <b>{props.sa.courseName}</b>                        
                {!!props.sa.assignmentTitle &&
                    <div>{props.sa.assignmentTitle}</div>
                }
                <div>{minutesToHours(props.sa.completedActivityID ? props.sa.completedDurationMinutes : props.sa.durationMinutes, 1)} hrs</div>
            </Box>   
        </Stack>        
        );
}