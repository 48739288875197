import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useState } from "react";
import { ConfirmDialogOptions } from "./confirm-dialog-options";
import { useSubscription } from "../../../hooks/useSubscription";
import globalStore from "../../../app/global.store";
import { AppButton } from "../buttons/primary";

export function ConfirmDialog() {  
  const [options, setOptions] = useState<ConfirmDialogOptions>();
  useSubscription(globalStore.confirmDialogSubject$, o => setOptions(o));

  const handleAction = (f?: () => void) => {
    setOptions(undefined);
    f && f();
  }

  return ( !!options &&
      <Dialog
          open={!!options}
          onClose={() => setOptions(undefined)}>
      <DialogTitle id="alert-dialog-title">
        {options?.title}
      </DialogTitle>
      <DialogContent sx={{minWidth: 250}}>
        <DialogContentText id="alert-dialog-description">
          {options?.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>        
        <AppButton onClick={() => handleAction(options.cancelAction)}
          text={options.cancelButtonText || 'Cancel'}
          type='neutral'
        />             
        <AppButton onClick={() => handleAction(options.confirmAction)}
          text={options.confirmButtonText || 'Ok'}          
        />                        
      </DialogActions>
    </Dialog>
  );
}

