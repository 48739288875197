import { ScheduledActivity } from "../models/data/schedule/scheduled-activity.model";
import { apiGet, apiPost } from "./api-service";
import { ScheduledActivityRecurring } from "../models/data/schedule/scheduled-activity-recurring.model";

export const saveScheduledActivity = (scheduledActivity: ScheduledActivity) =>
    apiPost<ScheduledActivity>('schedule/save', scheduledActivity);

export const getScheduledActivity = (startDate: string, endDate: string, students?: string[]) =>
    apiPost<ScheduledActivity[]>(`schedule`, {startDate, endDate, students});    

export const saveScheduledActivityRecurring = (scheduledActivityRecurring: ScheduledActivityRecurring) =>   
    apiPost<ScheduledActivityRecurring>('schedule/recurring/save', scheduledActivityRecurring);

export const getScheduledActivityRecurring = (startDate: string, endDate: string, students?: string[]) =>
    apiPost<ScheduledActivityRecurring[]>(`schedule/recurring`, {startDate, endDate, students});
