import { combineAll, combineLatest, combineLatestAll, filter, map, Observable, shareReplay, Subject as RxjsSubject, switchMap, take, tap } from "rxjs";
import globalStore from "../app/global.store";
import { AcademicLevel } from "../models/data/settings/academic-level";
import { AcademicTerm } from "../models/data/settings/academic-term";
import { Subject } from "../models/data/settings/subject";
import { apiGet, apiPost } from "./api-service";

export function getAcademicLevels(): Observable<AcademicLevel[]> {
    return apiGet<AcademicLevel[]>('settings/academicLevels');
}

export function getSubjects(): Observable<Subject[]> {
    return apiGet<Subject[]>('settings/subjects');
}

export function saveSubject(subject: Subject): Observable<Subject> {
    return apiPost<Subject>('settings/subjects/save', subject);
}

export function getAcademicTerms(): Observable<AcademicTerm[]> {
    return apiGet('settings/academicterms');
}

export function saveAcademicTerm(academicTerm): Observable<AcademicTerm> {    
    return apiPost<AcademicTerm>('settings/saveacademicterm', academicTerm);
}
