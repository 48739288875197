import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, CardContent, TableContainer, Table, TableRow, TableHead, TableCell, TableBody, TablePagination, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { Activity, ActivitySearchParams } from "../../../../models/data/activity/activity.model";
import { shortDate } from "../../../../util/dates";
import {minutesToHours} from '../../../../util/dates';
import { searchActivity, searchActivityCount, searchActivity_Print, searchActivity_csv } from "../../../../services/activity.service";
import { debounceTime, Subject, take, tap } from "rxjs";
import globalStore from "../../../../app/global.store";
import { ActivityEditForm } from "../shared/ActivityEditForm";
import { ActivityBasicTableSearch } from "./ActivityBasicTableSearch";
import { useSubscription } from "../../../../hooks/useSubscription";
import { DESKTOP_BREAKPOINT } from "../../../../app/constants";
import { CardActionButtons } from "../../../shared/CardActionButtons";
import { AppAvatar2 } from "../../../shared/AppAvatar2";

export function ActivityBasicTable(): JSX.Element {
    const [items, setItems] = useState<Activity[]>();
    const [search, setSearch] = useState<ActivitySearchParams>();
    const [searchFormSubmit$] = useState(new Subject<ActivitySearchParams>());
    const [pageChanged$] = useState(new Subject<number>());
    const [totalCount, setTotalCount] = useState(-1);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25); 
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState({});

    const isMobile = !useMediaQuery(DESKTOP_BREAKPOINT);

    useEffect(() => {
        if (search) {
            setLoading(true);

            searchActivity({...search, page: page, pageSize: pageSize}).pipe(take(1)).subscribe(r => {
                setItems(r);                 
                setLoading(false);
            });

            if (totalCount < 0) {
                searchActivityCount(search).subscribe(c => setTotalCount(c));
            }
        }
    }, [search, pageSize, page, reload]);

    useEffect(() => search && setSearch({...search, pageSize: pageSize}), [pageSize]);

    useSubscription(searchFormSubmit$.pipe(debounceTime(500)), s => {
        setPage(0);
        setTotalCount(-1);
        setSearch(s);        
    }, [searchFormSubmit$]);

    useSubscription(pageChanged$.pipe(tap(p => setPage(p)),debounceTime(200)), p => {        
    }, [pageChanged$]);

    return(<>
    <Card sx={{position: 'relative'}}>
        <CardHeader 
            title={<ActivityBasicTableSearch activitySearchSubmit$={searchFormSubmit$}/>}
             action={ <CardActionButtons buttons={[
                { text: 'Add', icon: <FontAwesomeIcon icon={["fas", "plus"]}/>, action: () => globalStore.drawerFormContentStackPush$.next(<ActivityEditForm activity={{atPrimaryLocation: true}} onSaved={(a) => addItem(a)} showPersonAndCourse={true}/>)},
                { text: 'Export', icon: <FontAwesomeIcon icon={["fas", "file-csv"]}/>, action: () => csv()},
             ]}/>
            }
        />
        <CardContent>                            
            <TableContainer
                // sx={{maxHeight: 500}}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width={'100px'} sx={{fontWeight: 'bold'}}>Date</TableCell>
                            <TableCell width={'200px'} sx={{fontWeight: 'bold'}}>Student</TableCell>
                            <TableCell width={'225px'} sx={{fontWeight: 'bold'}}>Course</TableCell>
                            <TableCell width={'225px'} sx={{fontWeight: 'bold'}}>Assignment</TableCell>
                            <TableCell width={'80px'} sx={{fontWeight: 'bold'}}>Hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items?.map(i => 
                            <TableRow key={i.id} className='hover-grow'
                                onClick={() => globalStore.drawerFormContentStackPush$.next(
                                    <ActivityEditForm activity={i} showPersonAndCourse={true} 
                                        onSaved={(a: Activity) => a ? updateItem(a) : setReload({})}/>)
                                }>
                                <TableCell>{shortDate(i.startDate)}</TableCell>
                                <TableCell>
                                    {i.personAvatar &&
                                        <span style={{display:'inline-block', marginRight: '4px'}}>
                                            <AppAvatar2
                                                avatarDefinition={i.personAvatar}
                                                size='26px' fontSize="13px"/>
                                        </span> } 
                                    <span>{i.personFirstName} {i.personLastName}</span>                                        
                                    </TableCell>
                                <TableCell>
                                    {i.courseAvatar &&
                                        <span style={{display:'inline-block', marginRight: '4px'}}>
                                            <AppAvatar2
                                                avatarDefinition={i.courseAvatar}
                                                size='26px' fontSize="13px"/>
                                        </span> } 
                                    <span>{i.courseTitle}</span>
                                </TableCell>
                                <TableCell>{i.assignmentTitle}</TableCell>
                                <TableCell>{minutesToHours(i.durationMinutes, 1)}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>                    
                </Table>
                {totalCount > -1 && 
                    <TablePagination component='div' 
                        page={page} 
                        count={totalCount} 
                        onPageChange={(_, p) => pageChanged$?.next(p)} 
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={(e) => setPageSize(+e.target.value)}
                        rowsPerPageOptions={isMobile ? [] : undefined}
                        SelectProps={{
                            disabled: loading
                          }}
                          backIconButtonProps={{
                                disabled: page <= 0 || loading
                            }}
                          nextIconButtonProps={{
                            disabled: loading || (totalCount > -1 && ((page + 1) * pageSize) >= totalCount)
                        }}                        
                        >
                    </TablePagination>
                }
            </TableContainer>
        </CardContent>                
    </Card>
    </>);

    function print() {
        searchActivity_Print(search).subscribe({
            next: file => {
                if (file) {
            var fileURL = window.URL.createObjectURL(file);
            let tab = window.open();
            tab.location.href = fileURL;
        }}});
    }

    function csv() {
        searchActivity_csv(search).subscribe({
            next: file => {                                              
                var fileURL = window.URL.createObjectURL(file);                
                window.location.href = fileURL;
            }
        });
    }

    function updateItem(item: Activity) {
        const newItems = items.concat();
        const i = newItems.findIndex(i => i.id === item.id);
        if (i !== -1) {
            newItems[i] = item;
            setItems(newItems)            
        }
    }
    function addItem(item: Activity) {        
        const newItems = items.concat([item]).sort((a,b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
        setItems(newItems);
    }
}