import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Subject, shareReplay } from "rxjs";
import { useSubscription } from "../../../../hooks/useSubscription";
import { ActivitySearchParams } from "../../../../models/data/activity/activity.model";
import { AcademicTerm } from "../../../../models/data/settings/academic-term";
import { Form } from "../../../shared/form/Form";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { dayjs_format } from "../../../../util/dates";
import { FormDatePicker } from "../../../shared/form/FormDatePicker";
import { FormValidator } from "../../../shared/form/validators";
import { Person } from "../../../../models/data/person.model";
import { getStudents } from "../../../../services/person.service";
import dayjs from "dayjs";
import { PersonSelectionChips } from "../../../shared/PersonSelectionChips";

export function ActivityBasicTableSearch(props: {
    activitySearchSubmit$: Subject<ActivitySearchParams>
}) {
    const [formData, setFormData] = useState<FormData>();
    const [academicTerms, setAcademicTerms] = useState<AcademicTerm[]>([]);
    const [filterType, setFilterType] = useState('dates');
    const [endDateMin, setEndDateMin] = useState<string | undefined>(undefined);

    const [students$] = useState(getStudents().pipe(shareReplay(1)));

    // const [students, setStudents] = useState<Person[]>(undefined);
    // const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

    useEffect(() => {
        students$.subscribe(s => setFormData(makeFormData(s)));        
    }, [students$]);

    // useEffect(() => formData && submit(), [formData, selectedStudents]);

    // useSubscription(getAcademicTerms(), setAcademicTerms);    

    useSubscription(formData?.fields.startDate.value$, v => { 
        setEndDateMin(v || undefined);
        const end = new Date(formData.fields.endDate.value$.value);
        end && end.getTime() < new Date(v).getTime() && formData.fields.endDate.value$.next(v);
    }, [formData]);
    
    useSubscription(formData?.fields.students.value$, _ => submit(), [formData])

    const submit = () => {
        props.activitySearchSubmit$.next(makeSearch(formData));
    };
    
    return (<>
        <Form formData={formData}>            
            {/* <Stack direction={'row'} spacing={2}>
                <Box width={'120px'}>
                    <FormSelect 
                        label='Filter'
                        fieldName="filterType"                 
                        fullWidth={false}   
                        options={['', 
                            //'Term', 
                            'Dates'
                        ].map(f => makeSelectOption(f.toLowerCase(), f))}     
                        onChange={() => submit()}               
                    />
                </Box>                
            </Stack> */}
            <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                {/* {filterType === 'term' && <Box width={'250px'} sx={{mt: 1.5}}>
                    <FormSelect 
                        label='Term'
                        fieldName="academicTermId"                 
                        fullWidth={false}   
                        options={[{id: '', name: ''} as AcademicTerm].concat(academicTerms).map(at => makeSelectOption(at.id, at.name))}
                        onChange={() => submit()}                                      
                    />
                </Box>} */}
                {filterType === 'dates' && <>
                    <Box width='200px'sx={{mt: 1.5}}>
                        <FormDatePicker fieldName="startDate" label='Start' 
                            onChange={() => submit()} />
                    </Box>
                    <Box width='200px' sx={{mt: 1.5}}>
                        <FormDatePicker fieldName="endDate" label='End' minDate={endDateMin} 
                            onChange={() => submit()} />
                    </Box>
                </>}
            </Box>
            <PersonSelectionChips selected$={formData?.fields.students.value$} persons$={students$}/>            
        </Form>
    </>);   
}

function makeFormData(students: Person[]): FormData {          
    const form = new FormData({
        startDate: new FormFieldData(dayjs().startOf('week').format(dayjs_format)),
        endDate: new FormFieldData(dayjs().endOf('week').format(dayjs_format), [endDateValidator]),
        students: new FormFieldData(students.map(s => s.id)),     
    });

    return form;
}

const endDateValidator: FormValidator = (end, form: FormData) => {
    const start = form.fields.startDate.value$?.value;
    return !end || !start || new Date(start).getTime() <= new Date(end).getTime();
}

function makeSearch(form: FormData): ActivitySearchParams {    
    const formValue = form.getValue();

    return {
        pageSize: 20,
        page: 0,
        academicTermId: undefined,
        startDate: formValue.startDate || undefined,
        endDate: formValue.endDate || undefined,
        students: formValue.students, 
    };    
}