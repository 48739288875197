import { TextField, TextFieldProps, useAutocomplete } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { FormFieldData } from "./form-data";
import { FormDataContext } from "./Form";

export function FormTextField(props: {
    fieldName: string, 
    label: string,    
    required?: boolean,    
    textFieldProps?: TextFieldProps,
    maxLength?: number,
}) {
    const formData = useContext(FormDataContext);
    const [field, setField] = useState<FormFieldData>(undefined);
    const [value, setValue] = useState<any>('');
    const [hasError, setHasError] = useState<boolean>(false);

    const onChange =(e) => {        
        field.value$.next(e.target.value);
        props.textFieldProps?.onChange
            && props.textFieldProps.onChange(e);
    };
    const onTouch = () => field.touched$.next(true);

    useEffect(() => {
        const cleanup$ = new Subject<void>();
        
        if (formData) {
            if (!(props.fieldName in formData.fields)) throw Error('Key not found in formData: ' + props.fieldName);
            const field = formData.fields[props.fieldName];
            field.value$.pipe(takeUntil(cleanup$)).subscribe(val => setValue([undefined, null].includes(val) ? '' : val));
            field.isValid$().pipe(takeUntil(cleanup$)).subscribe(valid => setHasError(!valid));            
            setField(field);
        }

        return () => cleanup$.next();
    }, [props.fieldName, formData]);

    return (field 
        ? <TextField              
            variant="outlined" 
            size="small" 
            fullWidth
            label={props.label}
            required={!!props.required}
            {...props.textFieldProps}
            value={value}
            error={hasError}
            onChange={e => onChange(e)}
            onBlur={_ => onTouch()}            
            inputProps={{autoComplete: 'off', maxLength: props.maxLength }}/>             
        : <></>
    );
}