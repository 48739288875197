import { Card, CardHeader, Typography, CardContent, TextField, FormControl, InputLabel, Select, MenuItem, CardActions, Skeleton, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { FormField } from "../../../../../models/app/form-field";
import { CourseAssignment } from "../../../../../models/data/course/course-assignment.model";
import { AssignmentType, displayAssignmentType } from "../../../../../models/data/enums/assignment-types";
import { deleteAssignment, getCourseSections, saveCourseAssignment } from "../../../../../services/course.service";
import { setFormValue, hasLengthValidator, notNullValidator, greaterThanZeroValidator } from "../../../../../util/form-util";
import { SaveCancelActions } from "../../../../shared/SaveCancelActions";
import Grid from "@mui/material/Unstable_Grid2";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { FormData, FormFieldData } from "../../../../shared/form/form-data";
import { minNumber, requiredNumber, requiredString } from "../../../../shared/form/validators";
import { Form } from "../../../../shared/form/Form";
import { FormTextField } from "../../../../shared/form/FormTextField";
import { FormSelect, makeSelectOption } from "../../../../shared/form/FormSelect";
import { FormBool } from "../../../../shared/form/FormBool";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { FormActions } from "../../../../shared/form/FormActions";
import { CourseSection } from "../../../../../models/data/course/course-section.model";
import globalStore from "../../../../../app/global.store";

export function AssignmentDetail(props: {
    assignment: CourseAssignment,
    onSaved: (assignment: CourseAssignment) => void,
    onError: () => void,
    onCanceled: () => void,
}): JSX.Element {
    const [formData, setFormData] = useState<FormData>();
    const [gradedChecked, setGradedChecked] = useState(false);
    const [busy, setBusy] = useState(false);
    
    const [courseSections, setCourseSections] = useState<CourseSection[]>();
    useSubscription(getCourseSections(props.assignment.courseID), s => setCourseSections(s), [props.assignment]);

    useEffect(() => setFormData(makeForm(props.assignment)), [props.assignment]);
    useSubscription(formData?.fields.graded.value$, v => {
        setGradedChecked(v);
        if (v) {
            !formData.fields.pointsPossible.value$?.value && 
                formData.fields.pointsPossible.value$.next(100);
        }
        else {
            formData.fields.pointsPossible.value$.next(0);
        }
    }, [formData]);

    const d = () =>         
        deleteAssignment(props.assignment).subscribe({
            next: _ => {                
                props.onSaved(undefined);
                globalStore.showToast('success', 'Deleted successfully');
            },
            error: _ => globalStore.showToast('error', 'Error deleting'), 
        });

    return ( formData &&
        <>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <Card>
                        <CardHeader title={
                            <Typography component="h6" variant="h6">
                                <IconButton size="small" onClick={props.onCanceled} sx={{mt: '-4px', mr: '10px'}}>
                                    <ArrowBackIcon />
                                </IconButton>                                
                                {/* Assignment */}
                            </Typography>
                        }
                        />
                        <CardContent>
                            <Form formData={formData}>
                                <Grid container spacing={2}>
                                    <Grid xs={12} xl={6}>
                                        <FormTextField fieldName="title" label='Title' required={true} />
                                    </Grid>                                    
                                    <Grid xs={6} lg={3}>
                                        <FormSelect fieldName="assignmentType" label='Type' required={true}
                                                options={[
                                                    AssignmentType.Lesson,
                                                    AssignmentType.Project,
                                                    AssignmentType.Quiz,
                                                    AssignmentType.Exam,
                                                ].map(v => makeSelectOption(+v, displayAssignmentType(v)))} />                                        
                                    </Grid>
                                    <Grid xs={6} lg={3}>
                                        <FormSelect fieldName="courseSectionID" label='Section'                                            
                                            options={courseSections && [undefined].concat(courseSections).map(cs => cs && makeSelectOption(cs.id, cs.name))} />                                   
                                    </Grid>
                                    <Grid xs={12} lg={3}>
                                        
                                        <FormBool fieldName="graded" label='Graded' />                                            
                                        
                                        {gradedChecked && 
                                            <FormTextField fieldName="pointsPossible" label='Points Possible' 
                                                textFieldProps={{
                                                    type: 'number',
                                                    sx: {width: 120}  
                                                }}/>                                            
                                        }                                        
                                    </Grid>   
                                    <Grid xs={12}>
                                        <FormTextField fieldName="description" label='Details'
                                            textFieldProps={{
                                                multiline: true,
                                                minRows: 3}} />                                
                                    </Grid>                                 
                                </Grid>
                                <CardActions sx={{ mt: 2, float: 'right', width: '100%' }}>                                    
                                    <FormActions saveAction={() => save()} cancelAction={() => props.onCanceled()} 
                                        deleteAction={props.assignment?.id ? d : undefined}  
                                        deleteConfirmation={{title: 'Confirm', text: 'Are you sure you want to delete this assignment?'}}/>
                                </CardActions>
                            </Form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );

    function save() {
        const formValue = formData.getValue();
        delete formValue.checked;
        saveCourseAssignment({
            ...props.assignment,
            ...formValue,            
            courseSectionID: formValue.courseSectionID || undefined,
        }).subscribe(
            {
                next: a => {
                    props.onSaved(a);
                },
                error: e => {
                    props.onError();
                }
            }
        );
    }
}

function makeForm(assignment: CourseAssignment) {
    return new FormData({
        title: new FormFieldData(assignment.title, [requiredString]),
        description: new FormFieldData(assignment.description),
        assignmentType: new FormFieldData(assignment.assignmentType || +AssignmentType.Lesson, [minNumber(1)]),
        pointsPossible: new FormFieldData(assignment.pointsPossible || 0, [minNumber(0)]),
        graded: new FormFieldData(!!assignment.pointsPossible),
        courseSectionID: new FormFieldData(assignment.courseSectionID),
    });
}
