import { Card, CardHeader, Typography, CardContent, Grid, CardActions, Stack, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Person } from "../../../../../models/data/person.model";
import { cloneElement, useState } from "react";
import { PersonAcademicTerm } from "../../../../../models/data/person-academic-term-model";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { getPersonAcademicTerms, savePersonAcademicTerm } from "../../../../../services/person.service";
import { AppButton } from "../../../../shared/buttons/primary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStore from "../../../../../app/global.store";
import { AcademicTerm } from "../../../../../models/data/settings/academic-term";
import { AcademicLevel } from "../../../../../models/data/settings/academic-level";
import { Observable, combineLatest, shareReplay } from "rxjs";
import { getAcademicLevels, getAcademicTerms } from "../../../../../services/settings.service";
import { DrawerFormContainer } from "../../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormHeader } from "../../../layout/form-drawer/DrawerFormHeader";
import { Form } from "../../../../shared/form/Form";
import { FormActions } from "../../../../shared/form/FormActions";
import { FormSelect, SelectOption, makeSelectOption } from "../../../../shared/form/FormSelect";
import { DrawerFormBody } from "../../../layout/form-drawer/DrawerFormBody";
import { DrawerFormFooter } from "../../../layout/form-drawer/DrawerFormFooter";
import { FormData, FormFieldData } from "../../../../shared/form/form-data";
import { requiredString } from "../../../../shared/form/validators";


export function PersonProfileAcademicTerms(props: {
  person: Person,
}) {
  const [terms, setTerms] = useState<PersonAcademicTerm[]>([]);  
  const [reload, setReload] = useState<any>({});

  useSubscription(getPersonAcademicTerms(props.person.id), t => setTerms(t), [reload]);

  const academicTerms$ = getAcademicTerms().pipe(shareReplay());
  const academicLevels$ = getAcademicLevels().pipe(shareReplay());
  
  const addNew = () => edit({personID: props.person.id});
  const edit = (term: PersonAcademicTerm) => globalStore.pushDrawerFormContent(
    <PersonAcademicTermEditForm 
      term={term} 
      academicLevels$={academicLevels$} academicTerms$={academicTerms$} 
      onSave={() => setReload({})} />)

  return <>
    <Card> {!!terms && <>
      <CardHeader title={
        <Typography component="h6" variant="h6">
          Academic Terms
        </Typography>
      }
        action={
          <AppButton size="medium" icon={<FontAwesomeIcon icon={['fas', 'calendar-plus']} />} text='Add Term'
            onClick={() => addNew()} />
        }
      />
      <CardContent>
        {!!terms?.length &&
          <Table size="small" >    
              <TableHead>
                <TableRow>                        
                    <TableCell>Term</TableCell>                                                       
                    <TableCell>Grade</TableCell>                                                       
                </TableRow>
            </TableHead>              
            <TableBody >
              {terms.map(t =>                             
                <TableRow key={t.id} className='hover-grow' onClick={() => edit(t)}>                                                                
                    <TableCell>                                                                               
                      {t.academicTermName}
                    </TableCell>          
                    <TableCell>                                                                               
                      {t.academicLevelName}
                    </TableCell>                                                         
                </TableRow>
            )}
            </TableBody>
          </Table>             
        }
      </CardContent></>}
    </Card>
  </>;
}


function PersonAcademicTermEditForm(props: {
  term: PersonAcademicTerm,   
  academicTerms$: Observable<AcademicTerm[]>, 
  academicLevels$: Observable<AcademicLevel[]>, 
  onSave: () => void
}) {
  
  const [termOptions, setTermOptions] = useState<SelectOption[]>();
  const [levelOptions, setLevelOptions] = useState<SelectOption[]>();  

  useSubscription(combineLatest([props.academicTerms$, props.academicLevels$]), ([ts, ls]) => {
    setTermOptions(ts?.map(t => makeSelectOption(t.id, t.name)) || []);
    setLevelOptions(ls?.map(l => makeSelectOption(l.id, l.name)) || []);
  });

  const formData = new FormData({
    academicTermID: new FormFieldData(props.term.academicTermID, [requiredString]),
    academicLevelID: new FormFieldData(props.term.academicLevelID, [requiredString]),
  });

  const save = () => {
    savePersonAcademicTerm({...props.term, ...formData.getValue()}).subscribe(_ => { props.onSave(); close();})
  };
  const close = () => globalStore.popDrawerFormContent();

  return <DrawerFormContainer>
  <Form formData={formData}>            
      <DrawerFormHeader>
          <Typography variant="h6">Student Academic Term</Typography>
      </DrawerFormHeader>            
      <DrawerFormBody>              
          <Stack spacing={2} sx={{minWidth: '275px'}}>              
              <FormSelect                         
                  label='Term'
                  fieldName="academicTermID"                 
                  fullWidth={false}   
                  options={termOptions}                         
              />
              <FormSelect                         
                  label='Grade'
                  fieldName="academicLevelID"                 
                  fullWidth={false}   
                  options={levelOptions}                         
              />
          </Stack>                             
      </DrawerFormBody>    
      <DrawerFormFooter>
          <FormActions     
              saveAction={() => save()}                         
              cancelAction={() => close()}/>                    
      </DrawerFormFooter>
  </Form>
</DrawerFormContainer>;
}