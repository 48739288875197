import { Card, CardHeader, Typography, CardContent, CardActions, IconButton, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Page, _Breadcrumb } from "../../../../shared/Page";
import { Person } from "../../../../../models/data/person.model";
import { RoleType } from "../../../../../models/data/enums/role-types";
import { getPerson, savePerson } from "../../../../../services/person.service";
import { AcademicLevel } from "../../../../../models/data/settings/academic-level";import { getAcademicLevels } from "../../../../../services/settings.service";
import { FormData, FormFieldData } from "../../../../shared/form/form-data";
import globalStore from "../../../../../app/global.store";
import { Form } from "../../../../shared/form/Form";
import { FormTextField } from "../../../../shared/form/FormTextField";
import { LoaderPage } from "../../../../shared/LoaderPage";
import { FormDatePicker } from "../../../../shared/form/FormDatePicker";
import { FormSelect, makeSelectOption } from "../../../../shared/form/FormSelect";
import { FormActions } from "../../../../shared/form/FormActions";
import { requiredString, requiredDate } from "../../../../shared/form/validators";
import { PersonProfileAcademicTerms } from "./PersonProfileAcademicTerms";
import { CardActionButtons } from "../../../../shared/CardActionButtons";
import { ChangePassword } from "./ChangePassword";
import { Edit, Key } from "@mui/icons-material";
import { randomMaterialColor } from "../../../../../util/color";
import { AvatarDefinition } from "../../../../../models/data/shared/avatar-definition";
import { AppAvatar2 } from "../../../../shared/AppAvatar2";
import { AvatarEditForm } from "../../../../shared/AvatarEditForm";
import { useSubscription } from "../../../../../hooks/useSubscription";

export function PersonProfile(): JSX.Element {            
    const { id } = useParams();
    const location = useLocation();    
    const locationState = location.state as any;
    const [academicLevels, setAcademicLevels] = useState<AcademicLevel[]>(); 
    const [person, setPerson] = useState<Person>(null);
    const [loaded, setLoaded] = useState(false);    

    const [formData, setFormData] = useState<FormData>();

    const init = (p: Person) => {
        setPerson(p);                
        setLoaded(true);

        setFormData(createFormData(p));
    };
    const save = () => {                        
        const formValue = formData.getValue();
        const toSave = {
            ...person,                     
            ...formValue,
            dateOfBirth: formValue.dateOfBirth || undefined,                                     
        };     
        savePerson(toSave).subscribe({
            next: saved => {globalStore.showToast('success', 'Saved'); setPerson(saved); init(saved); formData.setIsBusy(false);},
            error: e => {globalStore.showToast('error', 'Error saving')},
        });         
    }    

    const [avatar, setAvatar] = useState<AvatarDefinition>();
    useSubscription(formData?.fields.avatar.value$, a => setAvatar(a), [formData]);

    useSubscription(formData?.fields.firstName.value$, n => {        
        if (avatar && !avatar.icon && !avatar.text && !!n?.length) {
            formData.fields.avatar.value$.next({...avatar, text: n[0]});
        }
    }, [formData]);

    useEffect(() => {                  
        getAcademicLevels().subscribe(al => setAcademicLevels([null].concat(al)));        
        id === 'add' && init(locationState.person);;
        id !== 'add' && getPerson(id).subscribe(p => init(p));               
    }, [id]);
        

    const title: string = 
        person?.id 
            ? `${person.firstName} ${person.lastName}` 
            : locationState?.person?.roleType === RoleType.Parent
            ? 'Add Parent'
            : locationState?.person?.roleType === RoleType.Student 
            ? 'Add Student'
            : '';
    
    return (<>
        {loaded && formData && person &&
        <Page breadcrumbs={[{text: 'Settings'}, {text: 'Household', backNav: true}, {text: title}]}>      
              <Grid container spacing={2}>
                <Grid xs={12} lg={6}>   
                    <Card>
                        <CardHeader title={
                            <Typography component="h6" variant="h6">
                                Personal
                            </Typography>
                        }
                        action={person?.id && person.id === globalStore.user$.value?.id && <CardActionButtons buttons={[
                            {   
                                text: 'Change Password', 
                                icon: <Key />,
                                action: () => globalStore.pushDrawerFormContent(<ChangePassword />)}
                        ]} />}
                        />  
                        <CardContent>          
                            <Form formData={formData}>
                                <Grid container spacing={2}>                        
                                    <Grid xs={12} sm={6} md={4}>
                                        <FormTextField fieldName="firstName"
                                            label='First Name'
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4}>
                                        <FormTextField fieldName="middleName"
                                            label='Middle Name'                                    
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4}>
                                        <FormTextField fieldName="lastName"
                                            label='Last Name'
                                            required={true}
                                        />
                                    </Grid>    
                                    <Grid xs={12} sm={6} md={4}>
                                        { avatar &&
                                            <Stack direction={'row'} spacing={1}>
                                                <AppAvatar2 avatarDefinition={avatar} size="40px" fontSize="20px" />
                                                <IconButton 
                                                    onClick={() => globalStore.pushDrawerFormContent(
                                                        <AvatarEditForm avatar={avatar} 
                                                            onSaved={a => {
                                                                formData.fields.avatar.value$.next(a);
                                                                globalStore.popDrawerFormContent();
                                                            }} 
                                                        />)
                                                    }>
                                                    <Edit />
                                                </IconButton>
                                            </Stack>
                                        }                                            
                                        </Grid>  
                                    <Grid xs={12} sm={6} md={4}>
                                        <FormDatePicker fieldName="dateOfBirth" 
                                            label="Date of Birth"/>
                                    </Grid>                                                                                    
                                </Grid>          
                                <CardActions sx={{mt: 2, justifyContent: 'end' }}>
                                    <FormActions saveAction={() => save()} />                                      
                                </CardActions>          
                            </Form>             
                        </CardContent>              
                    </Card>   
                </Grid>
                {person?.id && person?.roleType === RoleType.Student &&
                    <Grid xs={12} lg={6}>   
                        <PersonProfileAcademicTerms person={person}/>
                    </Grid>                
                }
            </Grid>                                 
        </Page>
        }  
        {!loaded && <LoaderPage />}  
        </>);
}

function createFormData(p: Person) {
    const randomColor = randomMaterialColor();
    
    return new FormData({
        firstName: new FormFieldData(p?.firstName || '', [requiredString]),
        middleName: new FormFieldData(p?.middleName || ''),
        lastName: new FormFieldData(p?.lastName || '', [requiredString]),
        dateOfBirth: new FormFieldData(p?.dateOfBirth || ''),      
        academicLevelID: new FormFieldData(p?.academicLevelID || ''),
        avatar: new FormFieldData(p.avatar?.bgColor ? p.avatar : ({bgColor: randomColor.backgroundColor, textColor: randomColor.color} as AvatarDefinition)),
    });            
}