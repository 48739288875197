import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { FormFieldData } from "./form-data";
import { FormDataContext } from "./Form";

export function FormSelect(props: {
    fieldName: string,
    label: string,    
    options: SelectOption[],
    required?: boolean,
    fullWidth?: boolean,
    onChange?: (event: SelectChangeEvent<any>, child: React.ReactNode) => void,
    placeholder?: string,
    width?: number | string,
}) {
    const formData = useContext(FormDataContext);
    const [field, setField] = useState<FormFieldData>(undefined);
    const [value, setValue] = useState<string | number>('');
    const [hasError, setHasError] = useState<boolean>(false);

    const onChange = (e: SelectChangeEvent<any>, child: React.ReactNode) => {        
        field.value$.next(e.target.value || '');
        props.onChange && props.onChange(e, child);
    };
    const onTouch = () => field.touched$.next(true);

    useEffect(() => {
        const cleanup$ = new Subject<void>();
        
        if (formData) {
            if (!(props.fieldName in formData.fields)) throw Error('Field not found in formData: ' + props.fieldName);
            const field = formData.fields[props.fieldName];
            field.value$.pipe(takeUntil(cleanup$)).subscribe(val => setValue(val));
            field.isValid$().pipe(takeUntil(cleanup$)).subscribe(valid => setHasError(!valid));            
            setField(field);
        }

        return () => cleanup$.next();
    }, [props.fieldName, formData]);

    return (field
        ?                              
        <FormControl sx={{ width: props.width || '100%' }} size="small" required={!!props.required}>
            <InputLabel error={hasError}>{props.label}</InputLabel>
            <Select           
                label={props.label}
                value={value}  
                error={hasError}              
                required={!!props.required}
                onChange={onChange} onBlur={_ => onTouch()}
                placeholder={props.placeholder}>

                {props.options?.map(o => !!o 
                    ? 
                        <MenuItem
                            key={o.id}
                            value={o.id}>
                            {o.jsx || o.text || <>&nbsp;</>}
                        </MenuItem> 
                    : 
                        <MenuItem value='' key='none'>
                            <em>None</em>
                        </MenuItem>)
                }               
            </Select>
        </FormControl>
        : <></>
    );
}

// export const makeSelectOption = (id: string, text: string): SelectOption => {return {id,text}};
export const makeSelectOption = (id: string | number, text: string, jsx?: JSX.Element): SelectOption => {return {id, text, jsx}};
export type SelectOption = {id: string | number, text: string, jsx?: JSX.Element};