import { Menu as MenuIcon } from "@mui/icons-material";
import { Button, Drawer, Fab, List, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import globalStateStore from "../../../../../app/global.store";
import { Menu, MenuItem } from "../../../../../models/app/menu-item";
import './mobile-nav.scss';
import { AppButton } from "../../../../shared/buttons/primary";

export function ContextMenu(props: {contextMenu: Menu}): JSX.Element {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [active, setActive] = useState<MenuItem | undefined>();
    const [themeName, setThemeName] = useState<'light' | 'dark'>();
    
    useEffect(() => {
        globalStateStore.theme$.subscribe(t => setThemeName(t));
    }, []);

    useEffect(() => {
        setActive(props.contextMenu.items?.find(i => i.active));
    }, [props.contextMenu]);
    
    return (<>
        <AppButton onClick={() => setDrawerOpen(true)} icon={<MenuIcon fontSize="small"/>} type="primary" size="small"/>                    
        {/* <Fab variant="circular" onClick={() => setDrawerOpen(true)} 
            sx={{position: 'fixed', 
                right: '10px', bottom: '80px', 
                textTransform: 'none'}}>
            <MenuIcon />        
        </Fab> */}
        <Drawer
            anchor='left'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}            
          >      
          <div className={`nav-drawer ${themeName}`}>        
            <List>                 
                {props.contextMenu.items.map(mi =>
                <ListItemButton className={`nav-drawer-button ${active === mi ? 'active' : ''}`} 
                    onClick={() => {                        
                        setActive(mi);
                        if (mi.onClick) {
                            mi.onClick();                            
                        }
                        else {
                            navigate(mi.path);
                        }      
                        setDrawerOpen(false);                                                     
                    }} 
                    key={mi.text}
                    sx={{justifyContent: 'center'}}
                    >
                    <div className='icon'>{mi.icon && mi.icon}</div>
                    <div className="text">{mi.text}</div>
                </ListItemButton>  
                )}
            </List>
        </div>
        </Drawer>
    </>);
}