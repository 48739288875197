import { Typography, Stack } from "@mui/material";
import globalStore from "../../../../app/global.store";
import { Subject } from "../../../../models/data/settings/subject";
import { Form } from "../../../shared/form/Form";
import { FormActions } from "../../../shared/form/FormActions";
import { DrawerFormBody } from "../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../layout/form-drawer/DrawerFormHeader";
import { FormData, FormFieldData } from "../../../shared/form/form-data";
import { requiredString } from "../../../shared/form/validators";
import { FormTextField } from "../../../shared/form/FormTextField";
import { saveSubject } from "../../../../services/settings.service";
import { FormSelect, SelectOption, makeSelectOption } from "../../../shared/form/FormSelect";
import { FormBool } from "../../../shared/form/FormBool";
import { useSubscription } from "../../../../hooks/useSubscription";
import { combineLatest, skip } from "rxjs";

export function SubjectEditForm(props: {subject: Subject, existing: Subject[], onSaved: (s: Subject) => void}) {
    const existingSubjectsFlat = flattenSubjects(props.existing);
    const formData = new FormData({
        name: new FormFieldData(props.subject?.name || '', [requiredString]),
        parentSubjectID: new FormFieldData(props.subject?.parentSubjectID),
        isCore: new FormFieldData(props.subject.isCore || false),
    });

    const save = () => {
        const fv = formData.getValueAs<Subject>();        
        saveSubject({...props.subject, ...fv})
            .subscribe(s => {
                props.onSaved(s);
                globalStore.toastSubject$.next({ type: 'success', message: 'Saved'  });
                globalStore.popDrawerFormContent();                
            });
    };

    useSubscription(
        formData.fields.parentSubjectID.value$.pipe(skip(props.subject?.parentSubjectID ? 1 : 0)), 
        parentID => {
            const parent = parentID && existingSubjectsFlat.find(e => e.id === parentID);
            formData.fields.isCore.value$.next(parent?.isCore || false);
        }, 
        [formData, existingSubjectsFlat]);

    return <DrawerFormContainer>
        <Form formData={formData}>            
            <DrawerFormHeader>
                <Typography variant="h6">Subject</Typography>
            </DrawerFormHeader>            
            <DrawerFormBody>              
                <Stack spacing={2} sx={{minWidth: '275px'}}>
                    <FormTextField label="Subject" required={true} fieldName="name" />
                    <FormSelect                         
                        label='Parent'
                        fieldName="parentSubjectID"                 
                        fullWidth={false}   
                        options={makeOptions(props.existing || [], props.subject.id)}                         
                    />
                    <FormBool fieldName="isCore" type='switch' label="Is Core"/>
                </Stack>                             
            </DrawerFormBody>    
            <DrawerFormFooter>
                <FormActions     
                    saveAction={() => save()}                         
                    cancelAction={() => globalStore.popDrawerFormContent()}/>                    
            </DrawerFormFooter>
        </Form>
    </DrawerFormContainer>;
}

const makeOptions = (subjects: Subject[], exclude?: string) => {
    const os: SelectOption[] = [undefined];
    const recurseSubject = (s: Subject, depth: number) => {
        const t = <>
            {new Array(depth)?.fill(0).map(_ => <>&nbsp;&nbsp;&nbsp;</>)}
            {s.name}
        </>;
        os.push(makeSelectOption(s.id, s.name, t));
        s.subItems?.filter(s => s.id !== exclude).forEach(sub => recurseSubject(sub, depth + 1));
    }

    subjects?.filter(s => s.id !== exclude).forEach(s => recurseSubject(s, 0));

    return os;
};

const flattenSubjects = (subjects: Subject[]): Subject[] => subjects?.concat(subjects.flatMap(s => flattenSubjects(s.subItems))) || [];
