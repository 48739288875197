import { View, Text  } from "@react-pdf/renderer";
import {Style} from "@react-pdf/stylesheet"
import React from "react";

const textStyle: Style = {
    fontSize: '14px',
    fontWeight: 'bold',
};

export function SubHeader1(props: {children: string | string[], style?: Style}): JSX.Element {
    return (
        <View style={{...props.style}}>
            <Text style={{...textStyle}}>{props.children}</Text>
        </View>
    );
}