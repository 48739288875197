import { Card, CardHeader, CardContent } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { BehaviorSubject, shareReplay } from "rxjs";
import globalStore from "../../../../app/global.store";
import { useSubscription } from "../../../../hooks/useSubscription";
import { ScheduledActivity } from "../../../../models/data/schedule/scheduled-activity.model";
import { lastDOMWeekEnd, weekStart } from "../../../../util/dates";
import { AppButton } from "../../../shared/buttons/primary";
import { ScheduleMonthGrid } from "./ScheduleMonthGrid";
import { ScheduleMonthTitle } from "./ScheduleMonthTitle";
import { ScheduleStore } from "../schedule.store";
import { Add } from "@mui/icons-material";
import { ScheduledActivityRecurring } from "../../../../models/data/schedule/scheduled-activity-recurring.model";
import { SchdeuleAddForm } from "../edit-drawer/ScheduleAddForm";
import { PersonSelectionChips } from "../../../shared/PersonSelectionChips";
import { SchedulePersonSelectorContext } from "../Schedule";
export const ScheduleMonthDataContext = createContext<ScheduleStore>(undefined);

export function ScheduleMonth(): JSX.Element {
    const today = new Date();

    const [items, setItems] = useState<ScheduledActivity[]>();      
    const [recurringItems, setRecurringItems] = useState<ScheduledActivityRecurring[]>();      

    const [selectedMonth$] = useState(new BehaviorSubject<Date>(undefined));    
    
    const [startDate, setStartDate] = useState<Date>(undefined);
    const [endDate, setEndDate] = useState<Date>(undefined);

    const [store] = useState<ScheduleStore>(new ScheduleStore());
    
    useSubscription(selectedMonth$, d => d && setStartEnd(d), []);    
    
    const [selectedStudents, setSelectedStudents] = useState<string[]>();
    const personSelectorStore = useContext(SchedulePersonSelectorContext);
    useSubscription(personSelectorStore.selectedStudents$, s => setSelectedStudents(s));
    
    useSubscription(store?.items$, i => setItems(i), [store]);
    useSubscription(store?.recurringItems$, r => setRecurringItems(r), [store]);

    useEffect(() => {
        selectedMonth$.next(new Date(today.getFullYear(), today.getMonth(), 1));
    }, []);
    useEffect(() => {
        startDate && endDate && selectedStudents && store.init(startDate, endDate, selectedStudents);
    }, [startDate, endDate, selectedStudents]);    

    const setStartEnd = (firstDOM: Date) => {        
        setStartDate(weekStart(firstDOM));
        setEndDate(lastDOMWeekEnd(firstDOM));
    };

    return (
        <ScheduleMonthDataContext.Provider value={store}>
            <Card sx={{position: 'relative'}} style={{backgroundColor: 'transparent'}}>
                <CardHeader         
                    title={<>
                        <ScheduleMonthTitle selectedMonth$={selectedMonth$} />
                        <PersonSelectionChips selected$={personSelectorStore.selectedStudents$} persons$={personSelectorStore.students$} />
                    </>}
                    action={                    
                        <AppButton 
                            icon={<Add />}
                            text='Add'
                            onClick={() => globalStore.pushDrawerFormContent(
                                <SchdeuleAddForm                                 
                                    onSaved={() => store.refresh()} 
                                    showPersonAndCourse={true}/>)} 
                        />}
                />
                <CardContent>                            
                    {!!items && !!recurringItems
                        && <ScheduleMonthGrid startDate={startDate} endDate={endDate} />
                    }
                </CardContent>        
            </Card>
        </ScheduleMonthDataContext.Provider>
    );    
}



