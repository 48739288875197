import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mui/material";
import { AvatarDefinition } from "../../models/data/shared/avatar-definition";

export function AppAvatar2(props: {
    avatarDefinition: AvatarDefinition,    
    size?: string, 
    fontSize?: string, 
    className?: string, 
    onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void,
    border?: string,
}): JSX.Element {               
    return (
        <Avatar style={{ 
            backgroundColor: props.avatarDefinition.bgColor, 
            color: props.avatarDefinition.textColor, 
            height: props.size || '30px', 
            width: props.size || '30px', 
            fontSize: props.fontSize || '16px',
            border: props.border,            
        }}
            className={props.className}
            onClick={props.onClick}
            >
            {props.avatarDefinition.icon ?      
                <>{ props.avatarDefinition.icon.startsWith('fas-') || props.avatarDefinition.icon.startsWith('far-') 
                ? <FontAwesomeIcon icon={getFaIcon(props.avatarDefinition.icon)} />
                : <span className="material-symbols-outlined" style={{fontSize: props.fontSize || '20px'}}> 
                    {props.avatarDefinition.icon}
                </span>                    
                }</>                           
                : <>{props.avatarDefinition.text}</>
            }                                                    
        </Avatar>
    );
} 

export function getFaIcon(icon: string): [IconPrefix, IconName] | undefined {    
    const i = icon?.indexOf('-');
    if (i >= 0) {
        return [
            icon.substring(0, i) as IconPrefix,
            icon.substring(i + 1) as IconName,
        ];
    }
    else return undefined;
}