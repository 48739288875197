import React from "react";
import { CenteredContent } from "./CenteredContent";
import { Typography } from "@mui/material";
import { AppButton } from "./buttons/primary";
import { ReplayOutlined } from "@mui/icons-material";

export class ErrorBoundary extends React.Component<{children}, {
    hasError: boolean,
    error: any}> {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: undefined
        };
    }    
    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error: error
        };
    }
    
    componentDidCatch(error, errorInfo) {                
    }

    render() {     
        if (this.state.hasError) {
            return <ErrorContent />;
        } else {            
            return this.props.children;
        }
    }
}

const ErrorContent = () => 
    <CenteredContent>
        <Typography variant="body1">There was an error loading this page. Try refreshing your browser window.</Typography>
        <AppButton text='Refresh' icon={<ReplayOutlined />} onClick={() => window.location.reload()} sx={{mt: 3}} />
    </CenteredContent>;

