import { Edit as EditIcon, Add as AddIcon, MoreVert as MoreVertIcon } from "@mui/icons-material";
import { Card, CardHeader, Typography, CardContent, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { Page } from "../../../shared/Page";
import { AcademicLevel } from "../../../../models/data/settings/academic-level";
import { getAcademicLevels } from "../../../../services/settings.service";
import Grid from '@mui/material/Unstable_Grid2';

export function AcademicLevelList() {    
    const [academicLevels, setAcademicLevels] = useState<AcademicLevel[]>();    
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {                
        getAcademicLevels().subscribe(al => {
            setAcademicLevels(al);
            setLoaded(true);
        });        
    }, []);

    return(
        <Page breadcrumbs={[{text: 'Settings'}, {text: 'Grades'}]} title='Grades'>             
            <Grid container spacing={2}>                                            
                <Grid xs={12} md={6} xl={3}>   
                    <Card>
                        <CardHeader title={
                            <Typography component="h6" variant="h6">
                                Grades
                            </Typography>
                        }
                        action={
                            <IconButton size="small" onClick={() => {}}>
                                <AddIcon />
                            </IconButton>
                        }/>  
                        <CardContent>          
                            <Table size="medium" >    
                                <TableHead>
                                    <TableRow>                        
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>            
                                <TableBody>
                                {loaded &&
                                    academicLevels.map(a => {
                                        return (
                                        <TableRow key={a.id} className='action-button-container'>                                                                
                                            <TableCell>                                                                               
                                                {a.name} ({a.shortName})                                                                                                                                  
                                            </TableCell>    
                                            <TableCell sx={{width: '40px', padding: 0}}>                                         
                                                <IconButton size="small" onClick={() => {}} className='action-button'>
                                                    <MoreVertIcon />
                                                </IconButton>                                                                                                                                   
                                            </TableCell>                                                                      
                                        </TableRow>
                                        );
                                    })
                                } 
                                { !loaded &&
                                [0,1,2].map((v) => 
                                <TableRow key={v}>
                                    <TableCell colSpan={3}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </TableCell>                    
                                </TableRow>)
                                }                           
                                </TableBody>
                            </Table>    
                        </CardContent>              
                    </Card>             
                </Grid>
            </Grid>   
        </Page>
    );
}