import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { filter, of, switchMap, take } from "rxjs";
import { Person } from "../../../../models/data/person.model";
import { LoaderPage } from "../../../shared/LoaderPage";
import { Page } from "../../../shared/Page";
import { StudentStore } from "./student.store";
import { StudentCourses } from "./StudentCourses";
import Grid from "@mui/material/Unstable_Grid2";
import { ScheduleCard } from "../../schedule/shared/ScheduleCard";
import { PersonAcademicTerm } from "../../../../models/data/person-academic-term-model";
import { useSubscription } from "../../../../hooks/useSubscription";
import { getPersonAcademicTerms } from "../../../../services/person.service";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AppAvatar2 } from "../../../shared/AppAvatar2";

export function StudentDetail(): JSX.Element {        
    const store = useOutletContext<StudentStore>();
    const [loaded, setLoaded] = useState(false);     
    
    const [student, setStudent] = useState<Person>();
    const navigate = useNavigate();      

    const [terms, setTerms] = useState<PersonAcademicTerm[]>();
    const [selectedTerm, setSelectedTerm] = useState<PersonAcademicTerm>();

    useEffect(() => {         
        if (store) {                       
            store.student$.pipe(filter(s => !!s), take(1)).subscribe(s => {
                setStudent(s);                
            });
            store.loaded$.pipe(filter(l => l), take(1)).subscribe(_ => setLoaded(true));
        }                    
    }, [store]);    

    useSubscription(store?.academicTerms$, at => { setTerms(at); setSelectedTerm(at?.length ? at[0] : undefined) }, [store]);
    useEffect(() => store?.academicTerm$.next(selectedTerm), [store, selectedTerm]);

    return (terms ?    
        <Page 
            title={<Box onClick={() => navigate('/settings/household/person/' + student.id, { state: { person: student}})} 
                sx={{cursor: 'pointer', ":hover": {transform: 'scale(1.01)'}}}>      
                    {student?.firstName || ''} {student?.lastName || ''}                
                </Box>}
            icon={!!student?.avatar && <AppAvatar2 avatarDefinition={student.avatar} size='70px' fontSize="35px"/>}
            breadcrumbs={[{ text: 'Students', url: '/students' }, {text: `${student?.firstName || ''} ${student?.lastName || ''}`}]}>        
            <Box sx={{mb: 2}}>
                <FormControl sx={{ width: '300px' }} size="small">
                    <InputLabel>Term</InputLabel>
                    <Select           
                        label='Term'
                        value={selectedTerm?.id || ''}                                                   
                        onChange={(e) => setSelectedTerm(terms?.find(t => t.id === e.target.value))}>
                        {terms?.map(t =>                         
                                <MenuItem
                                    key={t.id}
                                    value={t.id}>
                                    {t.academicTermName}&nbsp;
                                </MenuItem> )
                        }               
                    </Select>                    
                </FormControl>                
            </Box> 


            
            { loaded &&

            
                <Grid container spacing={2}>
                    <Grid xs={12} md={8} xl={4}>
                        <StudentCourses />                        
                    </Grid>
                    <Grid xs={12} md={4} xl={4}>
                        <ScheduleCard studentID={student.id}/>
                    </Grid>                
                </Grid>          
            }
              
        </Page>
        : <LoaderPage />
    );
}
