import { TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect, useState } from "react";
import { Subject, takeUntil } from "rxjs";
import { FormFieldData } from "./form-data";
import { FormDataContext } from "./Form";
import dayjs from "dayjs";

export function FormDatePicker(props: {
    fieldName: string,
    label: string,    
    required?: boolean,
    minDate?: Date | string,
    maxDate?: Date | string,        
    onChange?: (value: any, keyboardInputValue?: string) => void,
}) {
    const formData = useContext(FormDataContext);
    const [field, setField] = useState<FormFieldData>(undefined);
    const [value, setValue] = useState<string>(null);
    const [hasError, setHasError] = useState<boolean>(false);

    const onChange = (val, keyboardInput) => {                        
        field.value$.next(val ? (val as dayjs.Dayjs).format('YYYY-MM-DD') : '');
        props.onChange && props.onChange(val, keyboardInput);      
    };
    const onTouch = () => field.touched$.next(true);    

    useEffect(() => {
        const cleanup$ = new Subject<void>();
                
        if (formData) {
            if (!(props.fieldName in formData.fields)) throw Error('Field not found in formData: ' + props.fieldName);
            const field = formData.fields[props.fieldName];                        
            field.value$.pipe(takeUntil(cleanup$)).subscribe(val => setValue(val || null));
            field.isValid$().pipe(takeUntil(cleanup$)).subscribe(valid => setHasError(!valid));               
            setField(field);
        }

        return () => cleanup$.next();
    }, [props.fieldName, formData]);

    return (field 
        ?                             
        <LocalizationProvider dateAdapter={AdapterDayjs}>                                 
            <DatePicker      
                label={props.label}          
                value={value}
                minDate={props.minDate}
                maxDate={props.maxDate}
                onChange={(val, keyboardInput) => onChange(val, keyboardInput)}                                                
                renderInput={(params) => <TextField {...params}
                    select={false}
                    size='small'
                    fullWidth={true}   
                    error={hasError}                                 
                    onBlur={_ => onTouch()}                        
                    required={!!props.required}           
                    autoComplete='off'                                         
                />}                                                                                
            />
        </LocalizationProvider>  
        : <></>
    );
}
