import { Typography, Stack } from "@mui/material";
import { Subject } from "rxjs";
import globalStore from "../../../../../app/global.store";
import { CourseCurriculum } from "../../../../../models/data/course/course-curriculum.model";
import { Form } from "../../../../shared/form/Form";
import { FormActions } from "../../../../shared/form/FormActions";
import { FormSelect, SelectOption, makeSelectOption } from "../../../../shared/form/FormSelect";
import { FormTextField } from "../../../../shared/form/FormTextField";
import { FormData, FormFieldData } from "../../../../shared/form/form-data";
import { requiredString } from "../../../../shared/form/validators";
import { DrawerFormBody } from "../../../layout/form-drawer/DrawerFormBody";
import { DrawerFormContainer } from "../../../layout/form-drawer/DrawerFormContainer";
import { DrawerFormFooter } from "../../../layout/form-drawer/DrawerFormFooter";
import { DrawerFormHeader } from "../../../layout/form-drawer/DrawerFormHeader";
import { saveCourseCurriculum } from "../../../../../services/course.service";

export function CurriculumEditForm(props: {curriculum: CourseCurriculum, onSaved: () => void}) {
    const formData = new FormData({
        name: new FormFieldData(props.curriculum?.name || '', [requiredString]),        
    });

    const save = () => {
       saveCourseCurriculum({...props.curriculum, ...formData.getValue()}).subscribe(_ => {
            globalStore.showToast('success', 'Saved successfully');
            props.onSaved();
            globalStore.popDrawerFormContent();
       });
    };

    return <DrawerFormContainer>
        <Form formData={formData}>            
            <DrawerFormHeader>
                <Typography variant="h6">Curriculum</Typography>
            </DrawerFormHeader>            
            <DrawerFormBody>              
                <Stack spacing={2} sx={{minWidth: '275px'}}>
                    <FormTextField label="Title" required={true} fieldName="name" maxLength={100} 
                        textFieldProps={{multiline: true}} />                    
                    {/* <FormSelect                         
                        label='Parent'
                        fieldName="parentSubjectID"                 
                        fullWidth={false}   
                        options={makeOptions(props.existing || [], props.subject.id)}                         
                    /> */}
                </Stack>                             
            </DrawerFormBody>    
            <DrawerFormFooter>
                <FormActions     
                    saveAction={() => save()}                         
                    cancelAction={() => globalStore.popDrawerFormContent()}/>                    
            </DrawerFormFooter>
        </Form>
    </DrawerFormContainer>;
}