export const distinctFilter = (o: any, i: number, self: any[]) => self.indexOf(o) === i;
export function firstForGroup<T>(...groupBy: string[]) {
    return (o: T, i: number, self: any[]) => self.findIndex(s => !groupBy.some(g => o[g] !== s[g])) === i;
}
export const sumReducer = (acc: number, val: number) => acc + val;
export function first<T> (a: T[]) {
    return a?.length ? a[0] : undefined;
}
export function last<T>(a: T[]) {
    return a?.length ? a[a.length - 1] : undefined;
}