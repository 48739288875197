import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, IconButton, Typography, Box } from "@mui/material";
import { useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useSubscription } from "../../../../hooks/useSubscription";
import { previousMonth, nextMonth } from "../../../../util/dates";

export const ScheduleMonthTitle = (props: {selectedMonth$: BehaviorSubject<Date>}) => {    
    const [selectedMonth, setSelectedMonth] = useState<Date>();
    useSubscription(props.selectedMonth$, d => setSelectedMonth(d));
    
    return (!!selectedMonth &&
        <Stack direction={'row'}>    
            <IconButton size="small" sx={{borderRadius: 0}}
                onClick={_ => props.selectedMonth$.next(previousMonth(selectedMonth))}>
                <FontAwesomeIcon icon={["fas", "chevron-left"]}/>
            </IconButton>
            <Typography variant="h6" sx={{width: '120px', textAlign: 'center'}}>                            
                <Box>{selectedMonth.toLocaleDateString(undefined, {month: 'long'})}</Box>
                <Box sx={{fontSize: 'small'}}>{selectedMonth.toLocaleDateString(undefined, {year: 'numeric'})}</Box>                
            </Typography>                    
            <IconButton size="small" sx={{borderRadius: 0}}
                onClick={_ => props.selectedMonth$.next(nextMonth(selectedMonth))}>
                <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
            </IconButton>
        </Stack>                    
    );
}