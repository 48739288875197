import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, IconButton, Typography, Box } from "@mui/material";
import { useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useSubscription } from "../../../../hooks/useSubscription";
import { addDays, addWeeks } from "date-fns";

export const ScheduleWeekTitle = (props: {selectedWeekStart$: BehaviorSubject<Date>}) => {    
    const [weekStart, setWeekStart] = useState<Date>();
    useSubscription(props.selectedWeekStart$, d => setWeekStart(d));
    
    return (!!weekStart &&
        <Stack direction={'row'}>    
            <IconButton size="small" sx={{borderRadius: 0}}
                onClick={_ => props.selectedWeekStart$.next(addWeeks(weekStart, -1))}>
                <FontAwesomeIcon icon={["fas", "chevron-left"]}/>
            </IconButton>
            <Typography variant="h6" sx={{width: '100px', textAlign: 'center'}}>                            
                <Box fontSize={'medium'}>
                    {weekStart.toLocaleDateString(undefined, {month: 'numeric', day: 'numeric'})} 
                    - 
                    {addDays(weekStart, 6).toLocaleDateString(undefined, {month: 'numeric', day: 'numeric'})}
                </Box>                
            </Typography>                    
            <IconButton size="small" sx={{borderRadius: 0}}
                onClick={_ => props.selectedWeekStart$.next(addWeeks(weekStart, 1))}>
                <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
            </IconButton>
        </Stack>                    
    );
}